<template>
  <Dialog
    :visible="visible"
    :style="style"
    :modal="true"
    :header="header"
    :dismissable-mask="closable && dismissableMask"
    :close-on-escape="closable"
    :closable="closable"
    :position="position"
    :draggable="false"
    class="mobile-modal-width"
    @update:visible="closeModal"
  >
    <template #header>
      <slot name="header" />
    </template>
    <slot />
    <template #footer>
      <div class="center">
        <slot name="footer" />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

type DialogPositionType = 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' | undefined;


export default defineComponent({
  name: 'BaseModalComponent',
  props: {
    header: {
      type: String,
      default: ''
    },
    visible: {
      required: true,
      type: Boolean
    },
    width: {
      type: String,
      default: '60vw'
    },
    closable: {
      type: Boolean,
      default: true
    },
    position: {
      type: String as PropType<DialogPositionType>,
      default: 'top'
    },
    dismissableMask: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close'],
  setup (props, { emit }) {
    const closeModal = (): void => {
      emit('close')
    }

    const style = computed(() => {
      return { width: props.width, margin: '2rem auto' }
    })

    return {
      closeModal,
      style
    }
  }
})
</script>

<style scoped lang="scss">
.close {
  content: ' ';
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  margin: 0;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9 Im5vIj8+Cjxzdmcgd2lkdGg9IjE3cHgiIGhlaWdodD0iMTdweCIgdmlld0JveD0i MCAwIDE3IDE3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9y Zy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkv eGxpbmsiPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ry b2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVs ZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpv aW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iaWNvbi1iaXRzIiB0cmFuc2Zvcm09 InRyYW5zbGF0ZSgtMTYxLjAwMDAwMCwgLTE1OC4wMDAwMDApIiBzdHJva2U9IiMz RTUzQTQiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxnIGlkPSJjbG9z ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTY5LjUwMDAwMCwgMTY2LjUwMDAwMCkg cm90YXRlKC00NS4wMDAwMDApIHRyYW5zbGF0ZSgtMTY5LjUwMDAwMCwgLTE2Ni41 MDAwMDApIHRyYW5zbGF0ZSgxNTkuMDAwMDAwLCAxNTYuMDAwMDAwKSI+CiAgICAg ICAgICAgICAgICA8cGF0aCBkPSJNMjAsMTAuNSBMMS4xMjI2NTc1MmUtMTIsMTAu NSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0x MCwwLjUgTDEwLDIwLjUiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgIDwv Zz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  color: var(--primary);
  user-select: none;
  cursor: pointer;
  opacity: 1;
}
.modal-dialog {
  margin: 0;
}
.modal-content {
  border: 0;
  border-radius: 0;
}
.modal-header {
  margin-bottom: 1.5rem;
  padding: 0;
}
.modal-title {
  font-size: 1.5rem;
  margin: 0;
  padding: 1rem 0;
}
.modal-body {
  padding: 1rem 0;
}
.center {
  margin: 5px 0;
  text-align: center;
  padding: 5px;
  border-top: 1px solid gainsboro;
}
</style>
