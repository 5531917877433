export default function useClickOutside (targets: HTMLElement[], callback: () => void): () => void {
  const listener = (event: MouseEvent): void => {
    if (
      targets.some((target: HTMLElement) => {
        return target === event.target || event.composedPath().includes(target)
      })
    ) {
      return
    }

    callback()
  }

  const init = (): void => {
    document.body.addEventListener('click', listener)
  }

  const clear = (): void => {
    document.body.removeEventListener('click', listener)
  }

  init()

  return clear
}
