import { IAmsApiResponseError } from '@/interfaces/ams'
import axios, { AxiosError } from 'axios'
import { useToast } from 'primevue/usetoast'
import { useI18n } from 'vue-i18n'

export interface IUseToasts {
  showError: (summary: string, details: string) => void;
  showInfo: (summary: string, details: string) => void;
  showSuccess: (summary: string, details: string) => void;
  showAmsApiError: (summary: string, error: AxiosError<IAmsApiResponseError, any>) => void;
}

export default function useToasts (): IUseToasts {
  const { t } = useI18n()
  const toast = useToast()
  const toastLife = 5000

  const showError = (summary: string, detail: string): void => {
    toast.add({ severity: 'error', summary: summary, detail: detail, life: toastLife })
  }

  const showInfo = (summary: string, detail: string): void => {
    toast.add({ severity: 'info', summary: summary, detail: detail, life: toastLife })
  }

  const showSuccess = (summary: string, detail: string): void => {
    toast.add({ severity: 'success', summary: summary, detail: detail, life: toastLife })
  }

  const showAmsApiError = (summary: string, error: AxiosError<IAmsApiResponseError, any>): void => {
    if (axios.isAxiosError(error)) {
      let errorMessage = ''
      if (error && error.response) {
        errorMessage = error.response.data?.message
      } else {
        errorMessage = t('api.unknown_error').toString()
      }
      showError(summary, errorMessage)
    }
  }

  return {
    showError, showInfo, showSuccess, showAmsApiError
  }
}
