<template>
  <div class="spinner-container">
    <span
      class="spinner"
      :style="spinnerStyle"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'BaseSpinnerComponent',
  props: {
    size: {
      required: false,
      default: 16,
      type: Number
    },
    color: {
      required: false,
      default: 'var(--primary)',
      type: String
    }
  },
  setup (props) {
    const spinnerStyle = computed((): Record<string, string|number> => {
      return {
        width: `${props.size}px`,
        height: `${props.size}px`,
        color: props.color
      }
    })

    return {
      spinnerStyle
    }
  }
})
</script>

<style scoped lang="scss">
.spinner {
  position: relative;
  display: inline-block;
  border-style: solid;
  border-radius: 50%;
  border-top: solid transparent;
  animation: spin 1.5s linear infinite;
  border-width: 3px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
}
</style>
