import { App } from 'vue'
// @ts-ignore
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import '@/styles/datepicker.scss'
import 'easymde/dist/easymde.min.css'
import '@/styles/mde.scss'

export default function setupExternalComponents (app: App): void {
  app.component('Datepicker', Datepicker)
}
