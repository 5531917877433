export default {
  // common
  unknown_error: 'Unknown error. Please try again.',
  server_error: 'Server response error',
  // errors
  could_not_fetch_resources: 'Could not fetch resources',
  could_not_fetch_tableau_resources: 'Could not fetch resources from Tableau. Please try again later.',
  could_not_fetch_resources_details: 'Could not fetch resources. Please try again later.',
  could_not_fetch_article: 'Could not fetch article',
  could_not_fetch_articles: 'Could not fetch articles',
  could_not_fetch_assignment_template: 'Could not fetch assignments template',
  could_not_fetch_assignment_templates: 'Could not fetch assignments templates',
  could_not_fetch_departments: 'Could not fetch departments',
  could_not_fetch_users: 'Could not fetch users',
  could_not_fetch_roles: 'Could not fetch roles',
  could_not_fetch_tools: 'Could not fetch tools',
  could_not_fetch_tool_sources: 'Could not fetch tool sources',
  could_not_fetch_groups: 'Could not fetch groups',
  could_not_send_email: 'Could not send email',
  could_not_add_user: 'Could not add user',
  could_not_edit_user: 'Could not edit user',
  could_not_add_tool: 'Could not add tool',
  could_not_edit_tool: 'Could not edit tool',
  could_not_delete_tool: 'Could not delete tool',
  could_not_fetch_assignments_count: 'Could not fetch assignments count',
  could_not_add_assignment_template: 'Could not add assignment template',
  could_not_edit_assignment_template: 'Could not edit assignment template',
  could_not_validate_groups_tools_association: 'Could not validate groups tools association',
  could_not_fetch_facilities: 'Could not fetch facilities',
  could_not_fetch_user_preferences: 'Could not fetch user preferences',
  could_not_edit_user_preferences: 'Could not edit user preferences'
}
