<template>
  <div
    ref="notificationsRef"
    class="notifications"
  >
    <div
      class="nav-link notifications-title-container"
      @click="toggleNotifications"
    >
      <i
        class="pbi-icon-outline pbi-bell notifications-icon"
        :alt="t('layout.header.link_notifications')"
        :title="t('layout.header.link_notifications')"
      />
      <span class="notifications-link-title">{{ t('layout.header.link_notifications') }}</span>
      <div
        v-if="notificationsCount > 0"
        class="notifications-counter"
      >
        {{ notificationsCount }}
      </div>
    </div>
    <div
      v-if="showNotifications || (keepOpenOnClick && isDisplayingNotificationDetails)"
      class="notifications-container"
    >
      <div class="notifications-container-icon" />
      <div class="notifications-container-inner shadow">
        <div class="notifications-list">
          <div
            v-if="notifications.length === 0"
            class="notifications-empty"
          >
            <BaseSpinnerComponent v-if="loadingNotifications" />
            <template v-else>
              {{ t('layout.header_notifications.empty_notifications') }}
            </template>
          </div>
          <HeaderNotification
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
            @click="openNotification(notification)"
          />
          <div
            v-if="nextUrl"
            class="notifications-fetch-more"
            @click="fetchHistory"
          >
            <BaseSpinnerComponent v-if="loadingNotifications" />
            <span
              v-else
              class="fetch-more-link"
            >{{ t('layout.header_notifications.load_older') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import notificationsService, { INotificationsResponse } from '@/services/notificationsService'
import { useStore } from 'vuex'
import { NotificationsActions } from '@/store/notificationsStore'
import INotification from '@/interfaces/notification'
import useInterval from '@/utils/interval'
import { IApiResponse } from '@/services/service'
import HeaderNotification from '@/layout/components/HeaderNotification.vue'
import useClickOutside from '@/utils/clickOutside'
import BaseSpinnerComponent from '@/components/utility/BaseSpinner.vue'
import MixpanelEvents from '@/utils/mixpanel/mixpanelEvents'
import { trackMixpanelEvent } from '@/utils/mixpanel/setupMixpanel'
import useToasts from '@/composables/useToasts'

export default defineComponent({
  name: 'HeaderNotifications',
  components: {
    HeaderNotification, BaseSpinnerComponent
  },
  props: {
    keepOpenOnClick: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['notification'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const toast = useToasts()
    const store = useStore()

    const showNotifications = ref(false)
    const requireAdditionalClickToClose = ref(false)
    const isDisplayingNotificationDetails = ref(false)
    const loadingNotifications = ref(false)

    const notificationsRef = ref()

    watch(() => props.keepOpenOnClick, (value: boolean): void => {
      if (value === true) {
        requireAdditionalClickToClose.value = true
      } else if (isDisplayingNotificationDetails.value) {
        isDisplayingNotificationDetails.value = false
      }
    })

    const fetchNotifications = (): void => {
      loadingNotifications.value = true
      notificationsService.getLatestNotifications()
        .then((response: IApiResponse<INotificationsResponse>) => {
          store.dispatch(NotificationsActions.AddNotifications, response.data.notifications)
          if (store.state.notifications.nextUrl === null) {
            store.dispatch(NotificationsActions.SetNextUrl, response.data.nextUrl)
          }
        })
        .catch(error => {
          toast.showError(t('layout.header_notifications.fetching_error').toString(), error.message)
        }).finally((): void => {
          loadingNotifications.value = false
        })
    }

    const notifications = computed((): INotification[] => {
      return store.state.notifications.notifications
    })

    const nextUrl = computed((): string|null => {
      return store.state.notifications.nextUrl
    })

    const notificationsCount = computed((): number => {
      return notifications.value.filter((notification: INotification) => notification.id > store.state.notifications.lastViewedId && !notification.banner && notification.active).length
    })

    const toggleNotifications = (): void => {
      showNotifications.value = !showNotifications.value

      if (showNotifications.value && notifications.value.length) {
        store.dispatch(NotificationsActions.SetLastViewedId, Math.max(...notifications.value.map((notification: INotification) => notification.id)))
      }

      trackMixpanelEvent(MixpanelEvents.OpenedNotifications)
    }

    const fetchHistory = async (): Promise<void> => {
      if (nextUrl.value) {
        loadingNotifications.value = true
        try {
          const response = await notificationsService.getHistory({
            url: nextUrl.value
          })
          store.dispatch(NotificationsActions.AddNotifications, response.data.notifications)
          store.dispatch(NotificationsActions.SetNextUrl, response.data.nextUrl)
        } catch (error: any) {
          toast.showError(t('layout.header_notifications.fetching_error').toString(), error.message)
        } finally {
          loadingNotifications.value = false
        }
      }
    }

    const openNotification = (notification: INotification): void => {
      emit('notification', notification)
      isDisplayingNotificationDetails.value = true
    }

    let clearInterval: () => void
    let clearClick: () => void

    onMounted((): void => {
      clearInterval = useInterval(fetchNotifications, 10 * 60 * 1000, true, 'HeaderNotifications')
      clearClick = useClickOutside([notificationsRef.value], () => {
        if (!requireAdditionalClickToClose.value) {
          showNotifications.value = false
        } else if (!props.keepOpenOnClick) {
          requireAdditionalClickToClose.value = false
        }
      })
    })

    onUnmounted((): void => {
      clearInterval()
      clearClick()
    })

    return {
      t,
      notificationsCount,
      nextUrl,
      toggleNotifications,
      notifications,
      fetchHistory,
      notificationsRef,
      loadingNotifications,
      openNotification,
      showNotifications,
      isDisplayingNotificationDetails
    }
  }
})
</script>

<style scoped lang="scss">
.nav-link {
  cursor: pointer;
  transition: padding-left 0.3s linear;
  transition: padding-right 0.3s linear;
}
.notifications-link-title {
  padding-left: 5px;
}
.notifications {
  position: relative;
  user-select: none;
  font-family: "PrecisionSans_W_Rg","Helvetica Neue",Arial,sans-serif;
  font-weight: normal;
}
.notifications-container {
  position: absolute;
  width: 340px;
  transform: translateX(-50%);
  left: 50%;
  top: 45px;
  z-index: 1;
  color: var(--text);
  cursor: auto;
}
.notifications-container-inner {
  background-color: white;
  width: 100%;
  border-radius: 2px;
}
.notifications-list {
  max-height: 50vh;
  overflow-y: auto;
}
.notifications-container-icon {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
}
.notifications-counter {
  color: var(--text);
  background-color: white;
  border-radius: 100%;
  width: auto;
  height: 20px;
  min-width: 20px;
  text-align: center;
  line-height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
}
.notifications-icon {
  cursor: pointer;
}
.notification-validity {
  color: var(--rich400);
}
.notifications-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid gainsboro;
}
.notifications-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid gainsboro;
}
.notification-inactive {
  opacity: 0.6;
}
.notifications-fetch-more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.notifications-fetch-more {
  cursor: pointer;
}
@media (min-width: 576px) {
  .notifications-link-title {
    display: none;
  }
  div.nav-link i {
    color: white !important;
  }
}
@media (max-width: 575.98px) {
  .nav-link {
    padding: 16px !important;
  }
  .notifications-container {
    width: auto;
    position: relative;
    transform: inherit;
    left: auto;
    top: auto;
    margin-top: 5px;
  }
  .notifications-container-inner.shadow {
    box-shadow: none !important;
  }
  .notifications-container-icon {
    display: none;
  }
  .notifications-counter {
    position: relative !important;
    display: inline-block !important;
    vertical-align: middle !important;
    top: auto !important;
    right: auto !important;
    margin-left: 5px !important;
  }
  .notifications-title {
    display: none;
  }
  .notifications-link-title {
    display: inline-block;
  }
  .notification {
    padding: 5px;
  }
  .notifications-list {
    border: 1px solid gainsboro;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .nav-link {
    padding: 24px 8px !important;
  }
  .notifications-container {
    left: auto;
    right: -92px;
    transform: none;
  }
  .notifications-container-icon {
    margin: 0 0 0 220px;
  }
}
@media (min-width: 992px) {
  .nav-link {
    padding: 24px 16px !important;
  }
}
</style>
