import { createRouter, createWebHistory, NavigationGuardNext, RouteLocation, RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from 'vue-router'
import Login from '@/views/Login.vue'
import Auth from '@/views/Auth.vue'
import store from '@/store/index'
import Layout from '@/layout/layout'
import { hasAmsResources, hasAnyGroup, hasRoles, isAuthenticated, PermissionsMode } from './routeGuards'
import { amsRoutes } from './amsRoutes'
import { AppActions } from '@/store/appStore'
import { recordReplay } from '@/utils/sentry/setupSentry'

/**
 * App routes
 *
 * Meta dictionary can contain the following keys:
 * layout: Layout to display, uses the Layout enum
 * allowAnonymous: Whether this route accepts anonymous users, boolean
 * permissions: dictionary with two keys:
 *   values: a list of permissions required
 *   mode: optional property which uses the PermissionsMode enum
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '',
    component: Auth,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: Layout.LoginLayout,
      allowAnonymous: true
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/assignments',
    name: 'Assignments',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "a" */ '@/views/assignments/Assignments.vue'),
    children: [
      {
        path: 'bulk',
        name: 'Assignments - Bulk Assign',
        component: () => import(/* webpackChunkName: "a" */ '@/views/assignments/components/BulkAssign.vue'),
        beforeEnter: (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
          if (!store.state.assignments.bulkAssignParams) {
            next('/')
          } else {
            next()
          }
        }
      },
      {
        path: ':id',
        name: 'Assignments - Details',
        component: () => import(/* webpackChunkName: "a" */ '@/views/assignments/components/AssignmentDetails.vue')
      }
    ]
  },
  {
    path: '/tools/browse',
    name: 'Browse available tools',
    component: () => import(/* webpackChunkName: "b" */ '@/views/BrowseTools.vue')
  },
  {
    path: '/tools/:toolId/:toolName?',
    name: 'Embedded Tool',
    component: () => import(/* webpackChunkName: "b" */ '@/views/EmbeddedTool.vue')
  },
  {
    path: '/tools',
    alias: '/tools',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "b" */ '@/views/Tools.vue')
  },
  {
    path: '/notifications/:notificationUuid/:notificationName',
    name: 'Notification Preview',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "b" */ '@/views/NotificationPreview.vue')
  },
  {
    path: '/admin',
    name: 'Administration',
    component: () => import(/* webpackChunkName: "c" */ '@/views/admin/Admin.vue'),
    redirect: (to: RouteLocation): RouteLocationRaw => {
      /**
       * Custom logic for admin section, handles redirection to first available admin subpage
       */
      if (!store.getters.isLoggedIn) {
        return '/login?next=/admin'
      }

      if (to.name !== 'Administration') {
        return to.fullPath
      }

      const hasAdminGroupsAccessRole = store.state.session.permissions.includes('ui.admin.groups.access')
      const hasAdminUsersAccessRole = store.state.session.permissions.includes('ui.admin.users.access')
      const hasAdminToolsAccessRole = store.state.session.permissions.includes('ui.admin.tools.access')
      const hasAdminAssignmentsAccessRole = store.state.session.permissions.includes('ui.admin.assignments.access')
      const hasAdminApiKeysAccessRole = store.state.session.permissions.includes('ui.admin.users.api.access')
      const hasAdminAmsAccessRole = store.state.session.permissions.includes('ui.admin.ams.access')

      if (hasAdminGroupsAccessRole) {
        return '/admin/groups'
      } else if (hasAdminUsersAccessRole) {
        return '/admin/users'
      } else if (hasAdminToolsAccessRole) {
        return '/admin/tools'
      } else if (hasAdminAssignmentsAccessRole) {
        return '/admin/assignments_templates'
      } else if (hasAdminApiKeysAccessRole) {
        return '/admin/keys'
      } else if (hasAdminAmsAccessRole) {
        return '/admin/ams'
      } else {
        return '/admin/notifications'
      }
    },
    children: [
      amsRoutes,
      {
        path: 'groups',
        component: () => import(/* webpackChunkName: "c" */ '@/views/admin/AdminGroups.vue'),
        name: 'Administration - Groups',
        meta: {
          permissions: {
            values: ['ui.admin.groups.access']
          }
        },
        children: [
          {
            path: '',
            name: 'Administration - Groups',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AdminGroupsTable.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.groups.access']
              }
            }
          },
          {
            path: 'uat',
            name: 'Administration - Groups - UAT',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/UatToolGroup.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.groups.access', 'groups.update.governed_by']
              }
            }
          }
        ]
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "c" */ '@/views/admin/AdminUsers.vue'),
        name: 'Administration - Users',
        meta: {
          permissions: {
            values: ['ui.admin.users.access']
          }
        }
      },
      {
        path: 'keys',
        component: () => import(/* webpackChunkName: "c" */ '@/views/admin/AdminApiKeys.vue'),
        name: 'Administration - API Keys',
        meta: {
          permissions: {
            values: ['ui.admin.users.api.access']
          }
        }
      },
      {
        path: 'tools',
        component: () => import(/* webpackChunkName: "c" */ '@/views/admin/AdminTools.vue'),
        name: 'Administration - Tools',
        meta: {
          permissions: {
            values: ['tools.read.governed_by', 'ui.admin.tools.access'],
            mode: PermissionsMode.Any
          }
        },
        children: [
          {
            path: '',
            name: 'Administration - Tools',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/ToolsTable.vue'),
            meta: {
              permissions: {
                values: ['tools.read.governed_by', 'ui.admin.tools.access'],
                mode: PermissionsMode.Any
              }
            }
          },
          {
            path: 'create',
            name: 'Administration - Tools - Create',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AddEditTool.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.tools.access', 'tools.create']
              }
            }
          },
          {
            path: 'edit/:toolId',
            name: 'Administration - Tools - Edit',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AddEditTool.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.tools.access', 'tools.update.all']
              }
            }
          }
        ]
      },
      {
        path: 'assignments_templates',
        component: () => import(/* webpackChunkName: "c" */ '@/views/admin/AdminAssignments.vue'),
        name: 'Administration - Assignments',
        meta: {
          permissions: {
            values: ['ui.admin.assignments.access']
          }
        },
        children: [
          {
            path: '',
            name: 'Administration - Assignment Templates',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AssignmentTemplatesTable.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.assignments.access']
              }
            }
          },
          {
            path: 'create',
            name: 'Administration - Assignment Templates - Create',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AddEditAssignmentTemplate.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.assignments.access', 'assignments.templates.create']
              }
            }
          },
          {
            path: 'edit/:id',
            name: 'Administration - Assignments Templates - Edit',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AddEditAssignmentTemplate.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.assignments.access', 'assignments.templates.update.all']
              }
            }
          }
        ]
      },
      {
        path: 'notifications',
        component: () => import(/* webpackChunkName: "c" */ '@/views/admin/AdminNotifications.vue'),
        name: 'Administration - Notifications',
        meta: {
          permissions: {
            values: ['ui.admin.notifications.access']
          }
        },
        children: [
          {
            path: '',
            name: 'Administration - Notifications',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/NotificationsTable.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.notifications.access']
              }
            }
          },
          {
            path: 'create',
            name: 'Administration - Notifications - Create',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AddEditNotifications.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.notifications.access', 'notifications.create']
              }
            }
          },
          {
            path: 'edit/:id',
            name: 'Administration - Notifications - Edit',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AddEditNotifications.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.notifications.access', 'notifications.update']
              }
            }
          },
          {
            path: 'duplicate/:id',
            name: 'Administration - Notifications - Duplicate',
            component: () => import(/* webpackChunkName: "c" */ '@/views/admin/components/AddEditNotifications.vue'),
            meta: {
              permissions: {
                values: ['ui.admin.notifications.access', 'notifications.create']
              }
            }
          }
        ]
      }
    ]
  },
  {
    path: '/access_request',
    name: 'Access Request',
    component: () => import(/* webpackChunkName: "d" */ '@/views/AccessRequest.vue'),
    meta: {
      permissions: {
        values: ['access_request.update.all', 'access_request.update.governed_by'],
        mode: PermissionsMode.Any
      },
      layout: Layout.LoginLayout
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "d" */ '@/views/Welcome.vue'),
    beforeEnter: (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (store.state.session.groups.length > 0) {
        next('/')
      } else {
        next()
      }
    },
    meta: {
      layout: Layout.LoginLayout,
      permissions: {
        values: ['ui.welcome.access']
      }
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "e" */ '@/views/Profile.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "e" */ '@/views/Support.vue')
  },
  {
    path: '/user-request',
    alias: '/bi-request',
    name: 'User Request Form',
    component: () => import(/* webpackChunkName: "e" */ '@/views/UserRequest.vue')
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "f" */ '@/views/errors/Forbidden.vue'),
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'Not Found',
    component: () => import(/* webpackChunkName: "f" */ '@/views/errors/NotFound.vue'),
    meta: {
      allowAnonymous: true
    }
  }
]

/**
 * Function which combines various checks before enabling navigation to requested routes
 * @param to requested page
 * @param from current page
 * @param next function which enables navigating to different pages
 * @returns {boolean} true if all checks have passed, otherwise false
 */
const onRouteChange = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
  store.dispatch(AppActions.ToggleFullScreen, false)
  if (await isAuthenticated(to, from, next) && hasAnyGroup(to, next) && hasRoles(to, next) && await hasAmsResources(to, next)) {
    next()
  }

  if ([to.name, from.name].includes('Embedded Tool')) {
    recordReplay(to.name !== 'Embedded Tool')
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(onRouteChange)

export default router
