<template>
  <div
    v-if="appState === 'initializing'"
    class="splash"
  >
    <BaseSpinner color="#fff" />
  </div>
  <component
    :is="layout"
    v-else-if="appState === 'ready'"
  >
    <router-view />
  </component>
  <div
    v-else-if="appState === 'maintenance'"
    class="maintenance"
  >
    <Maintenance />
  </div>
  <div
    v-else-if="appState === 'error'"
    class="splash"
  >
    <div class="splash-error">
      {{ errorMessage }}
    </div>
  </div>
  <Toast position="top-right" />
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import LoginLayout from '@/layout/LoginLayout.vue'
import BaseLayout from '@/layout/BaseLayout.vue'
import Layout from '@/layout/layout'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import BaseSpinner from '@/components/utility/BaseSpinner.vue'
import { useI18n } from 'vue-i18n'
import Maintenance from './components/Maintenance.vue'
import authService from './services/authService'
import { AppActions, AppState } from './store/appStore'
import router from './router'
import { SessionActions } from './store/sessionStore'

export default defineComponent({
  name: 'App',
  components: {
    LoginLayout,
    BaseLayout,
    BaseSpinner,
    Maintenance
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const { t } = useI18n()

    const errorMessage = ref('')

    const appState = computed((): AppState => {
      return store.state.app.state
    })

    onMounted(async (): Promise<void> => {
      const msal = authService.getMsalInstance()

      if (msal) {
        await msal.handleRedirectPromise().then(() => {
          return true
        }).catch(() => {
          return false
        })

        try {
          const userDetails = (await authService.getUserDetails()).data
          if (userDetails) {
            await store.dispatch(SessionActions.LogIn, userDetails)
            await store.dispatch(AppActions.SetState, 'ready')
          } else {
            await store.dispatch(SessionActions.LogOut)
            await store.dispatch(AppActions.SetState, 'ready')
            router.push('/login')
          }
        } catch {
          // unauthenticated user
          if (store.state.app.state !== 'maintenance') {
            await store.dispatch(SessionActions.LogOut)
            await store.dispatch(AppActions.SetState, 'ready')
          }
        }
      } else if (store.state.app.state === 'error') {
        // app state was set to error in main.ts app settings request catch block
        errorMessage.value = t('common.app_init_error')
      }
    })

    const layout = computed(() => {
      switch (route.meta.layout) {
      case Layout.LoginLayout:
        return LoginLayout
      default:
        return BaseLayout
      }
    })

    return {
      layout,
      appState,
      errorMessage
    }
  }
})
</script>
<style lang="scss">
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  height: 100%;
}
.splash {
  position: absolute;
  background-image: linear-gradient(135deg, var(--grad_start) 0%, var(--grad_mid) 50%, var(--grad_end) 100%);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.maintenance {
  height: 100%;
  width: 100%;
}
</style>
