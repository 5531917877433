<template>
  <div class="form-group">
    <label
      v-if="label"
      :for="inputId"
    >{{ label }}<span v-if="requiredLabel">*</span></label>
    <div class="input-container">
      <i
        v-if="icon"
        class="pi input-icon"
        :class="icon"
        @click="inputRef.focus()"
      />
      <input
        v-bind="$attrs"
        :id="inputId"
        ref="inputRef"
        class="form-control"
        :type="type"
        :autocomplete="autoComplete"
        :value="modelValue"
        :disabled="disabled"
        :tabindex="tabIndex"
        :placeholder="placeholder"
        :name="name"
        :maxlength="maxLength"
        @input="updateInput"
      >
    </div>
    <div
      v-if="maxLength !== MAX_LENGTH"
      class="small-length"
    >
      <span data-test-id="available-length">{{ availableLength }}</span> {{ t('components.forms.apex_markdown.characters_remaining') }}
    </div>
    <div
      v-if="!compact || errors.length"
      class="input-errors"
    >
      <div
        v-for="error of errors"
        :key="error.$uid"
        class="input-error"
      >
        {{ error.$message }}
      </div>
    </div>
    <span class="small-subtitle">{{ subtitle }}</span>
  </div>
</template>

<script lang="ts">
import { ErrorObject } from '@vuelidate/core'
import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const MAX_LENGTH = 524288

export default defineComponent({
  inheritAttrs: false,
  props: {
    compact: { type: Boolean, default: false },
    inputId: { type: String, required: true },
    label: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    type: { type: String, default: 'text' },
    autoComplete: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    tabIndex: { type: String, default: '0' },
    errors: { type: Array as PropType<ErrorObject[]>, default: () => [] },
    placeholder: { type: String, default: '' },
    icon: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    requiredLabel: { type: Boolean, default: false },
    name: { type: String, default: '' },
    maxLength: { type: Number, default: MAX_LENGTH }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const updateInput = (event: Event) => {
      const target = event.target as HTMLInputElement
      const value = target.value.substring(0, props.maxLength)
      updateAvailableLength(value.length)
      emit('update:modelValue', value)
    }

    const availableLength = ref('')

    const updateAvailableLength = (currentLength: number): void => {
      const length = props.maxLength - currentLength
      availableLength.value = length.toString()
    }

    onMounted(() => {
      updateAvailableLength(0)
    })

    watch(() => props.maxLength, () => {
      updateAvailableLength(props.modelValue.length)
    })

    const inputRef = ref()
    return { updateInput, inputRef, MAX_LENGTH, availableLength, t }
  }
})
</script>

<style scoped lang="scss">
.form-control {
  background-color: var(--form_control_bg_richcolor);
}
.input-errors {
  min-height: 18px;
}
.form-group {
  margin-bottom: 0;
}
.input-icon {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid var(--border_color);
  border-right: 0;
  cursor: pointer;
}
.input-container {
  display: flex;
  flex-direction: row;
}
.small-subtitle {
  font-size: 10px;
  color: var(--gray400);
}
.small-length {
  font-size: 10px;
  color: var(--gray400);
}
</style>
