import INotification from '@/interfaces/notification'
import Service, { IApiResponse } from './service'

export interface INotificationsResponse {
  notifications: INotification[],
  nextUrl: string|null
}

export interface INotificationsHistoryRequest {
  url: string;
}

export interface ICreateNotificationRequest {
  title: string;
  content: string;
  groups: number[];
  tools: number[];
  startDate: string;
  endDate: string;
  banner: boolean;
  type: number;
  emailNotification: boolean;
  emailTitle: string;
  emailScheduledDate: string;
  sendToAll: boolean;
}

export interface IEditNotificationRequest {
  title: string;
  content: string;
  groups: number[];
  tools: number[];
  banner: boolean;
  type: number;
  emailNotification: boolean;
  emailTitle: string;
  emailScheduledDate: string;
  endDate: string;
  sendToAll: boolean;
}

class NotificationsService extends Service {
  async getLatestNotifications (): Promise<IApiResponse<INotificationsResponse>> {
    return this.get('/notifications/active')
  }

  async getHistory (request: INotificationsHistoryRequest): Promise<IApiResponse<INotificationsResponse>> {
    return this.getUrl(request.url)
  }

  async getAllNotifications (system = false): Promise<IApiResponse<INotification[]>> {
    if (system) {
      return this.get('/notifications?system')
    }
    return this.get('/notifications')
  }

  async getNotification (id: number|string): Promise<IApiResponse<INotification>> {
    return this.get(`/notifications/${id}`)
  }

  async createNotification (notification: ICreateNotificationRequest): Promise<void> {
    return this.post('/notifications', notification)
  }

  async editNotification (id: number, notification: IEditNotificationRequest): Promise<void> {
    return this.put(`/notifications/${id}`, notification)
  }

  async deleteNotification (id: number): Promise<void> {
    return this.delete(`/notifications/${id}`)
  }
}

export default new NotificationsService()
