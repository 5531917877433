<template>
  <div
    class="base-layout"
    :class="{'full-screen-enabled': fullscreenEnabled}"
  >
    <Header v-if="!fullscreenEnabled" />
    <div class="base-layout-content">
      <slot />
    </div>
    <Footer v-if="!fullscreenEnabled" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Header from '@/layout/components/Header.vue'
import Footer from '@/layout/components/Footer.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'BaseLayout',
  components: {
    Header,
    Footer
  },
  setup: () => {
    const store = useStore()

    const fullscreenEnabled = computed(() => store.state.app.fullScreen)

    return {
      fullscreenEnabled
    }
  }
})
</script>

<style scoped lang="scss">
.base-layout {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 350px;
  &:not(.full-screen-enabled) {
    padding-top: 70px;
  }
}

@media (max-width: 575.98px) {
  .base-layout {
    &:not(.full-screen-enabled) {
      padding-top: 58px;
    }
  }
}

.base-layout-content {
  margin-bottom: auto;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
