enum MixpanelEvents {
  LoggedIn = 'Logged in',
  LoginFailure = 'Failed to log in',
  LoggedOut = 'Logged out',
  NavigatedToPage = 'Navigated to page',
  OpenedToolDetails = 'Opened tool details',
  ClosedToolDetails = 'Closed tool details',
  SearchedForTool = 'Searched for tool',
  OpenedNotificationDetails = 'Opened notification details',
  ClosedNotificationDetails = 'Closed notification details',
  OpenedNotifications = 'Opened notifications',
  OpenedTool = 'Opened tool',
  OpenedWelcomePage = 'Opened Welcome Page',
  SubmittedAccessRequest = 'Submitted Access Request',
  ViewedAdminGroupsPage = 'Viewed Admin Groups Page',
  ViewedAdminUsersPage = 'Viewed Admin Users Page',
  AddedGroup = 'Added group',
  EditedGroup = 'Edited group',
  DeletedGroup = 'Deleted group',
  OpenedAddGroupModal = 'Opened add group modal',
  OpenedEditGroupModal = 'Opened edit group modal',
  OpenedDeleteGroupModal = 'Opened delete group modal',
  ClosedAddGroupModal = 'Closed add group modal',
  ClosedEditGroupModal = 'Closed edit group modal',
  ClosedDeleteGroupModal = 'Closed delete group modal',
  ApprovedAccessRequest = 'Approved Access Request',
  RejectedAccessRequest = 'Rejected Access Request',
  ViewedKnowledgeBaseArticle = 'Viewed knowledge base article',
  SharedTool = 'Shared tool',
  AddedUser = 'Added user',
  EditedUser = 'Edited user',
  ResentWelcomeEmail = 'Resent Welcome Email',
  ResetOwnPassword = 'Reset own password',
  ResetOtherUsersPassword = 'Reset other user\'s password',
  AddedTool = 'Added tool',
  EditedTool = 'Edited tool',
  DeletedTool = 'Deleted tool',
  CreatedNotification = 'Created a notification',
  EditedNotification = 'Edited a notification',
  DeletedNotification = 'Deleted a notification',
  AddedAssignmentTemplate = 'Added assignment template',
  EditedAssignmentTemplate = 'Edited assignment template',
  DismissedNotificationsBanner = 'Dismissed notifications banner',
  CopiedNotificationLinkModal = 'Copied notification link from modal',
  CopiedNotificationLinkTable = 'Copied notification link from administration table',
  OpenedNotificationLink = 'Opened notification link',
  ClosedNotificationLink = 'Closed notification link',
  OpenedInvalidNotificationLink = 'Opened invalid notification link',
  NotificationAnchorClicked = 'Clicked on a link in a notification',
  OpenedView = 'Opened a view',
  AmsAddedApplication = 'AMS - Added Application',
  AmsEditedApplication = 'AMS - Edited Application',
  AmsDeletedApplication = 'AMS - Deleted Application',
  AmsAddedProfile = 'AMS - Added Profile',
  AmsEditedProfile = 'AMS - Edited Profile',
  AmsDeletedProfile = 'AMS - Deleted Profile',
  AmsAssignedProfileApp = 'AMS - Assigned Profile Application',
  AmsEditedProfileAppRoles = 'AMS - Eddited Profile Application Roles',
  AmsUnassignedProfileApp = 'AMS - Unassigned Profile Application',
  AmsEditedApplicationStructure = 'AMS - Eddited Application Roles/Resources/Permissions',
  ToggledToolFullScreen = 'Toggled tool fullscreen mode'
}

export default MixpanelEvents
