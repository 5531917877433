import { createStore, createLogger } from 'vuex'
import app, { IAppState } from './appStore'
import assignments, { IAssignmentsState } from './assignmentsStore'
import notifications, { INotificationsState } from './notificationsStore'
import session, { ISessionState } from './sessionStore'
import ams, { IAmsState } from './amsStore'

export interface IRootState {
  session: ISessionState,
  app: IAppState,
  assignments: IAssignmentsState,
  notifications: INotificationsState,
  ams: IAmsState
}

// This is the root store
// All store modules are registered here
export default createStore<IRootState>({
  modules: {
    session,
    app,
    assignments,
    notifications,
    ams
  },
  strict: true,
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})
