import moment from 'moment'

export default function adjustDateToUserTz (date: Date|string): Date {
  if (typeof date === 'string') {
    date = moment(date).toDate()
  }

  const userTz = new Date().getTimezoneOffset()

  try {
    const adjusted = moment(date).add(userTz * -1, 'minutes')
    if (adjusted.isValid()) {
      return adjusted.toDate()
    } else {
      return date
    }
  } catch {
    return date
  }
}
