export function prepareStringUrlValue (name: string): string {
  return name
    .replace(/[^A-Za-z0-9]/g, ';;')
    .split(';;')
    .map((part: string) => part ? part[0].toUpperCase() + part.slice(1) : '')
    .join('')
}

export function prepareB64JSONStringValue (params: Record<any, any> = {}): string {
  return btoa(JSON.stringify(params))
}

export function parseB64JSONStringValue (params: string): Record<any, any> {
  return JSON.parse(atob(params))
}
