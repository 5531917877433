<template>
  <HeaderLink to="/assignments">
    {{ t('layout.header.link_assignments') }}
    <span
      v-if="tasksCount"
      class="badge badge-pill badge-light ml-2"
    >{{ tasksCount }}</span>
  </HeaderLink>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import HeaderLink from '@/layout/components/HeaderLink.vue'
import { useStore } from 'vuex'
import useInterval from '@/utils/interval'
import useAssignmentsCount from '@/composables/assignments/useAssignmentsCount'

export default defineComponent({
  name: 'HeaderAssignments',
  components: {
    HeaderLink
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const { updateGlobalAssignmentsCount } = useAssignmentsCount()

    const tasksCount = computed((): number => store.state.assignments.taskCount.due)

    let clearInterval: () => void

    onMounted((): void => {
      clearInterval = useInterval(updateGlobalAssignmentsCount, 10 * 60 * 1000, true, 'HeaderAssignments')
    })

    onUnmounted((): void => {
      clearInterval()
    })

    return {
      t,
      tasksCount
    }
  }
})
</script>

<style scoped lang="scss">

</style>
