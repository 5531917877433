import { AccountInfo, AuthenticationResult, Configuration, EventMessage, EventType, LogLevel, PublicClientApplication, RedirectRequest } from '@azure/msal-browser'
import store from '@/store'
import Service from './service'
import IUserDetails from '@/interfaces/user'
import { SessionActions } from '@/store/sessionStore'
import { IApiResponse } from '@/interfaces/api'
import IAppSettings from '@/interfaces/appSettings'

export interface ISignInRequest {
  login: string,
  passwd: string
}

export interface ISignInSSOResponse {
  redirectUrl: string
}

export interface ISignOutResponse {
  redirectUrl: string|null
}

export class AuthService extends Service {
  private msalInstance?: PublicClientApplication
  private msalLoginRequest?: RedirectRequest
  private nextUrl?: string
  private amsUrl?: string
  private amsAsUrl?: string
  private facilityDataServiceUrl?: string

  getMsalInstance (): PublicClientApplication|undefined {
    return this.msalInstance
  }

  getMsalLoginRequest (): RedirectRequest {
    return this.msalLoginRequest || { scopes: [] }
  }

  getScopes (): string[] {
    return this.msalLoginRequest ? this.msalLoginRequest.scopes : []
  }

  getNextUrl (): string | undefined {
    return this.nextUrl
  }

  setupMsal (msalConfig: Configuration, loginRequest: RedirectRequest): PublicClientApplication {
    this.msalInstance = new PublicClientApplication(
      {
        ...msalConfig,
        system: {
          loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
              if (containsPii) {
                return
              }
              switch (level) {
              case LogLevel.Error:
                console.error(message)
                return
              case LogLevel.Info:
                console.info(message)
                return
              case LogLevel.Verbose:
                console.debug(message)
                return
              case LogLevel.Warning:
                console.warn(message)
                break
              default:
                break
              }
            },
            logLevel: LogLevel.Error
          }
        }
      }
    )
    this.msalLoginRequest = loginRequest

    const accounts = this.msalInstance.getAllAccounts()
    if (accounts.length > 0) {
      this.msalInstance.setActiveAccount(accounts[0])
    }

    // handlers
    this.msalInstance.addEventCallback(async (message: EventMessage) => {
      if (!message.payload || !this.msalInstance) {
        return
      }

      switch (message.eventType) {
      case EventType.ACCOUNT_ADDED:
      case EventType.ACCOUNT_REMOVED:
        break

      case EventType.LOGIN_SUCCESS:
        if (message.payload && this.msalInstance) {
          const payload = message.payload as AuthenticationResult
          this.msalInstance.setActiveAccount(payload.account)
          this.nextUrl = payload.state
        }
        break
      case EventType.SSO_SILENT_SUCCESS:
      case EventType.HANDLE_REDIRECT_END:
      case EventType.LOGIN_FAILURE:
      case EventType.SSO_SILENT_FAILURE:
      case EventType.LOGOUT_END:
      case EventType.ACQUIRE_TOKEN_SUCCESS:
      case EventType.ACQUIRE_TOKEN_FAILURE:
        break
      }
    })

    return this.msalInstance
  }

  setActiveAccount (account: AccountInfo | null): AccountInfo | null {
    if (!this.msalInstance) {
      return null
    }
    this.msalInstance.setActiveAccount(account)
    return account
  }

  async msalLogin (nextUrl: string) {
    if (!this.msalInstance || !this.msalLoginRequest) {
      return
    }

    const accounts = this.msalInstance.getAllAccounts()

    if (accounts.length === 0) {
      // No user signed in
      await this.msalInstance.loginRedirect({ ...this.msalLoginRequest, redirectStartPage: nextUrl })
    } else if (accounts.length > 0) {
      await this.msalInstance.loginRedirect({ ...this.msalLoginRequest, redirectStartPage: nextUrl, account: accounts[0] })
    }
  }

  async logout () {
    if (this.msalInstance && this.msalInstance.getAllAccounts().length > 0) {
      await this.signOutFromApexPlatform()
      await store.dispatch(SessionActions.LogOut)
      await this.msalInstance.logoutRedirect()
    }

    if (store.getters.isLoggedIn) {
      await this.signOutFromApexPlatform()
      await store.dispatch(SessionActions.LogOut)
    }
  }

  async signInWithCredentials (credentials: ISignInRequest): Promise<IApiResponse<IUserDetails>> {
    return this.post('/auth/signin', credentials)
  }

  async signOutFromApexPlatform (): Promise<IApiResponse<void>> {
    return this.post('/auth/signout')
  }

  async getUserDetails (): Promise<IApiResponse<IUserDetails>> {
    return this.get('/auth/user-details')
  }

  setAmsUrls (settings: IAppSettings): void {
    this.amsUrl = settings.amsUrl
    this.amsAsUrl = settings.amsAsUrl
    this.facilityDataServiceUrl = settings.facilityDataServiceUrl
  }

  getAmsUrl (): string|undefined {
    return this.amsUrl
  }

  getAmsAsUrl (): string|undefined {
    return this.amsAsUrl
  }

  getFacilityDataServiceUrl (): string|undefined {
    return this.facilityDataServiceUrl
  }
}

export default new AuthService()
