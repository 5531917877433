import axios from 'axios'

export interface IParams {
  [key: string]: string|number|string[]|number[]
}

// Base service abstract class
// Contains common methods for each service
export default abstract class Service {
  protected prefix: string
  protected snakeCaseParser: boolean

  constructor (prefix?: string, snakeCaseParser?: boolean) {
    this.prefix = prefix || '/api'
    this.snakeCaseParser = snakeCaseParser === undefined ? true : snakeCaseParser
  }

  public setUrlPrefix (prefix: string) {
    this.prefix = prefix
  }

  public async getUrl<T> (url: string, params: IParams = {}, config: Record<string, unknown> = {}): Promise<T> {
    const response = await axios.get(url, { ...config, params: params, snakeCaseParser: this.snakeCaseParser })
    return response?.data
  }

  public async headUrl (url: string): Promise<void> {
    return axios.head(url)
  }

  public async get<T> (url: string, params: IParams = {}, config: Record<string, unknown> = {}): Promise<T> {
    const response = await axios.get(`${this.prefix}${url}`, { ...config, params: params, snakeCaseParser: this.snakeCaseParser })
    return response.data
  }

  public async post<T1, T2> (url: string, data: T1|null = null, config = {}): Promise<T2> {
    const response = await axios.post(`${this.prefix}${url}`, data, { ...config, snakeCaseParser: this.snakeCaseParser })
    return response.data
  }

  public async delete<T1, T2> (url: string, data: T1|null = null): Promise<T2> {
    return axios.delete(`${this.prefix}${url}`, data ? { data, snakeCaseParser: this.snakeCaseParser } : { snakeCaseParser: this.snakeCaseParser })
  }

  public async put<T1, T2> (url: string, data: T1|null = null, config = {}): Promise<T2> {
    const response = await axios.put(`${this.prefix}${url}`, data, { ...config, snakeCaseParser: this.snakeCaseParser })
    return response.data
  }
}

export interface IApiResponse<T> {
  data: T;
  status: string;
}
