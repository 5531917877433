import { App } from 'vue'
import * as Sentry from '@sentry/vue'
import router from '@/router'
import ISentrySettings from '@/interfaces/sentrySettings'

const sentryConfig: ISentrySettings = (window as typeof window & { sentryConfig: ISentrySettings}).sentryConfig

export default function setupSentry (app: App): void {
  if (window.location.hostname.endsWith('.pb.com') || window.location.hostname.endsWith('.pitneycloud.com')) {
    Sentry.init({
      app,
      dsn: sentryConfig.dsn,
      trackComponents: true,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: true,
          stickySession: true,
          networkDetailAllowUrls: [
            // /^https:\/\/[^\/]*\.pb\.com/,
            // /^https:\/\/[^\/]*\.pitneycloud\.com/,
            window.location.origin
          ],
          networkDetailDenyUrls: []
        }),
        Sentry.replayCanvasIntegration()
      ],
      tracesSampleRate: parseFloat(sentryConfig.tracesSampleRate),
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: parseFloat(sentryConfig.replaysOnErrorSampleRate),
      environment: sentryConfig.environment,
      release: `apex@${sentryConfig.version}`
    })

    recordReplay(true)
  }
}

export function setSentryAppContext (isLocalhost: boolean): void {
  Sentry.setTag('localhost', isLocalhost)
}

export function recordReplay(record: boolean): void {
  if (window.location.hostname.endsWith('.pb.com') || window.location.hostname.endsWith('.pitneycloud.com')) {
    const replaysSessionSampleRate = parseFloat(sentryConfig.replaysSessionSampleRate) || 0.2
    const replay = Sentry.getReplay()

    if (!replay) {
      return
    }

    if(!record) {
      replay.stop()
      return
    }

    if (Math.random() <= replaysSessionSampleRate) {
      try {
        replay.start()
      } catch {
        // mute this error
      }
    }
  }
}