import { RouteRecordRaw } from 'vue-router'
import { PermissionsMode } from './routeGuards'
import { AmsAccessManagementResource } from '@/interfaces/ams'

export enum AmsNamedRoutes {
  Main = 'Access Management System',
  ApplicationList = 'Access Management System - Applications',
  ApplicationDetails = 'Access Management System - Application Details',
  ApplicationCreate = 'Access Management System - Create Application',
  ProfileList = 'Access Management System - Profiles',
  ProfileDetails = 'Access Management System - Profile Details',
  ProfileCreate = 'Access Management System - Create Profile'
}

export const amsRoutes: RouteRecordRaw = {
  path: 'ams',
  component: () => import(/* webpackChunkName: "ams" */ '@/views/admin/AdminAms.vue'),
  name: AmsNamedRoutes.Main,
  meta: {
    permissions: {
      values: ['ui.admin.ams.access'],
      mode: PermissionsMode.Any
    },
    amsResources: [
      AmsAccessManagementResource.ApplicationAdmin,
      AmsAccessManagementResource.ApplicationReader,
      AmsAccessManagementResource.ProfileAdmin,
      AmsAccessManagementResource.ProfileCreator,
      AmsAccessManagementResource.ProfileReader
    ]
  },
  children: [
    {
      path: 'applications',
      name: AmsNamedRoutes.ApplicationList,
      component: () => import(/* webpackChunkName: "ams" */ '@/views/admin/ams/AmsApplicationListView.vue'),
      meta: {
        amsResources: [
          AmsAccessManagementResource.ApplicationAdmin,
          AmsAccessManagementResource.ApplicationReader
        ]
      }
    },
    {
      path: 'applications/new',
      name: AmsNamedRoutes.ApplicationCreate,
      component: () => import(/* webpackChunkName: "ams" */ '@/views/admin/ams/AmsApplicationDetails.vue'),
      meta: {
        amsResources: [AmsAccessManagementResource.ApplicationAdmin]
      }
    },
    {
      path: 'applications/:appId',
      name: AmsNamedRoutes.ApplicationDetails,
      component: () => import(/* webpackChunkName: "ams" */ '@/views/admin/ams/AmsApplicationDetails.vue'),
      meta: {
        amsResources: [AmsAccessManagementResource.ApplicationAdmin]
      }
    },
    {
      path: 'profiles',
      name: AmsNamedRoutes.ProfileList,
      component: () => import(/* webpackChunkName: "ams" */ '@/views/admin/ams/AmsProfileListView.vue'),
      meta: {
        amsResources: [
          AmsAccessManagementResource.ProfileAdmin,
          AmsAccessManagementResource.ProfileCreator,
          AmsAccessManagementResource.ProfileReader
        ]
      }
    },
    {
      path: 'profiles/new',
      name: AmsNamedRoutes.ProfileCreate,
      component: () => import(/* webpackChunkName: "ams" */ '@/views/admin/ams/AmsProfileDetails.vue'),
      meta: {
        amsResources: [
          AmsAccessManagementResource.ProfileAdmin,
          AmsAccessManagementResource.ProfileCreator
        ]
      }
    },
    {
      path: 'profiles/:profileId',
      name: AmsNamedRoutes.ProfileDetails,
      component: () => import(/* webpackChunkName: "ams" */ '@/views/admin/ams/AmsProfileDetails.vue'),
      meta: {
        amsResources: [
          AmsAccessManagementResource.ProfileAdmin,
          AmsAccessManagementResource.ProfileCreator,
          AmsAccessManagementResource.ProfileReader
        ]
      }
    }
  ]
}
