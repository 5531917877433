import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { prepareB64JSONStringValue } from '@/utils/prepareStringUrlValue'
import store from '@/store/index'
import amsAsService, { IAmsAsMyRole } from '@/services/amsAsService'
import { SessionActions } from '@/store/sessionStore'
import { AmsAccessManagementResource } from '@/interfaces/ams'

/**
 * Permissions enum for hasRoles function
 */
export enum PermissionsMode {
  All = 'all',
  Any = 'any'
}

/**
 * Permissions interface
 */
interface IPermissions {
  mode?: PermissionsMode;
  values: string[];
}

const setTitle = (to: RouteLocationNormalized): void => {
  if (to.params.title) {
    window.document.title = `APEX - ${to.params.title}`
  } else if (to.name && to.name.toString().length) {
    window.document.title = `APEX - ${to.name.toString()}`
  } else {
    window.document.title = 'APEX'
  }
}

export const isAuthenticated = async (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext): Promise<boolean> => {
  if (to.meta && to.meta.allowAnonymous) {
    setTitle(to)
    return true
  }

  if (store.getters.isLoggedIn) {
    setTitle(to)
    return true
  } else {
    next({ name: 'Auth', query: { next: encodeURIComponent(to.fullPath) } })
    return false
  }
}

/**
 * Function which checks if the user has roles required by the requested endpoint
 * @param to requested page
 * @param next function which enables navigating to different pages
 * @returns {boolean} returns false if next was called
 */
export const hasRoles = (to: RouteLocationNormalized, next: NavigationGuardNext): boolean => {
  if (!to.meta || !to.meta.permissions) {
    return true
  }

  const permissions = to.meta.permissions as IPermissions

  if (!permissions.mode || permissions.mode === PermissionsMode.All) {
    if (permissions.values.some((permissionName: string) => !store.state.session.permissions.includes(permissionName))) {
      next(`/forbidden?e=${prepareB64JSONStringValue({ url: to.fullPath })}`)
      return false
    }
  } else {
    if (!permissions.values.some((permissionName: string) => store.state.session.permissions.includes(permissionName))) {
      next(`/forbidden?e=${prepareB64JSONStringValue({ url: to.fullPath })}`)
      return false
    }
  }

  return true
}

/**
 * Function which checks if the user belongs to any groups, if not it redirects to the /welcome page
 * @param to requested page
 * @param next function which enables navigating to different pages
 * @returns {boolean} returns false if next was called
 */
export const hasAnyGroup = (to: RouteLocationNormalized, next: NavigationGuardNext): boolean => {
  const name = to.name?.toString() || ''
  if (!['Welcome', 'Forbidden'].includes(name) &&
    store.getters.isLoggedIn &&
    store.state.session.groups.length === 0 &&
    !store.state.session.permissions.includes('ui.welcome.bypass')
  ) {
    next('/welcome')
    return false
  }
  return true
}

export const hasAmsResources = async (to: RouteLocationNormalized, next: NavigationGuardNext): Promise<boolean> => {
  if (!to.meta || !to.meta.amsResources) {
    return true
  }

  // fetch ams roles if not already initialized
  if (store.state.session.amsRoles === null) {
    try {
      const roles: IAmsAsMyRole[] = (await amsAsService.getMyAmsPermissions()).permissions
      await store.dispatch(SessionActions.UpdateAmsRoles, roles)
    } catch (error: any) {
      return false
    }
  }

  const resources = to.meta.amsResources as AmsAccessManagementResource[]
  if (store.getters.hasOneOfAccessManagementResources(resources)) {
    return true
  } else {
    next('/')
    return false
  }
}
