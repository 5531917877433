<template>
  <div class="login-container">
    <div class="login-content">
      <h1 class="login-title">
        {{ t("common.apex") }}
      </h1>
      <h5 class="login-subtitle">
        {{ t("common.apex_slogan") }}
      </h5>

      <div
        v-if="errorMessage"
        class="alert alert-danger"
      >
        {{ errorMessage }}
      </div>

      <button
        class="btn btn-page-primary btn-block btn-lg mt-2 mb-4 sso-login"
        :disabled="isSubmittingForm"
        tabindex="1"
        @click="submitSSORequest"
      >
        {{ t("views.login.sso_button") }}
      </button>

      <div class="login-form-separator">
        {{ t("views.login.separator") }}
      </div>

      <div class="login-direct">
        <div
          v-if="!directSignInFormEnabled"
          class="login-direct-title"
        >
          <h5>
            <a
              href="#"
              @click.prevent="showDirectSignInForm"
            >{{ t("views.login.direct_login") }}</a>
          </h5>
        </div>
        <div
          v-else
          class="login-direct-form"
        >
          <form @submit.prevent="submitForm">
            <ApexInput
              v-model="vuelidate.login.$model"
              :label="t('views.login.email_login_label')"
              input-id="login"
              type="text"
              auto-complete="username"
              :disabled="isSubmittingForm"
              tab-index="2"
              :errors="vuelidate.login.$errors"
            />

            <ApexInput
              v-model="vuelidate.passwd.$model"
              :label="t('views.login.password_label')"
              input-id="password"
              type="password"
              auto-complete="current-password"
              :disabled="isSubmittingForm"
              tab-index="3"
              :errors="vuelidate.passwd.$errors"
            />

            <button
              class="btn btn-page-secondary btn-block btn-lg mt-2 mb-4 login-submit-button"
              type="submit"
              :disabled="isSubmittingForm || vuelidate.$invalid"
              tabindex="4"
              @click="submitForm"
            >
              {{ t("views.login.login_button") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import ApexInput from '@/components/forms/ApexInput.vue'
import { useRoute, useRouter } from 'vue-router'
import { IMixpanelParams, trackMixpanelEvent } from '@/utils/mixpanel/setupMixpanel'
import MixpanelEvents from '@/utils/mixpanel/mixpanelEvents'
import authService from '@/services/authService'
import IUserDetails from '@/interfaces/user'
import { SessionActions } from '@/store/sessionStore'
import { AppActions } from '@/store/appStore'

export default defineComponent({
  name: 'LoginView',
  components: {
    ApexInput
  },
  setup () {
    // References
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()

    // View state
    const isSubmittingForm = ref(false)
    const errorMessage = ref('')
    const directSignInFormEnabled = ref(false)

    const errorCode = route.query.error?.toString()

    if (errorCode && ['sso_auth_error', 'sso_unknown_error', 'account_locked', 'session_expired', 'auth_server_error'].includes(errorCode)) {
      errorMessage.value = t(`views.login.${errorCode}`).toString()
    }
    const nextUrl = decodeURIComponent(route.query.next?.toString() || '/tools')

    // Form state and validation
    const formState = reactive({
      login: '',
      passwd: ''
    })

    const loginValidator = helpers.withMessage(t('views.login.email_login_required').toString(), required)
    const passwordValidator = helpers.withMessage(t('views.login.password_required').toString(), required)

    const formRules = {
      login: { required: loginValidator },
      passwd: { required: passwordValidator }
    }

    const vuelidate = useVuelidate(formRules, formState)

    const submitForm = async (): Promise<void> => {
      isSubmittingForm.value = true
      errorMessage.value = ''

      const isValid = await vuelidate.value.$validate()
      if (!isValid) {
        isSubmittingForm.value = false
        return
      }

      try {
        const userDetails: IUserDetails = (await authService.signInWithCredentials(formState)).data
        await store.dispatch(SessionActions.LogIn, userDetails)
        await store.dispatch(AppActions.SetState, 'ready')

        const params: IMixpanelParams = {
          sso: false
        }
        trackMixpanelEvent(MixpanelEvents.LoggedIn, params)

        redirectToNextPage()
      } catch (error: any) {
        formState.passwd = ''
        vuelidate.value.$reset()
        if (error.response && error.response.data) {
          errorMessage.value = error.response.data.status || error.response.data
        } else {
          errorMessage.value = t('common.unknown_error').toString()
        }

        const params: IMixpanelParams = {
          error: errorMessage.value,
          sso: false
        }
        trackMixpanelEvent(MixpanelEvents.LoginFailure, params)

        isSubmittingForm.value = false
      }
    }

    const submitSSORequest = async (): Promise<void> => {
      isSubmittingForm.value = true
      errorMessage.value = ''

      await authService.msalLogin(nextUrl)
      isSubmittingForm.value = false
    }

    const showDirectSignInForm = (): void => {
      directSignInFormEnabled.value = true
    }

    const redirectToNextPage = (): void => {
      router.push(nextUrl)
    }

    onMounted(() => {
      if (store.getters.isLoggedIn) {
        redirectToNextPage()
      }
    })

    return {
      isSubmittingForm,
      errorMessage,
      formState,
      vuelidate,
      submitForm,
      submitSSORequest,
      showDirectSignInForm,
      directSignInFormEnabled,
      t
    }
  }
})
</script>

<style scoped lang="scss">

  .login-container {
    width: 400px;
  }

  .login-content {
    padding: 2rem;
    margin-bottom: 6rem;
    background-color: #fff;
    border-radius: 6px;
  }

  .login-title {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }

  .login-subtitle {
    color: var(--text);
    font-size: 0.875rem;
    font-weight: normal;
    text-align: center;
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
  }

  .login-form-separator {
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;

    &::before, &::after {
      background-color: var(--rich300);
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &::before {
      right: 0.5em;
      margin-left: calc(-50% - 0.5em);
    }
    &::after {
      left: 0.5em;
      margin-right: calc(-50% + 0.5em);
    }
  }

  .sso-login {
    font-size: 1rem;
  }

  .login-submit-button {
    font-size: 1rem;
  }

  .login-direct-title {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    a {
      text-decoration: none;
      cursor: pointer;
    }
  }
</style>
