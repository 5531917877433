import { App } from 'vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import MultiSelect from 'primevue/multiselect'
import Checkbox from 'primevue/checkbox'
import Tooltip from 'primevue/tooltip'
import Dropdown from 'primevue/dropdown'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import AutoComplete from 'primevue/autocomplete'
import SelectButton from 'primevue/selectbutton'
import ConfirmDialog from 'primevue/confirmdialog'
import Calendar from 'primevue/calendar'
import ConfirmationService from 'primevue/confirmationservice'
import RadioButton from 'primevue/radiobutton'
import Breadcrumb from 'primevue/breadcrumb'
import Listbox from 'primevue/listbox'
import Tree from 'primevue/tree'
import SpeedDial from 'primevue/speeddial'

export default function setupPrime (app: App): void {
  app.use(ToastService)
  app.use(ConfirmationService)

  app.component('TabView', TabView)
  app.component('TabPanel', TabPanel)
  app.component('Toast', Toast)
  app.component('Dialog', Dialog)
  app.component('MultiSelect', MultiSelect)
  app.component('Checkbox', Checkbox)
  app.component('Dropdown', Dropdown)
  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
  app.component('DataTable', DataTable)
  app.component('Column', Column)
  app.component('AutoComplete', AutoComplete)
  app.component('SelectButton', SelectButton)
  app.component('ConfirmDialog', ConfirmDialog)
  app.component('Calendar', Calendar)
  app.component('RadioButton', RadioButton)
  app.component('Breadcrumb', Breadcrumb)
  app.component('Listbox', Listbox)
  app.component('Tree', Tree)
  app.component('SpeedDial', SpeedDial)

  app.directive('tooltip', Tooltip)
}
