import router from '@/router'
import store from '@/store'
import { AppActions } from '@/store/appStore'
import { SessionActions } from '@/store/sessionStore'
import axios from 'axios'
import { prepareB64JSONStringValue } from '../prepareStringUrlValue'

export default function setupHttpErrorInterceptor (): void {
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response && error.response.status === 503) {
        store.dispatch(SessionActions.LogOut)
        store.dispatch(AppActions.SetState, 'maintenance')
        return Promise.reject(error)
      } else if (error.response && error.response.status === 401 && router.currentRoute.value.name !== 'Login') {
        store.dispatch(SessionActions.LogOut)
        store.dispatch(AppActions.SetState, 'ready')
        router.push('/login')
        return Promise.reject(error)
      } else if (error.response && error.response.status === 403) {
        if (!error.config.handles403) {
          if (router.currentRoute.value.params.toolId) {
            router.replace(`/forbidden?e=${prepareB64JSONStringValue({ url: location.pathname, toolId: router.currentRoute.value.params.toolId })}`)
          } else {
            router.replace(`/forbidden?e=${prepareB64JSONStringValue({ url: location.pathname })}`)
          }
        }
        return Promise.reject(error)
      } else {
        return Promise.reject(error)
      }
    }
  )
}
