import axios from 'axios'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { AuthService } from '@/services/authService'

export default function setupBearerTokenInterceptor (authService: AuthService): void {
  axios.interceptors.request.use(
    async (config: any) => {
      if (config.withCredentials === false) {
        return config
      }

      const msal = authService.getMsalInstance()

      if (msal) {
        const account = msal.getAllAccounts()[0]
        // add bearer token if azure authenticated
        if (account) {
          const accessTokenResponse = await msal.acquireTokenSilent({
            scopes: authService.getScopes(),
            account: account
          }).catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
              await msal.acquireTokenRedirect({
                scopes: authService.getScopes(),
                account: account
              })
            }
          })
          if (accessTokenResponse) {
            const accessToken = accessTokenResponse.accessToken
            if (config.headers && accessToken) {
              config.headers.Authorization = 'Bearer ' + accessToken
            }
          }
        }
      }
      return config
    },
    error => {
      Promise.reject(error)
    })
}
