import { ActionContext } from 'vuex'
import store, { IRootState } from '@/store/index'
import INotification from '@/interfaces/notification'
import moment from 'moment'

enum NotificationsMutations {
  AddNotifications = 'ADD_NOTIFICATIONS_MUTATION',
  SetLastViewedId = 'SET_LAST_VIEWED_ID_MUTATION',
  SetLastDismissedBannerId = 'SET_LAST_DISMISSED_BANNER_ID_MUTATION',
  SetNextUrl = 'SET_NEXT_URL_MUTATION',
  RestoreState = 'RESTORE_NOTIFICATIONS_STATE_MUTATION',
  ClearState = 'CLEAR_NOTIFICATIONS_STATE_MUTATION',
  DeleteNotification = 'DELETE_NOTIFICATIONS_MUTATION',
  SetToolNotifications = 'SET_TOOL_NOTIFICATIONS_MUTATION'
}

export enum NotificationsActions {
  AddNotifications = 'ADD_NOTIFICATIONS_ACTION',
  SetLastViewedId = 'SET_LAST_VIEWED_ID_ACTION',
  SetLastDismissedBannerId = 'SET_LAST_DISMISSED_BANNER_ID_ACTION',
  SetNextUrl = 'SET_NEXT_URL_ACTION',
  RestoreState = 'RESTORE_NOTIFICATIONS_STATE_ACTION',
  ClearState = 'CLEAR_NOTIFICATIONS_STATE_ACTION',
  DeleteNotification = 'DELETE_NOTIFICATIONS_ACTION',
  SetToolNotifications = 'SET_TOOL_NOTIFICATIONS_ACTION'
}

export interface INotificationsState {
  notifications: INotification[]
  lastViewedId: number
  lastDismissedBannerId: number
  nextUrl: string|null
  toolNotifications: INotification[]
}

const storeState = (state: INotificationsState, userId: number): void => {
  localStorage.setItem(`notifications.${userId}.lastViewedId`, state.lastViewedId.toString())
  localStorage.setItem(`notifications.${userId}.lastDismissedBannerId`, state.lastDismissedBannerId.toString())

  localStorage.setItem('session.notifications.notifications', JSON.stringify(state.notifications))
  localStorage.setItem('session.notifications.nextUrl', state.nextUrl || '')
}

const clearState = (): void => {
  localStorage.removeItem('session.notifications.notifications')
  localStorage.removeItem('session.notifications.nextUrl')
}

const getDefaultState = (): INotificationsState => {
  return {
    notifications: [],
    toolNotifications: [],
    lastViewedId: 0,
    lastDismissedBannerId: 0,
    nextUrl: null
  }
}

export default {
  state: getDefaultState,
  mutations: {
    [NotificationsMutations.AddNotifications] (state: INotificationsState, payload: INotification[]): void {
      const newNotifications = payload.filter((newNotification: INotification) => {
        return !state.notifications.find((oldNotification: INotification) => newNotification.id === oldNotification.id)
      })

      const inactiveNewNotifications = newNotifications.filter(
        notification => !notification.active
      )

      const activeNewNotifications = newNotifications.filter(
        notification => notification.active
      )

      const inactiveNotifications = state.notifications.filter(
        notification => !notification.active || moment(notification.endDate) < moment()
      )

      const activeNotifications = state.notifications.filter(
        notification => notification.active && moment(notification.endDate) >= moment()
      )

      const allNotifications = [...activeNewNotifications, ...activeNotifications, ...inactiveNotifications, ...inactiveNewNotifications]
      // Update existing notifications
      payload.forEach((newNotification: INotification) => {
        const oldNotification = allNotifications.find((oldNotification: INotification) => oldNotification.id === newNotification.id)
        if (!oldNotification) {
          return
        }

        allNotifications[allNotifications.indexOf(oldNotification)] = newNotification
      })
      inactiveNotifications.forEach((notification: INotification) => {
        // Update active state for notification that have expired
        notification.active = false
      })

      state.notifications = allNotifications
    },
    [NotificationsMutations.SetLastViewedId] (state: INotificationsState, payload: number): void {
      state.lastViewedId = payload
    },
    [NotificationsMutations.SetLastDismissedBannerId] (state: INotificationsState, payload: number): void {
      state.lastDismissedBannerId = Math.max(payload, state.lastDismissedBannerId)
    },
    [NotificationsMutations.SetNextUrl] (state: INotificationsState, payload: string|null): void {
      state.nextUrl = payload
    },
    [NotificationsMutations.RestoreState] (state: INotificationsState, payload: number): void {
      const lastViewedId = parseInt(localStorage.getItem(`notifications.${payload}.lastViewedId`) || '0')
      const lastDismissedBannerId = parseInt(localStorage.getItem(`notifications.${payload}.lastDismissedBannerId`) || '0')

      const notificationsJson = localStorage.getItem('session.notifications.notifications')
      const nextUrl = localStorage.getItem('session.notifications.nextUrl')

      if (notificationsJson) {
        try {
          state.notifications = JSON.parse(notificationsJson)
          state.nextUrl = nextUrl
        } catch {
          // ignore and do nothing
        }
      }

      state.lastViewedId = lastViewedId
      state.lastDismissedBannerId = lastDismissedBannerId
    },
    [NotificationsMutations.ClearState] (state: INotificationsState): void {
      const defaultValues = getDefaultState()
      state.notifications = defaultValues.notifications
      state.lastViewedId = defaultValues.lastViewedId
      state.lastDismissedBannerId = defaultValues.lastDismissedBannerId
      state.nextUrl = defaultValues.nextUrl
    },
    [NotificationsMutations.DeleteNotification] (state: INotificationsState, notificationId: number): void {
      state.notifications = state.notifications.filter((notification: INotification) => notification.id !== notificationId)
    },
    [NotificationsMutations.SetToolNotifications] (state: INotificationsState, payload: INotification[]): void {
      state.toolNotifications = payload
    }
  },
  actions: {
    [NotificationsActions.AddNotifications] (context: ActionContext<INotificationsState, IRootState>, notifications: INotification[]): void {
      context.commit(NotificationsMutations.AddNotifications, notifications)
      if (context.rootState.session.currentUser) {
        storeState(context.state, context.rootState.session.currentUser.id)
      }
    },
    [NotificationsActions.SetNextUrl] (context: ActionContext<INotificationsState, IRootState>, nextUrl: string|null): void {
      context.commit(NotificationsMutations.SetNextUrl, nextUrl)
      if (context.rootState.session.currentUser) {
        storeState(context.state, context.rootState.session.currentUser.id)
      }
    },
    [NotificationsActions.SetLastViewedId] (context: ActionContext<INotificationsState, IRootState>, lastViewedId: number): void {
      context.commit(NotificationsMutations.SetLastViewedId, lastViewedId)
      if (context.rootState.session.currentUser) {
        storeState(context.state, context.rootState.session.currentUser.id)
      }
    },
    [NotificationsActions.SetLastDismissedBannerId] (context: ActionContext<INotificationsState, IRootState>, lastDismissedBannerId: number): void {
      context.commit(NotificationsMutations.SetLastDismissedBannerId, lastDismissedBannerId)
      if (context.rootState.session.currentUser) {
        storeState(context.state, context.rootState.session.currentUser.id)
      }
    },
    [NotificationsActions.RestoreState] (context: ActionContext<INotificationsState, IRootState>): void {
      if (context.rootState.session.currentUser) {
        context.commit(NotificationsMutations.RestoreState, context.rootState.session.currentUser.id)
      }
    },
    [NotificationsActions.ClearState] (context: ActionContext<INotification, IRootState>): void {
      context.commit(NotificationsMutations.ClearState)
      clearState()
    },
    [NotificationsActions.DeleteNotification] (context: ActionContext<INotification, IRootState>, notificationId: number): void {
      context.commit(NotificationsMutations.DeleteNotification, notificationId)
      clearState()
    },
    [NotificationsActions.SetToolNotifications] (context: ActionContext<INotificationsState, IRootState>, notifications: INotification[]): void {
      context.commit(NotificationsMutations.SetToolNotifications, notifications)
    }
  }
}

window.addEventListener('storage', function (event: StorageEvent): void {
  if (store.state.session.currentUser) {
    if (event.key && [
      'session.notifications.notifications',
      'session.notifications.nextUrl',
      `notifications.${store.state.session.currentUser.id}.lastViewedId`,
      `notifications.${store.state.session.currentUser.id}.lastDismissedBannerId`
    ].includes(event.key)) {
      store.commit(NotificationsMutations.RestoreState, store.state.session.currentUser.id)
    }
  }
})
