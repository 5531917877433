<template>
  <footer class="apex-footer">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-xs-12 col-md-8 col-lg-6">
          <div class="footer-logo-copyright">
            <a
              class="footer-logo"
              href="https://www.pitneybowes.com"
            />
            <span class="footer-copyright">© Pitney Bowes Inc.
              <span>{{ t("layout.footer.all_rights_reserved") }}</span>
            </span>
          </div>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-6 text-center text-md-right">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a
                href="https://www.pitneybowes.com/us/legal.html"
                target="_blank"
              >{{ t("layout.footer.legal") }}</a>
            </li>
            <li class="list-inline-item">
              <a
                :href="`mailto:${supportEmail}`"
                target="_blank"
              >{{ t("layout.footer.contact_us") }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'FooterComponent',
  setup () {
    const { t } = useI18n()
    const store = useStore()

    const supportEmail = computed(() => {
      return store.state.app.environment.supportEmail
    })

    return {
      t,
      supportEmail
    }
  }
})
</script>

<style scoped lang="scss">
  footer {
    position: inherit;
    width: 100%;
  }

  @media screen and (max-width: 575.98px), screen and (max-height: 575.98px) {
    .apex-footer {
      display: none;
      padding: 0px;
    }
  }
</style>
