import { IUser } from '@/interfaces/user'
import * as Sentry from '@sentry/vue'

export function setSentryUser (user: IUser): void {
  if (window.location.hostname.endsWith('.pb.com') || window.location.hostname.endsWith('.pitneycloud.com')) {
    Sentry.setUser({
      id: user.id.toString(),
      email: user.email,
      type: user.type.pid
    })
  }
}

export function unsetSentryUser (): void {
  if (window.location.hostname.endsWith('.pb.com') || window.location.hostname.endsWith('.pitneycloud.com')) {
    Sentry.setUser(null)
  }
}
