<template>
  <BaseModal
    :visible="displayModal"
    :header="notificationTitle"
    @close="closeModal"
  >
    <template #header>
      <div class="row col-12">
        <div class="col-12 col-md-10">
          <div
            v-if="notifications && notifications.length > 1"
            class="align-items-center notifications-banner-navigation"
          >
            <span
              class="notifications-banner-prev"
              :title="t('components.notifications.notification_modal.previous_notification')"
              @click="prevNotification"
            >
              <i class="pbi-icon-mini pbi-minimal-left" />
            </span>
            <span class="notifications-banner-counter">{{ currentNotificationNumber + 1 }}/{{ notifications.length }}</span>
            <span
              class="notifications-banner-next"
              :title="t('components.notifications.notification_modal.next_notification')"
              @click="nextNotification"
            ><i class="pbi-icon-mini pbi-minimal-right" /></span>
          </div>
          <span class="p-dialog-title">{{ notificationTitle }}</span>
        </div>
        <div class="col-12 col-md-2 modal-icons">
          <div class="copy-link-action">
            <span class="copy-link">
              <i
                v-tooltip.bottom="{ value: t('common.copy_link').toString() }"
                class="pbi-icon-mini pbi-clipboard"
                @click="copyLinkToNotification"
              />
            </span>
          </div>
        </div>
      </div>
    </template>
    <div class="modal-notification-section">
      <pre class="modal-notification-content"><p
@click="handleAnchorClick"
                                                 v-html="content"
      /></pre>
    </div>
    <div class="modal-notification-section">
      <h4 class="details">
        {{ t('components.notifications.notification_modal.details') }}
      </h4>
      <div class="row wrap-row">
        <div class="col-2 col-no-padding">
          {{ t('components.notifications.notification_modal.author') }}:
        </div>
        <div class="col col-no-padding">
          {{ author }}
        </div>
      </div>
      <div class="row wrap-row">
        <div class="col-2 col-no-padding">
          {{ t('components.notifications.notification_modal.created_on') }}:
        </div>
        <div class="col col-no-padding">
          {{ createdOn }}
        </div>
      </div>
      <div class="row wrap-row">
        <div class="col-2 col-no-padding">
          {{ t('components.notifications.notification_modal.start_date') }}:
        </div>
        <div class="col col-no-padding">
          {{ startDate }}
        </div>
      </div>
      <div class="row wrap-row">
        <div class="col-2 col-no-padding">
          {{ t('components.notifications.notification_modal.end_date') }}:
        </div>
        <div class="col col-no-padding">
          {{ endDate }}
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import INotification from '@/interfaces/notification'
import BaseModal from '@/components/utility/BaseModal.vue'
import moment from 'moment'
import adjustDateToUserTz from '@/utils/adjustDateToUserTz'
import { useI18n } from 'vue-i18n'
import { prepareStringUrlValue } from '@/utils/prepareStringUrlValue'
import useToasts from '@/composables/useToasts'
import { trackMixpanelEvent } from '@/utils/mixpanel/setupMixpanel'
import MixpanelEvents from '@/utils/mixpanel/mixpanelEvents'
import useMarkdown from '@/composables/useMarkdown'

export default defineComponent({
  name: 'NotificationModal',
  components: {
    BaseModal
  },
  props: {
    notification: {
      type: Object as PropType<INotification|null>,
      required: false,
      default: null
    },
    notifications: {
      type: Array as PropType<INotification[]|null>,
      required: false,
      default: () => []
    }
  },
  emits: ['close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const toast = useToasts()
    const { generateHtml } = useMarkdown()

    const closeModal = (): void => {
      emit('close')
    }
    const currentNotificationNumber = ref(0)
    const currentNotification = computed((): INotification|null => {
      if (props.notification) {
        return props.notification
      }

      if (props.notifications?.length) {
        return props.notifications[currentNotificationNumber.value]
      }

      return null
    })

    const displayModal = computed((): boolean => {
      return currentNotification.value !== null
    })

    const notificationTitle = computed((): string => {
      if (!currentNotification.value) {
        return ''
      }

      return currentNotification.value.title
    })

    const content = computed((): string => {
      if (!currentNotification.value) {
        return ''
      }
      return generateHtml(currentNotification.value.content)
    })

    const author = computed((): string => {
      if (!currentNotification.value) {
        return ''
      }

      if (currentNotification.value.createdBy.firstName && currentNotification.value.createdBy.lastName) {
        return `${currentNotification.value.createdBy.firstName} ${currentNotification.value.createdBy.lastName}`
      }

      return currentNotification.value.createdBy.login
    })

    const createdOn = computed((): string => {
      if (!currentNotification.value) {
        return ''
      }
      const date = moment(adjustDateToUserTz(currentNotification.value.createdOn))
      return `${date.format('L')} ${date.format('LTS')}`
    })

    const startDate = computed((): string => {
      if (!currentNotification.value) {
        return ''
      }

      const date = moment(adjustDateToUserTz(currentNotification.value.startDate))
      return `${date.format('L')} ${date.format('LTS')}`
    })

    const endDate = computed((): string => {
      if (!currentNotification.value) {
        return ''
      }

      const date = moment(adjustDateToUserTz(currentNotification.value.endDate))
      return `${date.format('L')} ${date.format('LTS')}`
    })

    const nextNotification = (): void => {
      if (props.notifications?.length) {
        if (currentNotificationNumber.value + 1 >= props.notifications.length) {
          currentNotificationNumber.value = 0
        } else {
          currentNotificationNumber.value += 1
        }
      }
    }

    const prevNotification = (): void => {
      if (props.notifications?.length) {
        if (currentNotificationNumber.value - 1 < 0) {
          currentNotificationNumber.value = props.notifications.length - 1
        } else {
          currentNotificationNumber.value -= 1
        }
      }
    }

    watch(() => props.notifications, () => {
      currentNotificationNumber.value = 0
    })

    const copyLinkToNotification = async () => {
      if (!currentNotification.value) {
        return
      }
      const url = new URL(
        `/notifications/${currentNotification.value.uuid}/${prepareStringUrlValue(currentNotification.value.title)}`,
        location.origin
      )
      try {
        await navigator.clipboard.writeText(url.toString())
        toast.showSuccess(
          t('common.link_copied'),
          t('common.link_copied_details')
        )
        trackMixpanelEvent(MixpanelEvents.CopiedNotificationLinkModal, {
          notificationId: currentNotification.value.id,
          notificationTitle: currentNotification.value.title
        })
      } catch {
        toast.showError(
          t('common.link_copy_failed'),
          t('common.link_copy_failed_details')
        )
      }
    }

    const handleAnchorClick = (event: Event) => {
      if (event.target instanceof HTMLAnchorElement && currentNotification.value) {
        trackMixpanelEvent(MixpanelEvents.NotificationAnchorClicked, {
          notificationId: currentNotification.value.id,
          notificationTitle: currentNotification.value.title,
          urlOpened: event.target.href,
          urlName: event.target.innerText
        })
      }

      return true
    }

    return {
      closeModal,
      displayModal,
      notificationTitle,
      content,
      author,
      createdOn,
      startDate,
      endDate,
      t,
      nextNotification,
      prevNotification,
      currentNotificationNumber,
      copyLinkToNotification,
      handleAnchorClick
    }
  }
})
</script>

<style scoped lang="scss">
.modal-notification-content {
  white-space: normal;
  font-family: inherit;
  font-size: .875rem;
  color: var(--text);
  width: 100%;
  position: relative;
  :deep(table) {
    margin-bottom: 1rem;
  }
  :deep(img) {
    max-width: 100%;
  }

  :deep(th), :deep(td) {
    border: 1px solid #ddd;
    padding: 5px;
  }
}
.details {
  border-bottom: 1px solid gainsboro;
}
@media (max-width: 500px) {
  .wrap-row {
    &.row {
      display: block;
    }
    .col, .col-2 {
      max-width: 100%;
      flex: none;
    }
  }
}
.notifications-banner-navigation {
  margin-right: 10px;
  user-select: none;
  display: inline-block;
  vertical-align: text-bottom;
}
.notifications-banner-next, .notifications-banner-prev {
  font-size: 12px;
  cursor: pointer;
}

.copy-link {
  cursor: pointer;
  line-height: 36px;
}
.copy-link-action {
  border-color: var(--rich300) !important;
  height: 36px;
  width: 36px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-color: #e8e8e8 !important;
  border-style: solid;
  border-width: thin;
}
.modal-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.col-12.col-md-8,
.row.col-12 {
  padding: 0;
}
</style>
