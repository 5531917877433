import showdown from 'showdown'
import { computed } from 'vue'
import { useStore } from 'vuex'
import sanitizeHtml from 'sanitize-html'

export default function useMarkdown (): { generateHtml(template: string): string } {
  const store = useStore()
  const user = computed(() => store.state.session.currentUser)

  const converter = new showdown.Converter({
    strikethrough: true,
    tables: true,
    tasklists: true,
    simpleLineBreaks: true,
    openLinksInNewWindow: true
  })

  const generateHtml = (template: string): string => {
    if (!template) {
      return ""
    }
    const replacements: Record<string, string> = {
      '$user.firstName': user.value.firstName,
      '$user.lastName': user.value.lastName,
      '$user.login': user.value.login,
      '$user.email': user.value.email
    }

    Object.keys(replacements).forEach((replacement: string) => {
      template = template.replaceAll(replacement, replacements[replacement])
    })

    return converter.makeHtml(sanitizeHtml(template, { allowedAttributes: {}, allowedTags: [], disallowedTagsMode: 'recursiveEscape' }))
  }

  return {
    generateHtml
  }
}
