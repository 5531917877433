<template>
  <div
    v-if="notifications.length > 0 && !dismissed"
    class="notifications-banner"
    :class="bannerClass"
  >
    <div class="row">
      <div
        v-if="notifications.length > 1"
        class="align-items-center notifications-banner-navigation"
      >
        <span
          class="notifications-banner-prev"
          :title="t('components.notifications.notification_banner.previous_notification')"
          @click="previousBanner"
        >
          <i class="pbi-icon-mini pbi-minimal-left" />
        </span>
        <span class="notifications-banner-counter">{{ currentBannerNumber + 1 }}/{{ notifications.length }}</span>
        <span
          class="notifications-banner-next"
          :title="t('components.notifications.notification_banner.next_notification')"
          @click="nextBanner"
        ><i class="pbi-icon-mini pbi-minimal-right" /></span>
      </div>
      <div
        v-else
        class="notifications-spacer"
      />
      <div class="notification-icon">
        <i
          class="pbi-icon-mini"
          :class="iconClass"
        />
      </div>
      <div class="col notifications-banner-container">
        <div class="notifications-banner-content">
          <div class="notifications-banner-title">
            {{ currentBannerTitle }}
          </div>
          <div>
            <span class="notifications-banner-description">{{ currentBannerDescription }}</span>
            <span
              class="notifications-banner-details"
              @click="openNotification"
            >[{{ t('components.notifications.notification_banner.more_details') }}]</span>
          </div>
        </div>
      </div>
      <div class="notification-banner-dismiss-container">
        <span
          class="notifications-banner-dismiss"
          :title="t('components.notifications.notification_banner.dismiss_all')"
          @click="dismissBanners"
        ><i class="pbi-icon-mini pbi-close" /></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue'
import { useStore } from 'vuex'
import INotification from '@/interfaces/notification'
import trimValue from '@/utils/trim'
import { NotificationsActions } from '@/store/notificationsStore'
import { useI18n } from 'vue-i18n'
import useMarkdown from '@/composables/useMarkdown'

export default defineComponent({
  name: 'NotificationBanner',
  props: {
    notifications: {
      type: Array as PropType<INotification[]>,
      default: () => []
    },
    saveStateOnDismiss: {
      type: Boolean,
      default: true
    },
    showAtBottom: {
      type: Boolean,
      default: false
    }
  },
  emits: ['notification', 'dismissed'],
  setup (props, { emit }) {
    const store = useStore()
    const { t } = useI18n()

    const dismissed = ref(false)

    const { generateHtml } = useMarkdown()
    const currentBannerNumber = ref(0)

    const currentBanner = computed((): INotification|null => {
      if (props.notifications.length) {
        return props.notifications[currentBannerNumber.value]
      }

      return null
    })

    const iconClass = computed(() => {
      if (!currentBanner.value) {
        return {}
      }

      const type = currentBanner.value.type
      const classResult: Record<string, boolean> = {
        'pbi-alert-circle': type === 2,
        'pbi-warning-triangle': type === 3,
        'pbi-info-circle': [0, 1].includes(type)
      }

      return classResult
    })

    const currentBannerTitle = computed((): string => {
      if (currentBanner.value) {
        return currentBanner.value.title
      }

      return ''
    })

    const currentBannerDescription = computed((): string => {
      if (currentBanner.value) {
        const value = generateHtml(currentBanner.value.content)
        const div = document.createElement('div')
        div.innerHTML = value
        return trimValue(div.innerText, 40)
      }

      return ''
    })

    const bannerClass = computed((): Record<string, unknown> => {
      const classes: Record<string, unknown> = {
        'notification-show-at-bottom': props.showAtBottom
      }
      classes[`notification-type-${currentBanner.value?.type}`] = true

      return classes
    })

    const nextBanner = (): void => {
      if (props.notifications.length) {
        if (currentBannerNumber.value + 1 >= props.notifications.length) {
          currentBannerNumber.value = 0
        } else {
          currentBannerNumber.value += 1
        }
      }
    }

    const previousBanner = (): void => {
      if (props.notifications.length) {
        if (currentBannerNumber.value - 1 < 0) {
          currentBannerNumber.value = props.notifications.length - 1
        } else {
          currentBannerNumber.value -= 1
        }
      }
    }

    const dismissBanners = (): void => {
      if (props.notifications.length && props.saveStateOnDismiss) {
        const maxBannerId = Math.max(...props.notifications.map((banner: INotification) => banner.id))
        store.dispatch(NotificationsActions.SetLastDismissedBannerId, maxBannerId)
      }
      dismissed.value = true
      emit('dismissed')
    }

    const openNotification = (): void => {
      emit('notification', currentBanner.value)
    }

    return {
      currentBanner,
      currentBannerNumber,
      currentBannerTitle,
      currentBannerDescription,
      bannerClass,
      nextBanner,
      previousBanner,
      dismissBanners,
      openNotification,
      t,
      dismissed,
      iconClass
    }
  }
})
</script>

<style scoped lang="scss">
.notifications-banner {
  color: white;
  transition: background-color linear 0.3s;
  border-top: 1px solid gainsboro;
  padding: 10px;
  width: 100%;
  z-index: 100;
  line-height: inherit;
  position: relative;
}

.notification-show-at-bottom {
  bottom: 0;
  position: absolute;
}

.notifications-banner-container {
  min-height: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.notifications-banner-content {
  padding: 0 14px;
}

.notifications-banner-action {
  cursor: pointer;
}

.notifications-banner-navigation {
  text-align: center;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding-left: 7px;
}

.notifications-banner-dismiss {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  font-size: 12x;
}

.notifications-banner-details {
  cursor: pointer;
  text-decoration: underline;
  padding-left: 5px;
}

.notifications-banner-container {
  padding-left: 0;
}

.notification-banner-dismiss-container {
  width: 40px;
}

.notifications-banner-title {
  font-size: 15px;
  font-weight: bold;
}

.notifications-banner-counter, .notifications-banner-next, .notifications-banner-prev {
  line-height: 8px;
  display: inline-block;
  vertical-align: middle;
}

.notifications-banner-next, .notifications-banner-prev {
  font-size: 12px;
  cursor: pointer;
}

.notifications-banner.notification-type-0, .notification-type-0.notification-color-preview {
  background-color: var(--success);
}

.notifications-banner.notification-type-1, .notification-type-1.notification-color-preview {
  background-color: #0072b8;
}

.notifications-banner.notification-type-2, .notification-type-2.notification-color-preview {
  background-color: var(--warning);
}

.notifications-banner.notification-type-3, .notification-type-3.notification-color-preview {
  background-color: var(--danger);
}
.notifications-spacer {
  width: 100px;
}
.notification-icon {
  display: flex;
  place-items: center;

  i {
    font-size: 25px;
  }
}

@media (max-width: 767.98px) {
  .notifications-spacer {
    width: 10px !important;
  }
  .notifications-banner-container {
    padding-right: 0 !important;
  }
}
</style>
