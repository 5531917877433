<template>
  <div />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { IMixpanelParams, trackMixpanelEvent } from '@/utils/mixpanel/setupMixpanel'
import MixpanelEvents from '@/utils/mixpanel/mixpanelEvents'

export default defineComponent({
  name: 'Auth',
  components: {
  },
  setup () {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onMounted(async (): Promise<void> => {
      const authenticated = store.getters.isLoggedIn
      if (authenticated) {
        const params: IMixpanelParams = {
          sso: true
        }
        trackMixpanelEvent(MixpanelEvents.LoggedIn, params)

        router.replace(decodeURIComponent(route.query.next?.toString() || '/tools'))
      } else {
        router.replace({ name: 'Login', query: route.query })
      }
    })

    return {
      t
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
