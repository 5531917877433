<template>
  <template v-if="external">
    <a
      class="nav-link"
      :href="to"
      target="_blank"
    >
      <slot />
    </a>
  </template>
  <template v-else>
    <router-link
      class="nav-link"
      :to="to"
    >
      <slot />
    </router-link>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'HeaderLink',
  props: {
    to: { type: String, required: true },
    external: { type: Boolean, required: false, default: false }
  },
  setup () {
    const { t } = useI18n()

    return {
      t
    }
  }
})
</script>

<style scoped lang="scss">
.nav-link {
  font-family: "PrecisionSans_W_Rg", "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  transition: padding-left 0.3s linear;
  transition: padding-right 0.3s linear;
  white-space: nowrap;
}

@media (max-width: 575.98) {
  .nav-link {
    padding: 16px !important;
  }
}

@media (min-width: 576px) {
  .nav-link {
    padding: 24px 4px !important;
  }
}

@media (min-width: 992px) {
  .nav-link {
    padding: 24px 16px !important;
  }
}
</style>
