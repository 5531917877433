<template>
  <header class="bg-brand-header fixed-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <nav class="navbar navbar-expand-sm navbar-light">
            <button
              ref="mainDropdownTogglerRef"
              class="navbar-toggler p-0"
              type="button"
              @click="toggleNavbarDropdown"
            >
              <span class="navbar-toggler-icon" />
            </button>
            <router-link
              class="navbar-brand"
              to="/"
            >
              <span class="app-name">{{ t('common.apex') }}</span>
            </router-link>
            <div
              v-if="isLoggedIn"
              ref="mainDropdownRef"
              class="collapse navbar-collapse"
              :class="{'show': showNavbarDropdown}"
            >
              <ul class="navbar-nav mr-auto">
                <li class="nav-item divider-vertical d-none d-lg-block" />
                <li class="nav-item">
                  <HeaderLink to="/tools">
                    {{ t('layout.header.link_tools') }}
                  </HeaderLink>
                </li>
                <li
                  v-if="assignmentsEnabled"
                  class="nav-item"
                >
                  <HeaderAssignments />
                </li>
                <li
                  v-if="showKnowledgeBaseLink"
                  class="nav-item"
                >
                  <HeaderLink
                    :to="kbLink"
                    :external="true"
                  >
                    {{ t('layout.header.link_knowledge_base') }}
                  </HeaderLink>
                </li>
                <li
                  v-if="showSelfServiceAnalytics"
                  class="nav-item"
                >
                  <HeaderLink
                    to="https://metabase.bi.pitneycloud.com"
                    :external="true"
                  >
                    {{ t('layout.header.link_analytics') }}
                  </HeaderLink>
                </li>
              </ul>
              <ul class="navbar-nav navbar-right">
                <li class="nav-item">
                  <HeaderNotifications
                    :keep-open-on-click="currentNotification !== null"
                    @notification="openNotificationModal"
                  />
                </li>
                <li
                  v-if="showAdministrationLink"
                  class="nav-item"
                >
                  <HeaderLink
                    to="/admin"
                    :legacy="false"
                  >
                    <i
                      class="pbi-icon-outline pbi-settings"
                      :title="t('layout.header.link_administration')"
                    />
                    <span class="header-alt-text">{{ t('layout.header.link_administration') }}</span>
                  </HeaderLink>
                </li>
                <li class="nav-item">
                  <HeaderUser ref="userDropdownRef" />
                </li>
              </ul>
            </div>
            <div
              v-else
              class="navbar-not-logged"
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <HeaderLink to="/login">
                    {{ t('layout.header.link_login') }}
                  </HeaderLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <NotificationBanner
    :notifications="banners"
    @notification="openNotificationModal"
    @dismissed="onNotificationBannerDismissed"
  />
  <NotificationModal
    :notification="currentNotification"
    @close="closeNotificationModal"
  />

  <!-- ui input mask visible on xs devices when navmenu dropdown is visible -->
  <div
    v-if="showNavbarDropdown"
    class="d-sm-none p-speeddial-mask p-speeddial-mask-visible"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import HeaderLink from '@/layout/components/HeaderLink.vue'
import HeaderUser from '@/layout/components/HeaderUser.vue'
import HeaderAssignments from '@/layout/components/HeaderAssignments.vue'
import HeaderNotifications from '@/layout/components/HeaderNotifications.vue'
import NotificationBanner from '@/components/notifications/NotificationBanner.vue'
import NotificationModal from '@/components/notifications/NotificationModal.vue'
import INotification from '@/interfaces/notification'
import { IMixpanelParams, trackMixpanelEvent } from '@/utils/mixpanel/setupMixpanel'
import MixpanelEvents from '@/utils/mixpanel/mixpanelEvents'
import useClickOutside from '@/utils/clickOutside'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'HeaderComponent',
  components: {
    HeaderLink, HeaderUser, HeaderAssignments, HeaderNotifications, NotificationBanner, NotificationModal
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const router = useRouter()
    let clearRouter: () => void

    // nav dropdown
    let clearClick: () => void
    const mainDropdownRef = ref()
    const mainDropdownTogglerRef = ref()
    const userDropdownRef = ref()

    const isLoggedIn = computed((): boolean => store.getters.isLoggedIn)

    const showKnowledgeBaseLink = computed((): boolean => {
      return isLoggedIn.value && store.state.session.currentUser.type.pid === 'domain' && kbLink.value.length > 0
    })
    const showSelfServiceAnalytics = computed((): boolean => {
      return isLoggedIn.value && store.state.session.featureToggles.selfServiceAnalytics
    })

    const assignmentsEnabled = computed((): boolean => {
      return store.state.session.featureToggles.assignments
    })

    const lastDismissedBannerId = computed((): number => store.state.notifications.lastDismissedBannerId)
    const banners = computed((): INotification[] => {
      const globalNotifications = store.state.notifications.notifications.filter((notification: INotification) => notification.banner && notification.id > lastDismissedBannerId.value && notification.active)
      const toolNotifications = store.state.notifications.toolNotifications.filter((notification: INotification) => notification.active)
      return [...globalNotifications, ...toolNotifications].sort((a, b) => a.id - b.id)
    })

    const hasAdminGroupsAccessRole = computed((): boolean => store.state.session.permissions.includes('ui.admin.groups.access'))
    const hasAdminUsersAccessRole = computed((): boolean => store.state.session.permissions.includes('ui.admin.users.access'))
    const hasAdminToolsAccessRole = computed((): boolean => store.state.session.permissions.includes('ui.admin.tools.access'))
    const hasAdminAssignmentsAccessRole = computed((): boolean => store.state.session.permissions.includes('ui.admin.assignments.access'))
    const hasAdminNotificationsAccessRole = computed((): boolean => store.state.session.permissions.includes('ui.admin.notifications.access'))
    const hasAdminTableauAccessRole = computed((): boolean => store.state.session.permissions.includes('ui.admin.tableau.access'))
    const hasAdminAmsAccessRole = computed((): boolean => store.state.session.permissions.includes('ui.admin.ams.access'))

    const kbLink = computed((): string => store.state.app.kbBaseUrl)

    const showAdministrationLink = computed((): boolean => {
      return hasAdminGroupsAccessRole.value ||
            hasAdminUsersAccessRole.value ||
            hasAdminToolsAccessRole.value ||
            hasAdminAssignmentsAccessRole.value ||
            hasAdminNotificationsAccessRole.value ||
            hasAdminTableauAccessRole.value ||
            hasAdminAmsAccessRole.value
    })

    const showNavbarDropdown = ref(false)
    const toggleNavbarDropdown = (): void => {
      showNavbarDropdown.value = !showNavbarDropdown.value
    }

    const currentNotification = ref<INotification|null>(null)

    const openNotificationModal = (notification: INotification): void => {
      currentNotification.value = notification
      const params: IMixpanelParams = {
        notificationId: notification.id,
        notificationTitle: notification.title
      }

      trackMixpanelEvent(MixpanelEvents.OpenedNotificationDetails, params)
    }

    const closeNotificationModal = (): void => {
      const params: IMixpanelParams = {
        notificationId: currentNotification.value?.id,
        notificationTitle: currentNotification.value?.title
      }

      trackMixpanelEvent(MixpanelEvents.ClosedNotificationDetails, params)
      currentNotification.value = null
    }

    const onNotificationBannerDismissed = () => {
      trackMixpanelEvent(MixpanelEvents.DismissedNotificationsBanner)
    }

    onMounted(() => {
      clearClick = useClickOutside([mainDropdownTogglerRef.value, mainDropdownRef.value, userDropdownRef.value], () => {
        if (showNavbarDropdown.value) {
          showNavbarDropdown.value = false
        }
      })

      clearRouter = router.beforeEach(() => {
        showNavbarDropdown.value = false
      })
    })

    onUnmounted((): void => {
      clearClick()
      clearRouter()
    })

    return {
      t,
      isLoggedIn,
      showNavbarDropdown,
      toggleNavbarDropdown,
      assignmentsEnabled,
      showKnowledgeBaseLink,
      showAdministrationLink,
      openNotificationModal,
      closeNotificationModal,
      currentNotification,
      banners,
      onNotificationBannerDismissed,
      showSelfServiceAnalytics,
      mainDropdownRef,
      mainDropdownTogglerRef,
      userDropdownRef,
      kbLink
    }
  }
})
</script>

<style scoped lang="scss">
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.navbar-brand {
  text-transform: uppercase;
}
.navbar-expand-sm {
  .navbar-nav {
    .nav-link {
      font-family: "PrecisionSans_W_Rg","Helvetica Neue",Arial,sans-serif;
      font-weight: normal;
    }
  }
}
.navbar-right {
  animation: none;
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .app-name {
    display: none;
  }
  nav {
    .navbar-brand {
      margin: 0 !important;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: white !important;
        white-space: nowrap;
        &:hover {
          color: white !important;
        }
        &:focus {
          color: white !important;
        }
        &:active {
          color: white !important;
        }
      }
      .show {
        >.nav-link {
          color: white !important;
          white-space: nowrap;
        }
      }
      .active {
        >.nav-link {
          color: white !important;
          white-space: nowrap;
        }
      }
      .nav-link.show {
        color: white !important;
        white-space: nowrap;
      }
      .nav-link.active {
        color: white !important;
        white-space: nowrap;
      }
    }
  }
  .bg-brand-header {
    background-size: 100% 70px;
    height: 70px;
    nav {
      background-size: 100% 70px;
    }
  }
  .navbar.navbar-expand-sm.navbar-light {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .nav-item.dropdown.show {
    .nav-link {
      background-color: rgba(0,0,0,0.21);
      border: 0;
    }
  }
}
@media (min-width: 576px) {
  .collapse.navbar-collapse.show {
    margin: 0;
  }
  .collapse.navbar-collapse {
    overflow-x: inherit !important;
    overflow-y: inherit !important;
    max-height: inherit !important;
  }
  .bg-brand-header {
    .d-lg-block {
      display: block !important;
    }
    .d-lg-none {
      display: none !important;
    }
    nav {
      .navbar-brand {
        margin-right: 1rem;
        transform: translateY(0);
      }
    }
  }
  header {
    .navbar-light {
      .navbar-brand {
        &::before {
          margin-right: 0.5rem !important;
          -ms-transform: translateY(0) scale(1.0) !important;
          transform: translateY(0) scale(1.0) !important;
        }
      }
    }
  }
  .navbar.navbar-expand-md.navbar-light {
    padding: 0 !important;
  }
  .header-alt-text {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: var(--text) !important;
        &:hover {
          color: var(--text) !important;
        }
        &:focus {
          color: var(--text) !important;
        }
      }
      .show {
        >.nav-link {
          color: var(--text) !important;
        }
      }
      .active {
        >.nav-link {
          color: var(--text) !important;
        }
      }
      .nav-link.show {
        color: var(--text) !important;
      }
      .nav-link.active {
        color: var(--text) !important;
      }
    }
  }
  .bg-brand-header {
    background-size: 100% 58px;
    height: 58px;
    nav {
      background-size: 100% 58px;
      padding-bottom: 0;
    }
  }
  .nav-item {
    &:hover {
      background-color: var(--light);
    }
  }
  .collapse.navbar-collapse.show {
    margin-top: 13px;
    border: 1px solid var(--light);
  }
  .header-alt-text {
    padding-left: 5px;
  }
}
.navbar-not-logged {
  width: 100%;
  .navbar-nav {
    align-items: flex-end;
    width: 100%;
    flex-direction: column;
  }
}

@media (max-width: 575.98px) {
  .p-navbar-dropdown-mask {
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
    z-index: 100;
  }
  .p-speeddial-mask.p-speeddial-mask-visible {
    pointer-events: auto;
  }
}

</style>
