export default {
  app_name: 'Access Managment System',
  unauthorized_msg: 'You don\'t have access to AMS',
  common: {
    app_schema: {
      standard: 'Standard',
      facility: 'Facility',
      nexship: 'NexShip'
    },
    user_type: {
      employee: 'Employee',
      temporary_worker: 'Temporary Worker'
    },
    identity_provider: {
      azure_ad: 'AzureAD',
      cognito: 'Cognito',
      octa: 'Octa'
    },
    select_palceholder: 'Select...',
    inactive_lbl: 'Inactive',
    ams_resource: {
      profile_reader: 'Profile Reader',
      profile_creator: 'Profile Creator',
      profile_admin: 'Profile Admin',
      self_editor: 'Self Editor',
      application_admin: 'Application Admin',
      application_reader: 'Application Reader',
      facility_read: 'Facility Read',
      facility_write: 'Facility Write'
    }
  },
  components: {
    add_edit_role_dlg: {
      add_header: 'Add Role',
      edit_header: 'Edit Role',
      name: 'Name',
      name_format_error: 'Only a-z A-z 0-9 - _ characters are allowed',
      display_name: 'Display Name',
      description: 'Description',
      is_active: 'Active',
      add_btn: 'Add Role',
      save_btn: 'Save Role'
    },
    add_edit_resource_dlg: {
      add_header: 'Add Resource',
      edit_header: 'Edit Resource',
      name: 'Name',
      name_format_error: 'Only a-z A-z 0-9 - _ characters are allowed',
      display_name: 'Display Name',
      is_active: 'Active',
      add_btn: 'Add Resource',
      save_btn: 'Save Resource'
    },
    add_edit_permission_dlg: {
      add_header: 'Add Permission',
      edit_header: 'Edit Permission',
      name: 'Name',
      name_format_error: 'Only a-z A-z 0-9 - _ characters are allowed',
      description: 'Description',
      add_btn: 'Add Permission',
      save_btn: 'Save Permission'
    },
    remove_application_dlg: {
      header: 'Are you sure?',
      msg: 'You are going to delete {application} application.',
      action_final: 'This action cannot be undone.',
      confirm_msg: 'To confirm, please type {deletethis} below.',
      delete_confirm_text_warning: 'Text must be equal to "delete this"',
      delete_application_btn: 'Delete Application'
    },
    remove_role_dlg: {
      header: 'Remove Role?',
      msg: 'Are you sure you want to remove {role} role?'
    },
    remove_resource_dlg: {
      header: 'Remove Resource?',
      msg: 'Are you sure you want to remove {resource} resource?'
    },
    remove_permission_dlg: {
      header: 'Remove Permission?',
      msg: 'Are you sure you want to remove {permission} permission?'
    },
    remove_profile_application_dlg: {
      header: 'Remove Application?',
      msg: 'Are you sure you want to remove {application} application form profile?'
    },
    remove_profile_dlg: {
      header: 'Are you sure?',
      msg: 'You are going to delete {profile} profile.',
      action_final: 'This action cannot be undone.',
      confirm_msg: 'To confirm, please type {deletethis} below.',
      delete_confirm_text_warning: 'Text must be equal to "delete this"',
      delete_profile_btn: 'Delete Profile'
    },
    profile_application_roles: {
      fasttrak_id_placeholder: 'FastTrak ID',
      loading: 'Loading roles...',
      saving: 'Saving roles...'
    }
  },
  views: {
    applications: {
      header: 'Applications',
      toolbar: {
        add_app_btn: 'Add Application',
        search_placeholder: 'Search Application'
      },
      table: {
        loading_apps: 'Loading...',
        no_apps_found: 'No Applications Found',
        columns: {
          name: 'Code',
          display_name: 'Name',
          description: 'Description',
          is_active: 'Active',
          schema: 'Schema',
          url: 'Url'
        }
      }
    },
    application_details: {
      header: 'Application',
      subheader: 'Add Application',
      back_to_list_btn: 'Back to Application List',
      general_tab: {
        tab_header: 'General',
        name: 'Name',
        name_format_error: 'Only a-z 0-9 - _ characters are allowed',
        display_name: 'Display Name',
        description: 'Description',
        description_placeholder: 'Enter Description...',
        is_active: 'Active',
        schema: 'Schema',
        url: 'Url',
        url_format_error: 'Please provide valid URL e.g. https://someapp.fdr.pitneycloud.com',
        delete_app_btn: 'Delete Application'
      },
      roles_tab: {
        tab_header: 'Roles',
        add_role_btn: 'Add Role',
        roles: 'Roles',
        select_role: 'Select Role',
        search_resources: 'Search Resources',
        search_roles: 'Search Roles',
        search_can_assign_roles: 'Search Can Assign Roles'
      },
      res_perm_tab: {
        tab_header: 'Resources / Permissions',
        add_resource_btn: 'Add Resource',
        resources: 'Resources',
        select_resource: 'Select Resource',
        no_resources: 'No Resources Available',
        add_permission_btn: 'Add Permission',
        permissions: 'Permissions',
        no_permissions: 'No Permissions Available',
        loading_permissions: 'Loading Permissions...'
      }
    },
    profiles: {
      header: 'Profiles',
      toolbar: {
        add_profile_btn: 'Add Profile',
        search_placeholder: 'Search...',
        app_filter: 'Application',
        facility_filter: 'Facility'
      },
      table: {
        loading: 'Loading...',
        no_profiles_found: 'No Profiles Found',
        columns: {
          name: 'Name',
          email: 'Email',
          type: 'Type',
          applications: 'Applications',
          facilities: 'Facilities'
        }
      }
    },
    profile_details: {
      add_header: 'Add Profile',
      subheader: 'Profile',
      back_to_list_btn: 'Back to Profile List',
      profile_tab: {
        tab_header: 'Profile',
        user_type: 'User Type',
        username: 'Username',
        username_placeholder: 'Search User...',
        username_not_found: 'User not found',
        username_not_unique: 'User already has a profile',
        email: 'Email',
        email_not_found: 'Employee not found',
        first_name: 'First Name',
        last_name: 'Last Name',
        pb_id: 'PB Id',
        locale: 'Locale',
        add_profile_btn: 'Add Profile',
        delete_profile_btn: 'Delete Profile',
        cancel_btn: 'Cancel'
      },
      profile_applications_tab: {
        tab_header: 'Applications',
        add_application_btn: 'Add Application',
        select_application: 'Select Application',
        assigned_applications: 'Assigned Applications',
        no_assigned_applications: 'No applications assigned',
        available_applications: 'Available Applications',
        no_available_applications: 'No applications available',
        search_applications: 'Search',
        search_roles: 'Search',
        application_roles: 'Application Roles',
        select_more_roles: 'Select at least one Role',
        select_more_roles_fasttrak: 'Select at least one Role and input FastTrak IDs',
        paau_used: 'PAAU Id can be set only for Profile Admins / Profile Creators',
        application_roles_edited: 'Application Roles edited',
        application_added: 'Application added to Profile',
        application_removed: 'Application removed form Profile'
      }
    },
    temp_workers: {
      header: 'Temporary Workers'
    }
  },
  api: {
    could_not_fetch_application: 'Could not fetch application | Could not fetch applications',
    could_not_fetch_profile: 'Could not fetch profile | Could not fetch profiles',
    could_not_fetch_profile_application: 'Could not fetch profile application | Could not fetch profile applications',
    could_not_fetch_role: 'Could not fetch role | Could not fetch roles',
    could_not_fetch_resource: 'Could not fetch resource | Could not fetch resources',
    could_not_fetch_permission: 'Could not fetch permission | Could not fetch permissions',
    could_not_fetch_facilities: 'Could not fetch facility | Could not fetch facilities',
    could_not_add_profile: 'Could not add profile | Could not add profiles',
    could_not_add_profile_application: 'Could not add profile application | Could not add profile applications',
    could_not_save_application: 'Could not save application | Could not save applications',
    could_not_save_profile_application: 'Could not save profile application | Could not save profile applications',
    could_not_save_role: 'Could not save role | Could not save roles',
    could_not_save_resource: 'Could not save resource | Could not save resources',
    could_not_save_permission: 'Could not save permission | Could not save permissions',
    could_not_remove_application: 'Could not remove application | Could not remove applications',
    could_not_remove_profile: 'Could not remove profile | Could not remove profiles',
    could_not_remove_profile_application: 'Could not remove profile application | Could not remove profile applications',
    could_not_remove_role: 'Could not remove role | Could not remove roles',
    could_not_remove_resource: 'Could not remove resource | Could not remove resources',
    could_not_remove_permission: 'Could not remove permission | Could not remove permissions',
    application_created: 'Application Created',
    application_edited: 'Application Edited',
    application_removed: 'Application Removed',
    role_created: 'Application Role Created',
    role_edited: 'Application Role Edited',
    role_removed: 'Application Role Removed',
    resource_created: 'Application Resource Created',
    resource_edited: 'Application Resource Edited',
    resource_removed: 'Application Resource Removed',
    permission_created: 'Application Permission Created',
    permission_edited: 'Application Permission Edited',
    permission_removed: 'Application Permission Removed',
    profile_added: 'Profile Added',
    profile_edited: 'Profile Edited',
    profile_removed: 'Profile Removed',
    profile_application_added: 'Profile Application Added',
    profile_application_edited: 'Profile Application Edited',
    profile_application_removed: 'Profile Application Removed'
  }
}
