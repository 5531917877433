import { ActionContext } from 'vuex'
import store, { IRootState } from '@/store/index'
import IAssignmentsTaskCount from '@/interfaces/assignmentsTaskCount'
import IBulkAssignParams from '@/interfaces/bulkAssignParams'

enum AssignmentsMutations {
  SetAvailableAssignments = 'SET_AVAILABLE_ASSIGNMENTS_MUTATION',
  RestoreState = 'RESTORE_ASSIGNMENTS_STATE_MUTATION',
  ClearState = 'CLEAR_ASSIGNMENTS_STATE_MUTATION',
  SetBulkAssignParams = 'SET_BULK_ASSIGN_PARAMS_MUTATION'
}

export enum AssignmentsActions {
  SetAvailableAssignments = 'SET_AVAILABLE_ASSIGNMENTS_ACTION',
  RestoreState = 'RESTORE_ASSIGNMENTS_STATE_ACTION',
  ClearState = 'CLEAR_ASSIGNMENTS_STATE_ACTION',
  SetBulkAssignParams = 'SET_BULK_ASSIGN_PARAMS_ACTION'
}

export interface IAssignmentsState {
  taskCount: IAssignmentsTaskCount;
  bulkAssignParams?: IBulkAssignParams;
}

const storeState = (state: IAssignmentsState): void => {
  localStorage.setItem('session.assignments.taskCount', JSON.stringify(state.taskCount))
}

const clearState = (): void => {
  localStorage.removeItem('session.assignments.taskCount')
}

const getDefaultState = (): IAssignmentsState => {
  return {
    taskCount: {
      due: 0,
      unassigned: 0,
      blocked: 0
    },
    bulkAssignParams: undefined
  }
}

export default {
  state: getDefaultState,
  mutations: {
    [AssignmentsMutations.SetAvailableAssignments] (state: IAssignmentsState, payload: IAssignmentsTaskCount): void {
      state.taskCount = payload
    },
    [AssignmentsMutations.RestoreState] (state: IAssignmentsState): void {
      const taskCountJson = localStorage.getItem('session.assignments.taskCount')

      if (taskCountJson) {
        try {
          state.taskCount = JSON.parse(taskCountJson)
        } catch {
          // ignore and do nothing
        }
      }
    },
    [AssignmentsMutations.ClearState] (state: IAssignmentsState): void {
      state.taskCount = getDefaultState().taskCount
    },
    [AssignmentsMutations.SetBulkAssignParams] (state: IAssignmentsState, payload: IBulkAssignParams|undefined): void {
      state.bulkAssignParams = payload
    }
  },
  actions: {
    [AssignmentsActions.SetAvailableAssignments] (context: ActionContext<IAssignmentsState, IRootState>, assignments: IAssignmentsTaskCount): void {
      context.commit(AssignmentsMutations.SetAvailableAssignments, assignments)
      storeState(context.state)
    },
    [AssignmentsActions.RestoreState] (context: ActionContext<IAssignmentsState, IRootState>): void {
      context.commit(AssignmentsMutations.RestoreState)
    },
    [AssignmentsActions.ClearState] (context: ActionContext<IAssignmentsState, IRootState>): void {
      context.commit(AssignmentsMutations.ClearState)
      clearState()
    },
    [AssignmentsActions.SetBulkAssignParams] (context: ActionContext<IAssignmentsState, IRootState>, params: IBulkAssignParams|undefined): void {
      context.commit(AssignmentsMutations.SetBulkAssignParams, params)
    }
  }
}

window.addEventListener('storage', function (event: StorageEvent): void {
  if (event.key && ['session.assignments.taskCount'].includes(event.key)) {
    store.commit(AssignmentsMutations.RestoreState)
  }
})
