import Service from './service'
import authService from './authService'

// TODO make suitable for other schemas
export interface IAmsAsMyResource {
  name: string;
}

export interface IAmsAsMyRole {
  name: string;
  appName?: string;
  resourceList: IAmsAsMyResource[];
}

export interface IAmsAsGetMyPermissionsResponse {
  id: string;
  firstName: string | null;
  lastName: string | null;
  ngsFacilityNumber?: string | null;
  permissions: IAmsAsMyRole[]; // for access_management app that we use, the permmissions property holds actual roles list
}

class AmsAsService extends Service {
  // helper method
  async getMyAmsPermissions (): Promise<IAmsAsGetMyPermissionsResponse> {
    return this.get('/v1/permissions/me/access_management')
  }

  async getMyAppPermissions (appName: string): Promise<IAmsAsGetMyPermissionsResponse> {
    return this.get(`/v1/permissions/me/${appName}`)
  }
}

export default new AmsAsService(authService.getAmsAsUrl(), false)
