import Cookies from 'js-cookie'

export default function useInterval (callback: () => void, frequency: number, instantCall = true, sessionKey = ''): () => void {
  let handle = 0
  const cookieName = `apex-interval-${sessionKey}`

  const init = () => {
    if (handle) {
      return
    }
    handle = window.setInterval(call, frequency)
    if (instantCall) {
      callback()
      if (sessionKey) {
        setNextIntervalCookie()
      }
    }
  }

  const clear = () => {
    if (handle) {
      clearInterval(handle)
      if (Cookies.get(cookieName)) {
        Cookies.remove(cookieName)
      }
    }
  }

  const call = () => {
    if (!sessionKey) {
      callback()
      return
    }

    // if there is a session key present then check the cookie to make sure callback is only called once each 'frequency' seconds
    const cookie = Cookies.get(cookieName)
    if (cookie) {
      const currentTime = Date.now()
      const ticketTimeout = parseInt(cookie, 10)
      if (currentTime >= ticketTimeout) {
        callback()
        setNextIntervalCookie()
      }
    } else {
      callback()
      setNextIntervalCookie()
    }
  }

  const setNextIntervalCookie = (): void => {
    const next = new Date()
    next.setTime(next.getTime() + frequency)
    Cookies.set(cookieName, next.valueOf().toString())
  }

  init()

  return clear
}
