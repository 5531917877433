export const AMS_ACCESS_MANAGMENT_APP_NAME = 'access_management'
export const AMS_ACCESS_MANAGMENT_ROLE_SUFFIXES = [
  '_profile_reader', '_profile_creator', '_profile_admin', '_application_admin'
]
export const AMS_FASTTRAKID_PAAU = 'paau'

export interface IAmsPageableResponse<T> {
  items: T;
  totalCount: number;
}

export interface IAmsApiResponseError {
  statusCode: number;
  message: string;
}

export interface IAmsPageableRequest {
  pageSize: number;
  pageNumber: number;
}

export enum AmsSortOrder {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export enum AmsUserType {
  Employee = 'Employee',
  TemporaryWorker = 'TemporaryWorker'
}

export enum AmsApplicationSchema {
  Standard = 'Standard',
  Facility = 'Facility',
  NexShip = 'NexShip'
}

export enum AmsIdentityProvider {
  AzureAD = 'AzureAD',
  Cognito = 'Cognito',
  Octa = 'Octa'
}

export enum AmsAccessManagementResource {
  ProfileReader = 'profile_reader',
  ProfileCreator = 'profile_creator',
  ProfileAdmin = 'profile_admin',
  SelfEditor = 'self_editor',
  ApplicationAdmin = 'application_admin',
  ApplicationReader = 'application_reader',
  FacilityRead = 'facility_read',
  FacilityWrite = 'facility_write'
}

export interface IAmsApplication {
  id: string;
  name: string;
  displayName: string | null;
  description: string | null;
  isActive: boolean;
  schema: AmsApplicationSchema;
  url: string | null;
  createdBy: string | null;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
}

export interface IAmsApplicationRole {
  id: string;
  name: string;
  displayName: string;
  description: string;
  isActive: boolean;
  resourceIdList: string[];
  canAssignRoleIdList: string[];
  createdBy: string | null;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
}

export interface IAmsApplicationResource {
  id: string;
  name: string;
  displayName: string;
  isActive: boolean;
  createdBy: string | null;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
}

export interface IAmsApplicationPermission {
  id: string;
  name: string;
  description: string;
  createdBy: string | null;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
}

export interface IGetMyUserProfileQueryResponse {
  id: string;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  pbId: string | null;
  locale: string | null;
  type: AmsUserType;
  // TODO app schemas
  applicationList: IAmsApplication[];
}

export interface IAmsUserProfile {
  id: string;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  pbId: string | null;
  locale: string | null;
  type: AmsUserType;
  identityProvider: AmsIdentityProvider;
  applicationList: IAmsApplication[];
  createdBy: string | null;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
}

export interface IAmsUserProfileApplication {
  id: string;
  name: string;
  displayName: string;
  schema: AmsApplicationSchema;
  roleIdList?: string[];
  createdBy: string | null;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
}

export interface IAmsUserProfileApplicationFacility {
  fastTrakUserId?: string;
  facilityName: string;
  ngsFacilityNumber: string;
  roleIdList?: string[];
  isActive: boolean;
  createdBy: string | null;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
}

export interface IAmsNgsFacility {
  id: string;
  facilityId: number;
  facilityName: string;
  ngsFacilityNum: string;
  cityName: string;
}
