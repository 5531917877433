import IGroup from '@/interfaces/group'
import { IUser } from '@/interfaces/user'
import mixpanel, { Callback } from 'mixpanel-browser'
import store from '@/store'

let isMixpanelInitialized = false

export interface IMixpanelParams {
  [key: string]: unknown;
}

export const setupMixpanel = (token: string|null): void => {
  if (token) {
    mixpanel.init(token, {
      secure_cookie: true,
      debug: false
    })

    isMixpanelInitialized = true
  }
}

export const trackMixpanelEvent = (eventName: string, params: IMixpanelParams = {}, options = {}, callback: Callback|undefined = undefined): void => {
  if (isMixpanelInitialized) {
    params.userGroups = []
    if (store.state.session?.groups) {
      params.userGroups = store.state.session.groups.map((group: IGroup) => group.name)
    }
    mixpanel.track(eventName, params, options, callback)
  }
}

export const setMixpanelUser = (user: IUser): void => {
  if (isMixpanelInitialized) {
    mixpanel.identify(user.id.toString())
    mixpanel.people.set({
      $name: `${user.firstName} ${user.lastName}`,
      $email: user.email,
      type: user.type.name
    })
  }
}

export const unsetMixpanelUser = (): void => {
  if (isMixpanelInitialized) {
    mixpanel.reset()
  }
}
