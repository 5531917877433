export default {
  groups_link: 'Groups',
  users_link: 'Users',
  api_keys_link: 'API Keys',
  tools_link: 'Tools',
  assignments_link: 'Assignments',
  notifications_link: 'Notifications',
  tableau_status_link: 'Tableau Status',
  ams_link: 'AMS',
  components: {
    add_edit_api_key: {
      header_add: 'Add API Key',
      name_label: 'Name',
      name_placeholder: 'Name...',
      name_required: 'Name is required.',
      cancel: 'Cancel',
      save_api_key: 'Save',
      email_label: 'Email',
      email_placeholder: 'Email...',
      email_required: 'Email is required',
      roles_label: 'Roles',
      roles_placeholder: 'Roles...',
      roles_required: 'At least one role is required.',
      could_not_add_api_user: 'Could not add a new API key. Please try again later.',
      active_label: 'Active',
      key_edited: 'API Key edited.',
      key_edited_details: 'API Key {name} successfully edited.'
    },
    show_api_key_modal: {
      header: 'API Token for {name}',
      cancel: 'Close',
      key_information: 'Below is your autogenerated token for {name}. This token can be only viewed once. Please copy it before closing this window.',
      click_to_copy: 'Click here to copy the token',
      copied_token: 'Copied token to clipboard'
    },
    delete_group_modal: {
      header: 'Are you sure?',
      group_single_template_assigned: 'This Group is assigned to 1 Assignment Template.',
      single_assignment_template_removal: 'Once deleted, the Group will be removed from the Assignment Template.',
      group_multiple_templates_assigned_1: 'This Group is assigned to ',
      group_multiple_templates_assigned_2: 'Assignment Templates.',
      group_mutiple_templates_delete_final: 'Once deleted, the Group will be removed from the Assignment Templates',
      group_ongoing_assignment_deprecation: '1 ongoing Assignment that has been assigned to this group will be automatically deprecated.',
      group_multiple_ongoing_assignments_deprecation: 'ongoing Assignments that have been assigned to this group will be automatically deprecated.',
      confirm_1: 'To confirm, please type',
      confirm_2: 'below.',
      cancel: 'Cancel',
      delete_group: 'Delete Group',
      could_not_fetch_assignments: 'Could not fetch associated assignments',
      delete_confirm_text_warning: 'Text must be equal to "delete this"',
      group_deleted: 'Group deleted',
      group_deleted_detail: 'Deleted',
      deleting_group_prompt: 'You are going to delete',
      action_final: 'This action cannot be undone.',
      delete_children: 'Deleting this group will also delete its children:',
      delete_groups_with_child_groups: 'Do you really want to delete this group and all its subgroups?',
      confirm_deletion: 'Deleting group {name}'
    },
    delete_tool_modal: {
      header: 'Are you sure?',
      delete_tool_prompt: 'You are going to delete',
      action_final: 'This action cannot be undone.',
      delete_tool_btn: 'Delete Tool',
      tool_single_template_assigned: 'This Tool is assigned to 1 Assignment Template.',
      single_assignment_template_removal: 'Once deleted, the Tool will be removed from the Assignment Template.',
      tool_multiple_templates_assigned: 'This Tool is assigned to {count} Assignment Templates.',
      mutiple_assignment_templates_removal: 'Once deleted, the Tool will be removed from the Assignment Templates',
      confirm_1: 'To confirm, please type',
      confirm_2: 'below.',
      confirm_text: 'delete this',
      confirm_text_warning: 'Text must be equal to "delete this"',
      tool_deleted: 'Tool deleted',
      tool_deleted_detail: 'Deleted'
    },
    add_edit_group_modal: {
      header_add: 'Add group',
      header_duplicate: 'Duplicate group',
      save_group: 'Save',
      cancel: 'Cancel',
      name_label: 'Name',
      name_placeholder: 'Name...',
      description_label: 'Description',
      description_placeholder: 'Description...',
      department_label: 'Department',
      department_placeholder: 'Department...',
      users_label: 'Users',
      users_placeholder: 'Users...',
      governors_label: 'Governors',
      governors_placeholder: 'Governors...',
      tools_label: 'Tools',
      tools_placeholder: 'Tools...',
      name_required: 'Name is required',
      governors_subtitle: 'External users cannot become governors and are not selectable',
      group_added: 'Group added',
      group_edited: 'Group edited',
      group_added_detail: 'Added group:',
      group_edited_detail: 'Edited group:',
      could_not_fetch_departments: 'Could not fetch departments',
      could_not_fetch_users: 'Could not fetch users',
      could_not_fetch_tools: 'Could not fetch tools',
      could_not_fetch_types: 'Could not fetch types',
      edit_name: 'Edit name',
      apex_app_scope_collections_placeholder: 'Select APEX App scope collections...',
      could_not_fetch_apex_scope_collections: 'Could not fetch APEX App scope collections',
      could_not_fetch_apex_scope_collections_details: 'Could not fetch APEX App scope collections. Please try again later.',
      type_label: 'Type',
      type_placeholder: 'Type...',
      type_required: 'Group type is required',
      parent_label: 'Parent group',
      parent_placeholder: 'Select parent group...',
      scopes_required: 'APEX App scope collections are required...'
    },
    add_edit_user_modal: {
      // headers
      header_domain_user: 'Add Domain User',
      header_external_user: 'Add External User',
      // labels
      login_label: 'Login',
      email_label: 'Email',
      first_name_label: 'First Name',
      last_name_label: 'Last Name',
      passwd_label: 'Password',
      confirm_passwd_label: 'Confirm Password',
      groups_label: 'Groups',
      send_welcome_email_label: 'Send Welcome Email',
      welcome_email_label: 'Welcome Email',
      active_label: 'Active',
      resend_email_btn: 'Resend',
      // placeholders
      login_placeholder: 'Login...',
      email_placeholder: 'Email...',
      passwd_placeholder: 'Password...',
      confirm_passwd_placeholder: 'Confirm Password...',
      first_name_placeholder: 'First Name...',
      last_name_placeholder: 'Last Name...',
      groups_placeholder: 'Select Groups...',
      // constraints
      login_required: 'Login is required',
      email_required: 'Email is required',
      group_required: 'External user must be assigned to a group',
      passwd_not_equal: 'Passwords are not equal',
      passwd_required: 'Field required',
      passwd_complexity: 'Password doesn\'t meet requirements. It must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character and must be at least 8 characters long.',
      passwd_constraints_info: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character and must be at least 8 characters long.',
      login_not_found: 'Login not found on PB domain',
      email_not_found: 'Email not found on PB domain',
      title_label: 'Job title',
      location_label: 'Location',
      country_label: 'Country',
      job_family_group_label: 'Job Family Group',
      job_family_label: 'Job Family',
      company_org_label: 'Organization',
      // events
      user_added: 'User added',
      user_added_detail: 'Added user: {user}',
      user_edited: 'User edited',
      user_edited_detail: 'Edited user: {user}',
      // tooltips
      enter_edit_login_email: 'Edit login/email',
      exit_edit_login_email: 'Discard changes',
      // resend email modal
      resend_email_modal: {
        header: 'Are you sure?',
        contents: 'You are going to resend Welcome Email to: {email}',
        send_btn: 'Send',
        email_sent: 'Email has been sent'
      }
    },
    add_edit_tool: {
      add_tool: 'Add Tool',
      edit_tool: 'Edit Tool',
      tool_details: 'Tool Details',
      tool_source: 'Tool Source',
      // headers
      header_domain_user: 'Add Domain User',
      header_external_user: 'Add External User',
      // labels
      external_url_label: 'Tool Url',
      appsmith_app_label: 'Application Url',
      basic_embed_app_label: 'Embed Url',
      metabase_dashboard_label: 'Dashboard Id',
      apex_apps_label: 'APEX App Url',
      tableau_dashboard_label: 'Dashboard',
      name_label: 'Tool Name in APEX',
      description_label: 'Tool Description in APEX',
      groups_label: 'Groups',
      articles_label: 'Knowledge Base Articles',
      departments_label: 'Department',
      tool_owner_label: 'Tool Owner',
      tool_views_label: 'Tool Views',
      default_view_label: 'Default',
      apex_app_label: 'APEX App',
      apex_app_placeholder: 'Select an APEX App...',
      apex_app_scope_collections_label: 'APEX App Default Scope Collections',
      apex_app_scope_collections_placeholder: 'Select default scope collections...',
      apex_app_required: 'APEX App is required',
      // placeholders
      external_url_placeholder: 'Url...',
      appsmith_app_placeholder: 'Url...',
      basic_embed_app_placeholder: 'Url...',
      metabase_dashboard_placeholder: 'Id...',
      apex_apps_placeholder: 'Url...',
      tableau_dashboard_placeholder: 'Select Workbook...',
      name_placeholder: 'Name...',
      description_placeholder: 'Description...',
      groups_placeholder: 'Select Groups...',
      articles_placeholder: 'Select Articles...',
      departments_placeholder: 'Select Department...',
      tool_owner_placeholder: 'Login or email...',
      // hints
      tool_owner_hint: 'Only domain users can be assigned as tool owners',
      // constraints
      tool_url_required: 'Tool Url required',
      app_url_required: 'Application Url required',
      invalid_url: 'Please enter valid Url',
      dashboard_id_required: 'Dashboard Id required',
      dashboard_not_found: 'Dashboard not found',
      name_required: 'Tool name required',
      login_email_not_found: 'Login or Email not found on PB domain',
      workbook_required: 'Workbook required',
      // events
      tool_added: 'Tool added',
      tool_added_detail: 'Added tool: {tool_name}',
      tool_edited: 'Tool edited',
      tool_edited_detail: 'Edited tool: {tool_name}',
      // tooltips
      default_view_tooltip: 'Default View',
      set_default_view_tooltip: 'Set as Default View',
      hours: 'no hours | {n} hour | {n} hours',
      sla_label: 'Tool SLA',
      sla_placeholder: 'Select SLA...',
      jira_project_key_label: 'JIRA Support Project',
      jira_project_key_placeholder: 'Select Jira support project...',
      jira_project_key: '{name} ({key})',
      tool_support_label: 'Tool Support',
      tool_support_placeholder: 'Select tool support...',
      skip_uat: 'Skip UAT',
      skip_uat_label: 'Skip UAT',
      suggested_audience: 'Suggested Audience',
      uat: 'UAT',
      uat_date_label: 'UAT Due date',
      uat_date_placeholder: 'UAT deadline...',
      uat_users_label: 'UAT Users',
      uat_users_placeholder: 'UAT Users',
      audience: 'Audience',
      approved: 'Approved',
      feedback: 'Feedback',
      pending: 'Pending',
      upload_thumbnail: 'Upload thumbnail:',
      uat_date_required: 'UAT due date is required',
      uat_date_range: 'UAT due date must be greater than current date',
      apex_app_thumbnail_required: 'Thumbnail is required',
      uat_users_required: 'At least one UAT user is required',
      description_remaining_length: 'characters remaining',
      uat_suggested_groups_required: 'At least one group is required',
      redo_review: 'Reset this review and ask the user to go through the UAT flow again',
      jasper_report_label: 'Jasper report',
      jasper_report_placeholder: 'Jasper report...',
      jasper_report_required: 'Jasper report is required',
      copy_tableau_link: 'Copy link to this workbook in Tableau'
    },
    uat_tool_group: {
      reject: 'Reject',
      add_to_group: 'Add tool to group',
      add_tool_to_group: 'Do you want to add tool {toolName} to group {groupName}?',
      header: 'Add UAT tool to group',
      loading: 'Loading...',
      no_rights: 'You do not have the required role to access this resource.',
      not_published: 'Tool still in UAT',
      not_published_details: 'Tool {toolName} is still in UAT and cannot be assigned groups.',
      already_added: 'Group already assigned',
      already_added_details: 'Tool {toolName} already has group {groupName} assigned.',
      not_suggested: 'Cannot add group to tool',
      not_suggested_details: 'This group can only be added through the add/edit group modal.',
      could_not_fetch_data: 'Could not fetch required data',
      could_not_fetch_data_dertails: 'Could not fetch required data. Please try again later.',
      tool_added: 'Tool assigned to group',
      tool_added_details: 'Tool {toolName} has been added to {groupName} group and can now be accessed by users.',
      could_not_add_tool: 'Could not assign tool to group',
      could_not_add_tool_details: 'Could not assign tool to group. Please try again later.'

    },
    redo_uat_review_modal: {
      header: 'Request Additional Review',
      redo_success: 'Successfully requested additional review',
      redo_success_details: 'Successfully requested additional review for current tool',
      redo_review: 'Request Review',
      comment_label: 'Comment',
      comment_placeholder: 'Comment...',
      could_not_request_review: 'Could not request an additional review. Please try again later.'
    },
    delete_notification_modal: {
      header: 'Are you sure?',
      confirm_1: 'To confirm, please type',
      confirm_2: 'below.',
      cancel: 'Cancel',
      delete_notification: 'Delete notification',
      delete_confirm_text_warning: 'Text must be equal to "delete this"',
      notification_deleted: 'Group deleted',
      notification_deleted_detail: 'Deleted',
      deleting_notification_prompt: 'You are going to delete',
      action_final: 'This action cannot be undone.'
    },
    notifications_table: {
      header: 'Notifications',
      no_notifications_found: 'No notifications found.',
      loading_notifications: 'Loading notifications. Please wait.',
      notification_search: 'Search...',
      add_notification: 'Add notification',
      edit_notification: 'Edit notification',
      duplicate_notification: 'Duplicate notification',
      delete_notification: 'Delete notification',
      could_not_fetch_notifications: 'Could not fetch notifications',
      actions: 'Actions',
      everyone: 'Everyone',
      regular: 'Regular',
      system: 'System',
      type_filter: 'Type'
    },
    add_edit_notification: {
      add_header: 'Add notification',
      edit_header: 'Edit notification',
      notification_content: 'Notification Content',
      notification_audience: 'Notification Audience',
      notification_date_range: 'Notification Date Range',
      local_timezone: 'All times are in the local timezone',
      date_edit: 'Start date is final and cannot be edited once a notification is created',
      title_label: 'Title',
      title_placeholder: 'Title...',
      content_label: 'Content',
      content_placeholder: 'Content...',
      start_date_label: 'Start date',
      start_date_placeholder: 'Start date...',
      end_date_label: 'End date',
      end_date_placeholder: 'End date...',
      groups_label: 'Groups',
      groups_placeholder: 'Groups...',
      title_required: 'Notification title is required.',
      content_required: 'Notification content is required.',
      start_date_required: 'Notification start date is required.',
      end_date_required: 'Notification end date is required.',
      groups_required: 'At least one group is required',
      tools_required: 'At least one tool is required',
      save: 'Save',
      cancel: 'Cancel',
      everyone: 'Everyone',
      selected_groups: 'Selected groups',
      selected_tools: 'Selected tools',
      audience_label: 'Target audience',
      banner_label: 'Banner',
      type_label: 'Type',
      type_green: 'Green',
      type_blue: 'Blue',
      type_orange: 'Orange',
      type_red: 'Red',
      end_date_invalid: 'End date must be greater than start date.',
      could_not_fetch_groups: 'Could not fetch groups',
      notification_created: 'Notification created',
      notification_created_detail: 'Notification created:',
      notification_edited: 'Notification edited',
      notification_edited_detail: 'Notification edited:',
      could_not_fetch_notification: 'Could not fetch notification',
      tools_label: 'Tools',
      tools_placeholder: 'Tools...',
      email_notification: 'Email notification',
      email_notification_label: 'Schedule email notification',
      email_title_label: 'Email subject line',
      email_title_placeholder: 'Email subject line...',
      email_scheduled_date_label: 'Scheduled date',
      email_scheduled_date_placeholder: 'Scheduled date...',
      email_scheduled_date_required: 'Valid schedule date must be provided',
      email_title_required: 'Valid email subject must be provided',
      cannot_edit_email_date: 'It is no longer possible to modify the scheduled email notification',
      email_date_required: 'Scheduled email date must be between the start and end dates',
      send_email: {
        header: 'Do you really want to send an email notification?',
        contents: 'Please verify that the correct audience has been selected ({targetAudience}). Email notifications can be cancelled up to 20 minutes before the selected date.',
        send_btn: 'Confirm',
        everyone: 'Everyone'
      },
      end_date_email_invalid: 'End date must be later than the notification scheduled date'
    },
    tools_table: {
      active_tools: 'Active Tools',
      archived_tools: 'Archived Tools'
    }
  },
  admin_groups: {
    header: 'Groups',
    no_groups_found: 'No groups found.',
    loading_groups: 'Loading groups. Please wait...',
    group_search: 'Search...',
    add_group: 'Add group',
    edit_group: 'Edit group',
    duplicate_group: 'Duplicate group',
    delete_group: 'Delete group',
    could_not_fetch_groups: 'Could not fetch groups',
    could_not_fetch_groups_details: 'Could not fetch groups. Please try again later.',
    actions: 'Actions',
    access_requests_search: 'Search...',
    access_requests_tab: 'Access Requests',
    no_access_requests_found: 'No access requests found',
    groups_tab: 'Groups',
    loading_access_requests: 'Loading access requests...',
    accept_access_request: 'Accept Access Request',
    reject_access_request: 'Reject Access Request',
    processed_access_request: 'Access Request processed',
    processed_access_request_details: 'Access Request processed successfully.',
    could_not_submit_access_request: 'Could not process access request',
    could_not_submit_access_request_details: 'Could not process access request. Please try again later.',
    access_request_header: 'Process Access Request',
    accept_request_confirmation: 'Do you want to approve this access request?',
    reject_request_confirmation: 'Do you want to reject this access request?',
    type_filter: 'Type',
    department_filter: 'Department',
    status_filter: 'Status',
    archived_groups: 'Archived Groups',
    active_groups: 'Active Groups',
    group_filter: 'Groups',
    group_access: 'Group Access',
    tool_access: 'Tool Access',
    governors: 'Governors',
    reason: 'Reason'
  },
  admin_users: {
    header: 'Users',
    no_users_found: 'No users found.',
    loading_users: 'Loading users. Please wait...',
    could_not_fetch_users: 'Could not fetch users',
    user_search: 'Search...',
    add_user: 'Add User',
    add_user_domain_option: 'PB Employee',
    add_user_external_option: 'External (Client/Vendor)',
    actions: 'Actions',
    edit_user_tooltip: 'Edit User',
    passwd_reset_tooltip: 'Reset password',
    pb_employee_tooltip: 'PB Employee',
    active_users: 'Active Users',
    activity_filter: 'Status',
    type_filter: 'Type',
    group_filter: 'Group',
    inactive_users: 'Inactive Users',
    activity_never: 'never'
  },
  admin_api_keys: {
    header: 'API Keys',
    no_users_found: 'No keys found.',
    loading_users: 'Loading keys. Please wait...',
    could_not_fetch_users: 'Could not fetch keys',
    user_search: 'Search...',
    active_users: 'Active API Keys',
    inactive_users: 'Inactive API Keys',
    activity_filter: 'Status',
    actions: 'Actions',
    add_api_user: 'Add API Key',
    key_reset_tooltip: 'Reset token',
    reset_token: 'Reset token',
    reset_token_confirmation: 'Do you really want to reset the token for {name}?',
    could_not_reset_token: 'Could not reset token.',
    could_not_reset_token_details: 'Could not reset token. Please try again later.',
    edit_api_user: 'Edit',
    roles_filter: 'Roles'
  },
  admin_tools: {
    header: 'Tools',
    published_tab: 'Published Tools',
    uat_tab: 'Tools in UAT',
    approved_tab: 'UAT Approved Tools',
    loading_tools: 'Loading tools. Please wait...',
    no_tools_found: 'No tools found.',
    tool_search: 'Search...',
    add_tool: 'Add Tool',
    col_header_type: 'Type',
    col_header_name: 'Name',
    col_header_description: 'Description',
    col_header_department: 'Department',
    col_header_groups: 'Groups',
    col_header_actions: 'Actions',
    col_header_approved: 'Approved',
    col_header_feedback: 'Feedback',
    col_header_pending: 'Pending',
    col_header_sla: 'SLA',
    col_header_sops: 'SOPs',
    col_header_supporters: 'Supporters',
    col_header_owner: 'Owner',
    edit_tool_tooltip: 'Edit {toolName}',
    delete_tool_tooltip: 'Delete {toolName}',
    reset_uat_tool_tooltip: 'Reset all UAT reviews and request all UAT users to perform reviews again.',
    publish_uat_tool_tooltip: 'Publish UAT tool',
    manage_uat_groups_assignment: 'Manage',
    group_filter: 'Group',
    department_filter: 'Department',
    type_filter: 'Type',
    archived_filter: 'Status',
    sla: '{n} hour|{n} hours',
    col_header_group_assignment: 'Group Assignment',
    select_suggested_group: 'Select a group...',
    delete_suggested_group: 'Delete this group suggestion',
    assign_suggested_group: 'Assign tool to this suggested group',
    deleted_suggested_group: 'Deleted group suggestion.',
    deleted_suggested_group_details: 'Successfully deleted group suggestion.',
    delete_suggested_group_failed: 'Could not remove the group suggestion.',
    delete_suggested_group_failed_details: 'Could not remove the group suggestion. Please try again later.',
    assigned_suggested_group: 'Assigned tool to selected group.',
    assigned_suggested_group_details: 'Successfully assigned tool to selected group.',
    assign_suggested_group_failed: 'Could not assign tool to group.',
    assign_suggested_group_failed_details: 'Could not assign tool to group. Please try again later.',
    publish_tool_message: 'Do you want to publish tool {toolName}? This operation cannot be reverted.',
    could_not_publish_tool: 'Could not publish the selected tool.',
    could_not_publish_tool_details: 'Could not publish the selected tool. Please try again later.',
    published_uat_tool: 'Published UAT tool',
    published_uat_tool_details: 'Published tool {toolName}.',
    open_tool: 'Open in a new tab'
  },
  assignments: {
    common: {
      day: 'day | {n} days',
      week: 'week | weeks',
      month: 'month | months',
      importance_low: 'Low',
      importance_medium: 'Medium',
      importance_high: 'High'
    },
    table: {
      header: 'Assignment Templates',
      loading: 'Loading assignment templates. Please wait...',
      no_templates_found: 'No assignment templates found.',
      search: 'Search...',
      add_template_btn: 'Add Template',
      col_header_name: 'Name',
      col_header_importance: 'Importance',
      col_header_groups: 'Groups',
      col_header_frequency: 'Frequency',
      col_header_active: 'Active',
      col_header_actions: 'Actions',
      edit_template_tooltip: 'Edit {templateName}',
      freq_descr_daily: '[undefined] | Every day | Every {n} days',
      freq_descr_weekly: '[undefined] | Every week on {days} | Every {n} weeks on {days}',
      freq_descr_monthly_each: '[undefined] | Every month each {dayNumbers} day | Every {n} months each {dayNumbers} day',
      freq_descr_monthly_on: '[undefined] | Every month on the {numerator} {day} | Every {n} months on the {numerator} {day}',
      freq_descr_yearly: 'In {months} on the {numerator} {day}',
      freq_descr_date: 'On the {date}',
      freq_descr_per_day: '[undefined] |, 1 time per day |, {n} times per day',
      item_detail: {
        description: 'Description',
        sops: 'Standard Operating Procedure(SOP)',
        tools: 'Tools',
        created: 'Created',
        last_modified: 'Last Modified',
        no_documents_supported: 'No documents supported',
        no_tools_used: 'No tools used',
        could_not_fetch: 'Could not fetch template details'
      },
      group_filter: 'Group',
      active_filter: 'Status',
      active: 'Active',
      inactive: 'Inactive'
    },
    add_edit: {
      add_header: 'Add Assignment Template',
      edit_header: 'Edit Assignment Template',
      description_section: 'Description',
      scheduling_section: 'Scheduling',
      details_section: 'Details',
      name_label: 'Template Name:',
      description_label: 'Template Description:',
      sops_label: 'Standard Operating Procedure (SOP):',
      tools_label: 'Tools Needed:',
      groups_label: 'Groups Assigned To:',
      importance_label: 'Importance:',
      active_label: 'Active:',
      name_placeholder: 'Name...',
      description_placeholder: 'Description...',
      sops_placeholder: 'Select SOPs...',
      tools_placeholder: 'Select Tools...',
      groups_placeholder: 'Select Groups...',
      name_required: 'Template name required',
      group_tool_warning: 'The selected group(s) does not have access to some of the tool(s)',
      schedule: {
        frequency: 'Frequency:',
        instance_count: 'Times per day:',
        weekly_on: 'On:',
        monthly_each: 'Each:',
        monthly_on: 'On the:',
        yearly_in: 'In:',
        yearly_on: 'On the:',
        date: 'Date:',
        date_placeholder: 'Select date...',
        toggle: {
          label: 'Schedule editing',
          on_option: 'On',
          off_option: 'Off'
        },
        interval: {
          every: 'Every:',
          daily_suffix: 'Day(s)',
          weekly_suffix: 'Weeks(s)',
          monthly_suffix: 'Month(s)'
        },
        freq_options: {
          daily: 'Daily',
          weekly: 'Weekly',
          monthly: 'Monthly',
          yearly: 'Yearly',
          date: 'Specific Date'
        },
        numerator: {
          first: 'First',
          last: 'Last'
        },
        days: {
          sunday: 'Sunday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday'
        },
        days_abbrev: {
          sunday: 'Sun',
          monday: 'Mon',
          tuesday: 'Tue',
          wednesday: 'Wed',
          thursday: 'Thu',
          friday: 'Fri',
          saturday: 'Sat'
        },
        months_abbrev: {
          january: 'Jan',
          february: 'Feb',
          march: 'Mar',
          april: 'Apr',
          may: 'May',
          june: 'Jun',
          july: 'Jul',
          august: 'Aug',
          september: 'Sep',
          october: 'Oct',
          november: 'Nov',
          december: 'Dec'
        }
      },
      template_added: 'Template added',
      template_added_detail: 'Added template: {template_name}',
      template_edited: 'Template edited',
      template_edited_detail: 'Edited template: {template_name}'
    },
    enable_schedule_editing_modal: {
      header: 'Are you sure?',
      contents: `You are going to enable schedule editing. Changes to template schedule will cause 1 ongoing assignment associated with this template to get deprecated.
      | You are going to enable schedule editing. Changes to template schedule will cause {n} ongoing assignments associated with this template to get deprecated.`
    }
  },
  admin_tableau_status: {
    header: 'Tableau Status',
    fetching_status_failed_details: 'Could not fetch tableau status. Please try again later.',
    could_not_fetch_status: 'Could not fetch status for server {server}. Please try again later.',
    process: 'Process name',
    applicationserver: 'Application Server',
    backgrounder: 'Backgrounder',
    cacheserver: 'Cache Server',
    clustercontroller: 'Cluster Controller',
    coordination: 'Coordination',
    dataserver: 'Data Server',
    filestore: 'File Store',
    flowprocessor: 'Flow Processor',
    gateway: 'Gateway',
    hyper: 'Hyper',
    metrics: 'Metrics',
    repository: 'Repository',
    searchandbrowse: 'Search & Browse',
    vizqlserver: 'VizQL Server',
    status_active: 'Active',
    status_busy: 'Busy',
    status_unlicensed: 'Unlicensed',
    status_down: 'Down',
    status_passive: 'Passive',
    status_activesyncing: 'Synchronizing'
  }
}
