import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from '@/i18n/i18n'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import PrimeVue from 'primevue/config'

import 'primeicons/primeicons.css'

import '@/styles/main.scss'
import { setupSentry, setupCaseParserInterceptor, setupHttpErrorInterceptor, setupBearerTokenInterceptor, setupPrime } from '@/utils'
import setupExternalComponents from './utils/externalComponents'

import { CustomNavigationClient } from './router/customNavigationClient'
import appService, { IAppSettingsResponse } from './services/appService'
import { AppActions } from './store/appStore'
import authService from './services/authService'
import { IApiResponse } from './interfaces/api'
import amsAsService from './services/amsAsService'

// support for translations
const i18n = createI18n({
  locale: 'en',
  messages,
  legacy: false
})

// create the app instance
const app = createApp(App)

// setup plugins
app.use(i18n)
app.use(store)
app.use(PrimeVue, {
  zIndex: {
    modal: 1100, // dialog, sidebar
    overlay: 1025, // dropdown, overlaypanel
    menu: 1000, // overlay menus
    tooltip: 1100 // tooltip
  }
})

setupPrime(app)
setupExternalComponents(app)
setupCaseParserInterceptor()
setupHttpErrorInterceptor();

// get app configuration
(
  async () => {
    await appService.fetchAppSettings()
      .then(async (response: IApiResponse<IAppSettingsResponse>) => {
        try {
          const settings = response.data
          const msal = authService.setupMsal(
            settings.appSettings.msalConfig,
            { scopes: settings.appSettings.msalApiScopes }
          )
          authService.setAmsUrls(settings.appSettings)

          // set AS service url prefix because service constructor could have been called earlier
          amsAsService.setUrlPrefix(settings.appSettings.amsAsUrl)

          const navigationClient = new CustomNavigationClient(router)
          msal.setNavigationClient(navigationClient)

          await store.dispatch(AppActions.SetAppSettings, settings.appSettings)
          setupBearerTokenInterceptor(authService)
        } catch {
          await store.dispatch(AppActions.SetState, 'error')
        }
      })
      .catch(async (error) => {
        if (error.response && error.response.status !== 503) {
          await store.dispatch(AppActions.SetState, 'error')
        }
      })

    setupSentry(app)

    app.use(router)

    router.isReady().then(() => {
      // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
      app.mount('#app')
    })
  }
)()
