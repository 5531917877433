import { ActionContext } from 'vuex'
import store, { IRootState } from '@/store/index'
import { setSentryUser, unsetSentryUser } from '@/utils/sentry/setSentryUser'
import IUserDetails, { IUser } from '@/interfaces/user'
import IGroup from '@/interfaces/group'
import { setMixpanelUser, unsetMixpanelUser } from '@/utils/mixpanel/setupMixpanel'
import IRole from '@/interfaces/role'
import { NotificationsActions } from './notificationsStore'
import { AssignmentsActions } from './assignmentsStore'
import { IFeatureToggles } from '@/interfaces/featureToggles'
import moment from 'moment'
import { IAmsAsMyResource, IAmsAsMyRole } from '@/services/amsAsService'
import { AMS_ACCESS_MANAGMENT_ROLE_SUFFIXES, AmsAccessManagementResource } from '@/interfaces/ams'

const lastActionTimeKey = 'apex.lastActionTime'
const logoutKey = 'apex.logout'

enum SessionMutations {
  SetAuthProvider = 'SET_AUTH_PROVIDER_MUTATION',
  UnsetAuthProvider = 'UNSET_AUTH_PROVIDER_MUTATION',
  SetCurrentUser = 'SET_CURRENT_USER_MUTATION',
  UnsetCurrentUser = 'UNSET_CURRENT_USER_MUTATION',
  UpdateGroup = 'SESSION_UPDATE_GROUP_MUTATION',
  DeleteGroup = 'SESSION_DELETE_GROUP_MUTATION',
  UpdateSubscriptions = 'SESSION_UPDATE_SUBSCRIPTIONS_MUTATION',
  DeleteSubscription = 'SESSION_DELETE_SUBSCRIPTION_MUTATION',
  UpdateLastActionTime = 'SESSION_UPDATE_LAST_ACTION_TIME_MUTATION',
  UpdateAmsRoles = 'SESSION_UPDATE_AMS_AS_ROLES_MUTATION'
}

export enum SessionActions {
  LogIn = 'LOG_IN_ACTION',
  LogOut = 'LOG_OUT_ACTION',
  UpdateGroup = 'SESSION_UPDATE_GROUP_ACTION',
  DeleteGroup = 'SESSION_DELETE_GROUP_ACTION',
  UpdateSubscriptions = 'SESSION_UPDATE_SUBSCRIPTIONS_ACTION',
  DeleteSubscription = 'SESSION_DELETE_SUBSCRIPTION_ACTION',
  UpdateLastActionTime = 'SESSION_UPDATE_LAST_ACTION_TIME_ACTION',
  UpdateAmsRoles = 'SESSION_UPDATE_AMS_AS_ROLES_ACTION'
}

type AuthProvider = 'azure' | 'apex'

export interface ISessionState {
  currentUser: IUser|null,
  roles: IRole[],
  permissions: string[],
  groups: IGroup[],
  governedGroupsIds: number[],
  featureToggles: IFeatureToggles|null,
  authProvider: AuthProvider|null,
  lastActionTime: number,
  amsRoles: IAmsAsMyRole[]|null
}

const storeState = (state: ISessionState): void => {
  localStorage.setItem('session.session', JSON.stringify(state))
}

const clearState = (): void => {
  localStorage.removeItem('session.session')
}

// This is the session store
// It is responsible for handling and storing data related to currently signed in user
export default {
  state: (): ISessionState => ({
    currentUser: null,
    roles: [],
    permissions: [],
    groups: [],
    governedGroupsIds: [],
    featureToggles: null,
    authProvider: null,
    lastActionTime: moment().unix(),
    amsRoles: null
  }),
  getters: {
    isLoggedIn: (state: ISessionState): boolean => {
      return state.currentUser !== null
    },
    hasOneOfAccessManagementResources: (state: ISessionState) => (resources: AmsAccessManagementResource[], appName?: string) => {
      if (state.amsRoles === null) {
        return false
      }

      return state.amsRoles
        .filter((roles: IAmsAsMyRole) => appName ? roles.appName === appName : true)
        .some((roles: IAmsAsMyRole) => roles.resourceList
          .some((res: IAmsAsMyResource) => resources
            .some((paramRes: string) => paramRes === res.name))
        )
    }
  },
  mutations: {
    [SessionMutations.SetAuthProvider] (state: ISessionState, payload: AuthProvider): void {
      state.authProvider = payload
    },
    [SessionMutations.UnsetAuthProvider] (state: ISessionState): void {
      state.authProvider = null
    },
    [SessionMutations.SetCurrentUser] (state: ISessionState, payload: IUserDetails): void {
      state.currentUser = payload.currentUser
      state.roles = payload.roles
      state.permissions = payload.permissions
      state.groups = payload.groups
      state.governedGroupsIds = payload.governedGroupsIds
      state.featureToggles = payload.featureToggles
    },
    [SessionMutations.UnsetCurrentUser] (state: ISessionState): void {
      state.currentUser = null
    },
    [SessionMutations.UpdateGroup] (state: ISessionState, group: IGroup): void {
      const hasGroup = state.groups.some((oldGroup: IGroup) => oldGroup.id === group.id)
      if (!hasGroup) {
        state.groups = [...state.groups, group]
      }
    },
    [SessionMutations.DeleteGroup] (state: ISessionState, group: IGroup): void {
      state.groups = state.groups.filter((oldGroup: IGroup) => oldGroup.id !== group.id)
    },
    [SessionMutations.UpdateLastActionTime] (state: ISessionState, actionTime?: number): void {
      state.lastActionTime = actionTime || moment().unix()
      localStorage.setItem(lastActionTimeKey, state.lastActionTime.toString())
    },
    [SessionMutations.UpdateAmsRoles] (state: ISessionState, amsRoles: IAmsAsMyRole[]): void {
      // get application name from role name using known AMS role suffixes
      const pattern = new RegExp(AMS_ACCESS_MANAGMENT_ROLE_SUFFIXES.join('|') + '$')

      state.amsRoles = amsRoles.map((role: IAmsAsMyRole) => {
        return { ...role, appName: role.name.replace(pattern, '') }
      })
    }
  },
  actions: {
    async [SessionActions.LogIn] (context: ActionContext<ISessionState, IRootState>, userDetails: IUserDetails): Promise<void> {
      context.commit(SessionMutations.SetCurrentUser, userDetails)

      await store.dispatch(NotificationsActions.RestoreState)
      await store.dispatch(AssignmentsActions.RestoreState)
      context.commit(SessionMutations.UpdateLastActionTime)

      setSentryUser(userDetails.currentUser)
      setMixpanelUser(userDetails.currentUser)
      storeState(context.state)
    },
    async [SessionActions.LogOut] (context: ActionContext<ISessionState, IRootState>): Promise<void> {
      context.commit(SessionMutations.UnsetCurrentUser)

      await store.dispatch(NotificationsActions.ClearState)
      await store.dispatch(AssignmentsActions.ClearState)

      unsetSentryUser()
      unsetMixpanelUser()

      clearState()
    },
    async [SessionActions.UpdateGroup] (context: ActionContext<ISessionState, IRootState>, group: IGroup): Promise<void> {
      context.commit(SessionMutations.UpdateGroup, group)
    },
    async [SessionActions.DeleteGroup] (context: ActionContext<ISessionState, IRootState>, group: IGroup): Promise<void> {
      context.commit(SessionMutations.DeleteGroup, group)
    },
    async [SessionActions.UpdateLastActionTime] (context: ActionContext<ISessionState, IRootState>, actionTime?: number): Promise<void> {
      context.commit(SessionMutations.UpdateLastActionTime, actionTime)
    },
    async [SessionActions.UpdateAmsRoles] (context: ActionContext<ISessionState, IRootState>, roles: IAmsAsMyRole[]): Promise<void> {
      context.commit(SessionMutations.UpdateAmsRoles, roles)
    }
  }
}

window.addEventListener('storage', function (event: StorageEvent): void {
  if (event.key) {
    if (event.key === lastActionTimeKey) {
      store.dispatch(SessionActions.UpdateLastActionTime, event.newValue ? +event.newValue : 0)
    }
    if (event.key === logoutKey) {
      store.dispatch(SessionActions.LogOut)
    }
  }
})
