import { ActionContext } from 'vuex'
import { IRootState } from '@/store/index'
import { IAmsNgsFacility } from '@/interfaces/ams'

enum AmsMutations {
  SetAmsUserProfilesView = 'SET_AMS_USER_PROFILES_VIEW_MUTATION',
  SetAmsNgsFacilities = 'SET_AMS_NGS_FACILITIES_MUTATION'
}

export enum AmsActions {
  SetAmsUserProfilesView = 'SET_AMS_USER_PROFILES_VIEW_ACTION',
  SetAmsNgsFacilities = 'SET_AMS_NGS_FACILITIES_ACTION'
}

export interface IAmsUserProfilesView {
  pageNumber: number;
  pageSize: number;
  filter: string;
  applicationId: string[];
  ngsFacilityNumber: string[];
}

export interface IAmsState {
  ngsFacilities: IAmsNgsFacility[];
  userProfilesView: IAmsUserProfilesView;
}

export default {
  state: (): IAmsState => ({
    userProfilesView: {
      pageNumber: 1,
      pageSize: 10,
      filter: '',
      applicationId: [],
      ngsFacilityNumber: []
    },
    ngsFacilities: []
  }),
  mutations: {
    [AmsMutations.SetAmsUserProfilesView] (state: IAmsState, payload: IAmsUserProfilesView): void {
      state.userProfilesView = payload
    },
    [AmsMutations.SetAmsNgsFacilities] (state: IAmsState, payload: IAmsNgsFacility[]): void {
      state.ngsFacilities = payload
    }
  },
  actions: {
    [AmsActions.SetAmsUserProfilesView] (context: ActionContext<IAmsState, IRootState>, view: IAmsUserProfilesView): void {
      context.commit(AmsMutations.SetAmsUserProfilesView, view)
    },
    [AmsActions.SetAmsNgsFacilities] (context: ActionContext<IAmsState, IRootState>, facilities: IAmsNgsFacility[]): void {
      context.commit(AmsMutations.SetAmsNgsFacilities, facilities)
    }
  }
}
