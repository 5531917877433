<template>
  <div
    ref="dropdownRef"
    :class="{'dropdown': true, 'show': showDropdown}"
    @click="toggleDropdown"
  >
    <div class="nav-link dropdown-toggle">
      <div
        v-if="user"
        class="d-none d-lg-block header-user-lockup nowrap header-user"
      >
        <div
          v-if="user.type.pid === 'external'"
          class="header-username"
        >
          {{ user.login || user.email }}
        </div>
        <div
          v-if="user.type.pid === 'domain'"
          class="header-username"
        >
          {{ user.firstName }} {{ user.lastName }}
        </div>
        <i class="pbi-icon-outline pbi-user ml-4" />
      </div>
      <div
        v-if="user"
        class="d-lg-none nowrap header-user"
      >
        <i class="pbi-icon-outline pbi-user" />
        <div
          v-if="user.type.pid === 'external'"
          class="header-username header-alt-text"
        >
          {{ user.login || user.email }}
        </div>
        <div
          v-if="user.type.pid === 'domain'"
          class="header-username header-alt-text"
        >
          {{ user.firstName }} {{ user.lastName }}
        </div>
      </div>
    </div>
    <div class="dropdown-menu">
      <router-link
        style="text-decoration: none;"
        to="/profile"
      >
        <div class="dropdown-item">
          <div>{{ t('layout.header.link_profile') }}</div>
          <span class="menu-text-small">{{ t('layout.header.link_profile_subtitle') }}</span>
        </div>
      </router-link>
      <router-link
        style="text-decoration: none;"
        to="/support"
      >
        <div class="dropdown-item">
          <div>{{ t('layout.header.link_support') }}</div>
          <span class="menu-text-small">{{ t('layout.header.link_support_subtitle') }}</span>
        </div>
      </router-link>
      <router-link
        v-if="userFormEnabled"
        style="text-decoration: none;"
        to="/user-request"
      >
        <div class="dropdown-item">
          <div>{{ t('layout.header.link_user_request') }}</div>
          <span class="menu-text-small">{{ t('layout.header.link_user_request_subtitle') }}</span>
        </div>
      </router-link>
      <router-link
        v-if="requestAdditionalToolsEnabled"
        style="text-decoration: none;"
        to="/tools/browse"
      >
        <div class="dropdown-item">
          <div>{{ t('layout.header.link_browse_tools') }}</div>
          <span class="menu-text-small">{{ t('layout.header.link_browse_tools_subtitle') }}</span>
        </div>
      </router-link>
      <a
        v-if="displayJiraLink"
        class="dropdown-item"
        style="color: var(--text);"
        :href="jiraUserTicketsURL"
        target="_blank"
      >
        <div>{{ t('layout.header.link_jira_users_tickets') }}</div>
        <span class="menu-text-small">{{ t('layout.header.link_jira_users_tickets_subtitle') }}</span>
      </a>
      <div class="divider" />
      <span
        class="dropdown-item"
        @click="logOut()"
      >{{ t('layout.header.link_signout') }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { IUser } from '@/interfaces/user'
import useClickOutside from '@/utils/clickOutside'
import { trackMixpanelEvent } from '@/utils/mixpanel/setupMixpanel'
import MixpanelEvents from '@/utils/mixpanel/mixpanelEvents'
import { useRouter } from 'vue-router'
import useToasts from '@/composables/useToasts'
import authService from '@/services/authService'

export default defineComponent({
  name: 'HeaderUser',
  setup () {
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const toast = useToasts()

    const user = computed((): IUser|null => store.state.session.currentUser)
    const permissions = computed((): string[] => store.state.session.permissions)

    const dropdownRef = ref()

    const showDropdown = ref(false)
    const toggleDropdown = (): void => {
      showDropdown.value = !showDropdown.value
    }


    let clearClick: () => void

    const logOut = async (): Promise<void> => {
      try {
        await authService.logout()
        trackMixpanelEvent(MixpanelEvents.LoggedOut)
        router.push('/login')
      } catch {
        toast.showError(t('layout.header_user.logout_error').toString(), t('layout.header_user.logout_error').toString())
      }
    }

    const userFormEnabled = computed((): boolean => {
      return permissions.value.includes('bi_request.create') || permissions.value.includes('kb_request.create')
    })

    const requestAdditionalToolsEnabled = computed((): boolean => {
      return store.state.session.featureToggles.requestAdditionalTools && user.value?.type.pid === 'domain'
    })

    const displayJiraLink = computed((): boolean => {
      if (user.value?.email) {
        return true
      } else {
        return false
      }
    })

    const jiraUserTicketsURL = computed((): string | undefined => {
      if (user.value?.email) {
        return `${store.state.app.jiraIssuesLink}`
      } else {
        return undefined
      }
    })

    onMounted((): void => {
      clearClick = useClickOutside([dropdownRef.value], () => {
        showDropdown.value = false
      })
    })

    onUnmounted((): void => {
      clearClick()
    })

    return {
      t,
      user,
      showDropdown,
      toggleDropdown,
      dropdownRef,
      logOut,
      userFormEnabled,
      requestAdditionalToolsEnabled,
      displayJiraLink,
      jiraUserTicketsURL
    }
  }
})
</script>

<style scoped lang="scss">
.header-user {
  font-family: "PrecisionSans_W_Rg","Helvetica Neue",Arial,sans-serif;
  font-weight: normal;
}

.header-username {
  white-space: pre-wrap;
}

.nav-link {
  cursor: pointer;
  user-select: none;
  transition: padding-left 0.3s linear;
  transition: padding-right 0.3s linear;
}

@media (max-width: 575.98) {
  .nav-link {
    padding: 16px !important;
  }
}

@media (min-width: 992px) {
  .nav-link {
    padding: 24px 16px !important;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .header-username {
    display: none;
  }

  .header-user {
    color: #fff !important;
  }

  .nav-link {
    padding: 24px 8px !important;
  }
}

.header-alt-text {
  padding-left: 5px;
}

.dropdown {
  &.show {
    .dropdown-menu {
      display: block;

      @media (max-width: 575.98px) {
        border: 0;

        .divider {
          display: none;
        }
      }
    }
  }
}
.menu-text-small {
  font-size: 10px;
}
.dropdown-item div {
  line-height: 10px;
}
</style>
