// English translations for APEX
import apiI18n from './api_en'
import adminI18n from './admin_en'
import amsI18n from './ams_en'

export default {
  common: {
    apex: 'APEX',
    apex_slogan: 'Achieving Performance Excellence.',
    unknown_error: 'Unknown error. Please try again.',
    server_error: 'Server response error',
    app_init_error: 'Failed to initialize application',
    button: {
      export: 'Export',
      ok: 'OK',
      save: 'Save',
      cancel: 'Cancel',
      close: 'Close',
      accept: 'Accept',
      submit: 'Submit',
      apply: 'Apply',
      create: 'Create'
    },
    yes: 'Yes',
    no: 'No',
    modified_on: 'Modified on',
    created_on: 'Created on',
    created_by: 'Created by',
    modified_by: 'Modified by',
    copy_link: 'Copy shareable link',
    link_copied: 'Link copied',
    link_copied_details: 'Copied link to clipboard',
    link_copy_failed: 'Could not copy link',
    link_copy_failed_details: 'Could not copy link to clipboard.',
    service_worker_failure_details: 'Could not initialize a service worker. Some features might not be available.',
    service_worker_failure: 'Could not initialize a service worker.'
  },
  show_jira_ticket_modal: {
    header: 'Your issue has been logged',
    key_information_new_issue: 'Please find the link to your issue - ',
    key_information_all_issues: ' You can also view all your issues by selecting - ',
    jira_all_tickets: 'My JIRA issues',
    cancel: 'Close'

  },
  layout: {
    header: {
      link_tools: 'Tools',
      link_assignments: 'Assignments',
      link_knowledge_base: 'Knowledge Base',
      link_analytics: 'Self-Service Analytics',
      link_administration: 'Administration',
      link_notifications: 'Notifications',
      link_login: 'Login',
      link_profile: 'Profile',
      link_profile_subtitle: 'Groups, Roles, Preferences',
      link_support: 'Report a platform issue',
      link_user_request: 'Submit a Request',
      link_support_subtitle: 'Get support from APEX Team',
      link_user_request_subtitle: 'User Story, Project Charter, KB Articles',
      link_signout: 'Sign out',
      link_browse_tools: 'Browse available tools',
      link_browse_tools_subtitle: 'Request access to additional tools',
      link_jira_users_tickets: 'Your JIRA tickets',
      link_jira_users_tickets_subtitle: 'See a list of your logged tickets'
    },
    footer: {
      all_rights_reserved: 'All rights reserved.',
      legal: 'Legal',
      contact_us: 'Contact us'
    },
    header_assignments: {
      fetching_error: 'Fetching assignments count failed'
    },
    header_notifications: {
      fetching_error: 'Fetching notifications failed',
      load_older: 'Load older',
      empty_notifications: 'There are no active notifications'
    },
    header_user: {
      logout_error: 'Failed to log out'
    }
  },
  components: {
    modals: {
      apex_upload_image_modal: {
        header: 'Upload image',
        files_label: 'Image',
        file_required: 'Image is required',
        max_files_count: 'Can only upload one file',
        max_size: 'File size must not exceed 5 MB',
        could_not_upload_image_details: 'Could not upload image. Please try again later.',
        image_uploaded: 'Image has been uploaded',
        image_uploaded_details: 'Image has been uploaded successfully.'
      },
      jasper_export_report_modal: {
        header: 'Export report',
        type_label: 'Export to',
        export: 'Export',
        type_required: 'Type required',
        could_not_export_report: 'Could not export report. Please try again later.'
      }
    },
    tools: {
      tool_access_request_modal: {
        header: 'Tool Access Request',
        description: 'You are requesting access to tool {toolName}.',
        could_not_fetch_tool_data: 'Could not fetch tool details. Please try again later.',
        request_submitted: 'Access request submitted',
        request_submitted_details: 'Access request submitted. Please wait for a response from governors of the selected group.',
        group_required: 'Group required',
        request_failed: 'Could not submit access request',
        request_failed_details: 'Could not submit access request. Please try again later.',
        select_group_label: 'Select a group',
        select_group_placeholder: 'Select a group...',
        reason_label: 'Reason',
        reason_placeholder: 'Reason...',
        governors: 'Governors of selected group',
        already_submitted: 'You have already submitted a request for this tool. It is currently being reviewed by group governors.',
        selected_group: 'Selected group',
      },
      governor_add_tool_modal: {
        header: 'Add tool to group',
        description: 'Select one of the groups that you govern to add tool {toolName} to it.',
        could_not_fetch_tool_data: 'Could not fetch tool details. Please try again later.',
        request_submitted: 'Added tool to group',
        request_submitted_details: 'Successfully added tool to group',
        group_required: 'Group required',
        request_failed: 'Could not submit request',
        request_failed_details: 'Could not submit request. Please try again later.',
        select_group_label: 'Select a group',
        select_group_placeholder: 'Select a group...'
      }
    },
    forms: {
      common: {
        optional: 'optional'
      },
      apex_markdown: {
        characters_remaining: 'characters remaining',
        upload_image: 'Upload image',
        personalization_user_first_name: 'First name',
        personalization_user_last_name: 'Last name',
        personalization_user_login: 'Login',
        personalization_user_email: 'Email',
        personalization: 'Personalization'
      },
      apex_dropdown: {
        delete_selection: 'Delete selection'
      },
      apex_list: {
        move_up: 'Move up',
        move_down: 'Move down',
        edit: 'Edit name',
        delete_selection: 'Delete'
      },
      apex_file_upload: {
        click_prompt: 'Choose a file | Choose files',
        drag_and_drop_prompt: 'or drag and drop it here | or drag and drop them here',
        file_name: 'Name',
        file_size: 'Size',
        actions: 'Actions',
        delete_file: 'Delete file',
        drop_to_add: 'Drop selected files here',
        max_size_allowed: 'Total size allowed: {size}',
        max_files_allowed: 'Total files allowed: {maxFiles}',
        invalid_files: 'Invalid file types detected',
        accepted_files: 'Accepted files: {types}',
        current_file: 'Current file:',
        invalid_file_type: 'Only {types} can be accepted. Please try again.'
      }
    },
    notifications: {
      notification_banner: {
        previous_notification: 'Previous notification',
        next_notification: 'Next notification',
        more_details: 'More details',
        dismiss_all: 'Dismiss all'
      },
      notification_modal: {
        details: 'Details',
        author: 'Author',
        created_on: 'Created on',
        start_date: 'Start date',
        end_date: 'End date',
        previous_notification: 'Previous notification',
        next_notification: 'Next notification'
      }
    },
    tool_tile: {
      no_description: 'No description',
      add_favourite: 'Add to favourites',
      remove_favourite: 'Remove from favourites',
      open_tool: 'Open Tool',
      open_external_tool: 'Open External Tool',
      more_details: 'More details',
      open_to: 'Open to',
      notifications_available: 'There are {count} notifications available for this tool. Click here to view them.',
      uat: 'UAT',
      uat_tool: 'This tool has not yet been published and is in User Acceptance Testing'
    },
    tool_tile_details_modal: {
      description: 'Description',
      details: 'Details',
      type: 'Type',
      groups: 'Groups',
      department: 'Department',
      owner: 'Owner'
    },
    embedded_tools: {
      jasper_tool: {
        loading_jasper_tool: 'Waiting for a response from Jasper Server...',
        apply_filters_to_load_report: 'Apply filters to load the report',
        next_page: 'Next page',
        prev_page: 'Previous page',
        filters: 'Filters',
        failed_to_fetch_script: 'Could not fetch jasper script. Please try again later.',
        export: 'Export'
      },
      tableau_cloud_tool: {
        acquiring_licence: 'We are setting up your Tableau Cloud Licence.',
        acquiring_licence_details: 'Your tool will automatically open after this process is completed. You can also close this tool and reopen it later.',
        could_not_open_tool: 'Could not open Tableau Cloud tool. Please try again later.',
        no_views_available: 'There are no views available for this tool. Please try again later.'
      },
      knowledge_base_viewer: {
        title: 'Help Docs - Related Articles',
        no_articles: 'There are no help articles available.',
        view_in_tab: 'View in the Knowledge Base',
        could_not_fetch_article: 'Could not fetch article'
      },
      share_tool_form: {
        title: 'Share tool',
        cancel: 'Cancel',
        share: 'Share',
        emails_label: 'Email addresses',
        emails_placeholder: 'Recipient email address, GUID or name...',
        comment_label: 'Comment',
        comment_optional_label: 'Optional',
        comment_placeholder: 'Comment...',
        comment_remaining_length: 'characters remaining',
        emails_required: 'At least one user must be selected.',
        could_not_fetch_users: 'Could not fetch users',
        shared_tool: 'Shared tool',
        shared_tool_details: 'Successfuly shared tool with selected users',
        sharing_state_note: 'Tool state, including filters, will be shared with selected users.'
      },
      uat_tool_form: {
        title: 'UAT Review',
        comment_label: 'Comment',
        comment_placeholder: 'Comment...',
        comment_remaining_length: 'characters remaining',
        action_label: 'Action',
        action_placeholder: 'Select an action...',
        could_not_create_uat_review: 'Could not submit a UAT review. Please try again later.',
        action_required: 'Action is required.',
        comment_required: 'Comment is required.',
        uat_review_submitted: 'UAT review submitted',
        uat_review_submitted_description: 'UAT review submitted successfully.',
        approve: 'Approve',
        provide_feedback: 'Provide feedback'
      },
      tool_connection_error: {
        title: 'It seems we are experiencing issues loading current tool',
        subtitle: 'Don\'t worry. It\'s probably an easy fix.',
        try_again: 'Try reloading the page in a few minutes.',
        error: 'Error',
        help_1: 'If that doesn\'t work, please contact',
        help_2: 'to report the issue.',
        apex_support: 'APEX Support'
      },
      tool_deleted_error: {
        title: 'This tool does not exist',
        subtitle: 'This tool does not exist or has been deleted.',
        error: 'Error'
      },
      support_tool_form: {
        title: 'Report an issue'
      },
      tool_zscaler_warning: {
        title: 'Zscaler Private Access not detected',
        action_1: 'This tool can only be accessed from within PB network. To assure unrestricted access, please connect to Zscaler Private Access on your device.',
        action_2: 'to skip this warning after connecting to Zscaler Private Access.',
        skip: 'Click here'
      },
      tool_rating: {
        total_likes: ' {totalLikes} '
      },
      external_tool: {
        placeholder: 'This tool is an external tool. If you are not automatically redirected, please click on the link to open it:'
      }
    },
    reset_password_modal: {
      reset_passwd_modal_header: 'Reset Password',
      new_passwd_label: 'New Password',
      confirm_passwd_label: 'Confirm Password',
      reset_passwd_submit: 'Submit',
      passwd_not_equal: 'Passwords are not equal',
      passwd_required: 'Field required',
      passwd_changed: 'Password changed succesfully',
      passwd_complexity: 'Password doesn\'t meet requirements. It must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character and must be at least 8 characters long.',
      passwd_constraints_info: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character and must be at least 8 characters long.'
    },
    support_form: {
      issue_label: 'Issue category:',
      issue_placeholder: 'Select issue category...',
      description_label: 'Description:',
      description_placeholder: 'Describe the issue...',
      severity_label: 'Severity:',
      severity_placeholder: 'Select severity level...',
      files_label: 'Files:',
      max_files_count: 'You can upload at most {count} files',
      max_size: 'You can upload at most {size} in total',
      issue_required: 'Issue category is required',
      description_required: 'Description is required',
      severity_required: 'Severity is required',
      could_not_create_request: 'Could not create a support request. Please try again.',
      request_created: 'Support request created',
      request_created_description: 'Support request was created successfully.',
      request_created_description_jira: 'Support request was created successfully under reference number {jiraTicketNumber}.',
      could_not_fetch_categories: 'Could not fetch issue categories',
      could_not_fetch_categories_details: 'Could not fetch issue categories. Please try again later.',
      request_access_message: 'I would like to request access to: {url}.',
      description_remaining_length: 'characters remaining'
    },
    maintenance: {
      title: 'We\'re doing some maintenance',
      subtitle: 'APEX will be available soon',
      subtitle2: 'It shouldn\'t take more than a few minutes',
      error: 'Error:'
    }
  },
  views: {
    admin: adminI18n,
    login: {
      sso_button: 'Pitney Bowes Employee One Click Sign On',
      separator: 'or',
      email_login_label: 'E-mail / Login',
      password_label: 'Password',
      login_button: 'Sign in',
      invalid_credentials: 'We don\'t recognize that email/login and password combination.',
      email_login_required: 'Email or login is required',
      password_required: 'Password is required',
      direct_login: 'Direct Log In for External Accounts',
      sso_auth_error: 'PB SSO authentication error. Please try again.',
      sso_unknown_error: 'Authentication error. Please try again.',
      account_locked: 'Your account has been locked.',
      session_expired: 'It looks like your session may have expired. To continue, please sign in again.',
      auth_server_error: 'Authentication server error. Please try again later.'
    },
    browse_tools: {
      header: 'Browse available tools',
      subheader: 'Here you can find and request access to APEX tools that are currently not assigned to your groups.',
      col_header_type: 'Type',
      col_header_name: 'Name',
      col_header_description: 'Description',
      col_header_department: 'Department',
      col_header_groups: 'Groups',
      col_header_actions: 'Actions',
      col_header_preview: 'Preview',
      loading_tools: 'Loading tools. Please wait...',
      no_tools_found: 'No tools found.',
      tool_search: 'Search...',
      group_filter: 'Group',
      type_filter: 'Type',
      department_filter: 'Department',
      request_access: 'Request access to tool {name}',
      access_request_pending: 'Access request for this tool is pending approval from {governors}'
    },
    welcome: {
      fetching_error: 'Server response error',
      unknown_error: 'Unknown error. Please try again.',
      departments_section_title: 'Welcome',
      departments_section_text: 'It looks like you\'re new to APEX. Let\'s get your account registered.',
      departments_dropdown_label: 'What department are you in?',
      departments_dropdown_placeholder: 'Select Department...',
      departments_dropdown_submit_button: 'Next',
      departments_dropdown_validator: 'Please select a department from the list.',
      groups_section_title: 'Which group is the most relevant to you?',
      groups_section_text_1: 'Select a group to request access. The governors of that group will review your request.',
      groups_section_text_2: 'You can always join more groups later.',
      groups_radio_governors: 'Governed by:',
      groups_radio_governors_oxford_comma: 'and',
      groups_radio_expand: 'Show more...',
      groups_radio_submit_button: 'Submit Request',
      groups_radio_reset_button: 'Back',
      no_groups_title: 'Sorry, we couldn\'t find any groups to suggest for you',
      no_groups_text: 'Please go back and choose a different department or speak to your manager about access.',
      success_section_text_1: 'An email request for access to',
      success_section_text_2: 'has been sent for approval to',
      success_section_text_3: 'You should receive an email response',
      success_section_text_4: 'before',
      success_section_text_5: 'within the next 72 hours',
      success_section_text_alt: 'An email request for access to <b>{groupName}</b> has been sent to its governors.',
      signout_button: 'Sign out',
      cancel_button: 'Cancel'
    },
    tools: {
      set_favourite_error: 'Could not set tool as favourite',
      unset_favourite_error: 'Could not unset tool as favourite',
      default_view_error: 'Could not open the selected view',
      search_tools: 'Search Tools',
      favourites_only: 'Favourites only',
      no_matches_found: 'No matches found',
      search_placeholder: 'Search',
      most_popular_sort: 'Most popular',
      most_liked_sort: 'Most liked',
      department_sort: 'Department',
      name_sort: 'Name',
      type_sort: 'Type',
      fetching_error: 'Could not load tools. Please try again later.',
      sort: 'Sort',
      departments: 'Departments',
      groups: 'Groups',
      types: 'Types',
      fetching_most_popular_error: 'Could not fetch most popular tools',
      fetching_most_popular_error_details: 'Could not fetch most popular tools. Please try again later.',
      fetching_most_liked_error: 'Could not fetch most liked tools',
      fetching_most_liked_error_details: 'Could not fetch most liked tools. Please try again later.',
      select_departments: 'No departments selected',
      select_groups: 'No groups selected',
      select_types: 'No tool types selected',
      uat_only: 'UAT only',
      find_tools_link: 'Click here',
      find_tools_description: ' to request access to additional tools',
      unable_to_rate_tool: ' A problem occured while rating this tool.'
    },
    assignments: {
      header: 'Assignments',
      groups_label: 'Groups:',
      my_to_do_tab: 'My To Do',
      unassigned_tab: 'Unassigned',
      my_groups_tab: 'My Groups',
      fetching_counts_error: 'Could not retrive assignments counts',
      fetching_groups_error: 'Could not retrive user groups',
      common: {
        deprecated_badge: 'deprecated',
        overdue_assignment: 'Overdue Assignment',
        no_assignments: 'No assignments to display',
        loading_assignments: 'Loading assignments...',
        adhoc_assignment: 'Ad-hoc Assignment'
      },
      assignee_select: {
        loading: 'Loading...',
        assigned_to: 'Assigned To:',
        select_user: 'Select User...',
        assign: 'Assign',
        reassign: 'Reassign',
        for_next_4_weeks: 'For next 4 Weeks',
        for_next_8_weeks: 'For next 8 Weeks',
        for_next_12_weeks: 'For next 12 Weeks',
        send_email_to_assignee: 'Send Email to Assignee'
      },
      details: {
        fetching_details_error: 'Could not load assignment details',
        fetching_tool_error: 'Could not retrive tool',
        fetching_sop_error: 'Could not retrive sop',
        add_comment_error: 'Could not add comment',
        change_status_error: 'Could not change assignment status',
        resend_email_error: 'Could not send notification email',
        create_and_cancel_error: 'Could not set assignment as not needed',
        open_tool_error: 'Could not open the selected tool',
        status_new: 'New',
        status_completed: 'Completed',
        status_blocked: 'Blocked',
        status_not_needed: 'Cancelled',
        status_not_assigned: 'Not Assigned',
        no_tools_used: 'No tools used',
        no_documents_supported: 'No documents supported',
        importance: {
          low: 'Low',
          medium: 'Medium',
          high: 'High'
        },
        due_date_label: 'Due Date:',
        group_label: 'Group:',
        status_label: 'Status:',
        assignment_id_label: 'Assignment ID:',
        tools_used: 'Tools Used:',
        supported_docs: 'Supported Documents:',
        revert_to_new_btn: 'Revert to New',
        complete_btn: 'Complete',
        block_btn: 'Block',
        unblock_btn: 'Unblock',
        not_needed_btn: 'Not Needed',
        resend_email_btn: 'Resend Email',
        create_and_cancel_btn: 'Not Needed',
        comments: 'Comments',
        post_comment_btn: 'Post',
        suggested_users: 'Suggestions',
        other_users_from_group: 'Other Users from Group',
        edit_assignment: 'Edit assignment',
        duplicate_assignment: 'Duplicate assignment',
        assignment_type_label: 'Assignment Type:'
      },
      bulk_assign: {
        header: 'Bulk Assign',
        fetching_assignees_error: 'Could not retrive available assignees',
        assign_error: 'Could not assign assignment(s)'
      },
      bulk_toolbar: {
        multiselect: 'Multiselect',
        select_all: 'Select All',
        assign_btn: 'Assign'
      },
      card_filter: {
        all_overdue: 'All Overdue',
        all_blocked: 'All Blocked',
        new: 'New',
        completed: 'Completed',
        not_needed: 'Not Needed'
      },
      group_date_filter: {
        current_week: 'Current Week',
        week: 'Week',
        select_groups: 'Select Groups...',
        selected_range: 'Custom range',
        select_date: 'Select dates',
        previous_period: 'Previous period',
        next_period: 'Next period',
        previous_week: 'Previous Week',
        next_week: 'Next Week',
        current_month: 'Current Month',
        previous_month: 'Previous Month',
        next_month: 'Next Month',
        no_groups: 'No groups selected'
      },
      search_toolbar: {
        clear_selection: 'Clear selection',
        items_selected: '{n} Items Selected',
        select: 'Select',
        template_name_placeholder: 'Assignment',
        assigned_to_placeholder: 'Assigned to',
        instance_label: 'Shift'
      },
      adhoc: {
        create_adhoc_assignment: 'Create Assignment'
      },
      set_status_blocked_modal: {
        header: 'Block Assignment',
        add_comment_label: 'Please Leave a Comment In Order to Block',
        block_btn: 'Block'
      },
      resolve_assign_conflicts_modal: {
        header: 'Conflicts',
        description_part_1: ' assignments were assigned succesfully and another ',
        description_part_2: ' were skipped because of conflicts. You can find them on the list below and decide whether leave them as it is or reassign them anyway.',
        assigned_to_label: 'Assigned To:',
        reasign_selected_btn: 'Reasign Selected',
        close_btn: 'Close'
      },
      add_edit_adhoc_assignments_modal: {
        save: 'Save',
        close: 'Close',
        name_label: 'Name',
        name_placeholder: 'Name...',
        description_label: 'Description',
        description_placeholder: 'Description...',
        name_required: 'Name is required',
        description_required: 'Description is required',
        group_required: 'Group is required',
        user_required: 'User is required',
        due_required: 'Due date is required',
        importance_required: 'Importance is required',
        edit: 'Edit Ad-hoc Assignment',
        add: 'Add Ad-hoc Assignment',
        duplicate: 'Duplicate Ad-hoc Assignment',
        assignment_added: 'Assignment added',
        assignment_added_detail: 'Successfully created an ad-hoc assignment:',
        assignment_edited: 'Assignment edited',
        assignment_edited_detail: 'Assignment edited successfully:',
        sop_label: 'Standard Operating Procedure (SOP)',
        sop_placeholder: 'Add SOP...',
        tools_label: 'Tools needed',
        tools_placeholder: 'Add tool...',
        group_label: 'Assigned to group',
        group_placeholder: 'Select group...',
        user_label: 'Assigned to',
        user_placeholder: 'Select user...',
        importance_label: 'Importance',
        importance_placeholder: 'Select importance...',
        due_label: 'Set due date',
        due_placeholder: 'Set due date...',
        importance_low: 'Low',
        importance_medium: 'Medium',
        importance_high: 'High',
        fetching_users_error: 'Failed to fetch users',
        fetching_tools_failed: 'Failed to fetch tools',
        fetching_kb_failed: 'Failed to fetch sops',
        fetching_kb_failed_details: 'Could not fetch knowledge base articles. Please try again later.',
        no_tools_access: 'Selected group does not have access to some of the tool(s)',
        fetching_groups_failed: 'Failed to fetch groups.',
        validating_tools_failed: 'Failed to fetch related tools',
        validating_tools_details: 'Could not verify if all selected tools are available to selected users.'
      }
    },
    embedded: {
      add_custom_view_modal: {
        name_label: 'Name',
        name_placeholder: 'Name...',
        header: 'Create quick view',
        create: 'Create',
        name_required: 'Name is required',
        could_not_create_custom_view: 'Could not create quick view',
        could_not_create_custom_view_details: 'Could not create quick view. Please try again later.',
        created_custom_view: 'Successfully created view {name}',
        created_custom_view_details: 'Could not create quick view. Please try again later.',
        default_label: 'Set as default view',
        default_required: 'Default view value required',
        unique_name_required: 'A view with this name already exists.'
      },
      manage_custom_view_modal: {
        header: 'Manage quick views',
        save: 'Save',
        top_3_views: 'Top three views can be accessed via the quick view menu.',
        no_views: 'All views will be removed.',
        edited_views: 'Edited quick views',
        edited_views_details: 'Names and order of views have been updated.',
        views_must_have_names: 'All views must have valid names',
        view_created: 'View created successfully',
        view_created_details: 'View {name} was created successfully.',
        set_default_view: 'Set view as default',
        unset_default_view: 'Unset default view',
        could_not_edit_views: 'Could not edit views',
        could_not_edit_views_details: 'Could not edit views. Please try again later.',
        views_must_have_unique_names: 'All views must have unique names.'
      }
    },
    embedded_tool: {
      share: 'Share',
      kb: 'Knowledge Base',
      favourite: 'Favourite',
      like_this: 'I like this',
      unlike: 'Unlike',
      dislike_this: 'I dislike this',
      too_many_likes: '99+',
      close: 'Close',
      could_not_load_selected_tool: 'Could not load selected tool, please try again later.',
      support: 'Report an issue',
      uat_review: 'UAT Review',
      uat_deadline: 'UAT Deadline:',
      uat_deadline_tooltip: 'Remaining time to complete the UAT review for this tool.',
      quick_view: 'Quick view',
      manage_views: 'Manage views',
      create_view: 'Create view',
      more_views: 'More',
      copy_link: 'Copy direct link to tool',
      failed_fetching_custom_views: 'Could not fetch custom views',
      failed_fetching_custom_views_details: 'Could not fetch custom views. Please try again later.',
      enable_fullscreen: 'Enable Full Screen Mode',
      disable_fullscreen: 'Disable Full Screen Mode'
    },
    errors: {
      not_found: {
        title: 'This address doesn\'t exist.',
        url_title: 'Here\'s what you looked for',
        try_again: 'You may want to check for typos and try again.',
        error: 'Error'
      },
      forbidden: {
        title: 'We can\'t let you in.',
        subtitle: 'You\'ll need permission first.',
        description: 'You\'ve attempted to access a restricted area that may require additional permissions.',
        request_access: 'Request Access',
        error: 'Error'
      }
    },
    profile: {
      header: 'Profile',
      login_label: 'Login:',
      name_label: 'Name:',
      email_label: 'Email Address:',
      organization_label: 'Organization:',
      job_family_group_label: 'Job Family Group:',
      job_family_label: 'Job Family:',
      title_label: 'Title:',
      manager_label: 'Manager:',
      location_label: 'Location:',
      groups_label: 'Groups:',
      roles_label: 'Roles:',
      user_label: 'User',
      reset_passwd_btn: 'Reset Password',
      preferences: {
        header: 'Preferences:',
        select: 'Select...',
        default_facility: 'Default Facility',
        save_btn: 'Save preferences',
        saved: 'Preferences saved'
      }
    },
    support: {
      header: 'Report an APEX Platform issue',
      form_description: 'Use this form to raise a support ticket to the APEX Support Team. The APEX support team will respond to all enquiries as soon as possible.',
      form_description_sla: 'Use this form to raise a support ticket to the APEX Support Team. The APEX support team will respond to all enquiries within {hours} hour(s).',
      form_description_general: ' If your enquiry is related to a specific tool, please report the issue directly through that tool\'s page for a more timely response.'
    },
    access_request: {
      malformed_header: 'We can\'t process your request.',
      malformed_body: 'You\'ve attempted to access a restricted area that may require special permission or a subscription. Contact your administrator with access questions.',
      error_header: 'We\'re having an issue with our server.',
      error_body: 'Please wait a few minutes and try reloading the page.',
      processed_header: 'This Request has already been {actionCompleted}',
      processed_group_body: '{username} was {actionDescription} membership to {groupName} group by {processedBy} at {responseOn}',
      processed_tool_body: 'Group {groupName} was {actionDescription} access to {toolName} tool by {processedBy} at {responseOn}',
      expired_header: 'This Request has expired.',
      expired_group_body: '{username} requested membership to {groupName} group at {createdOn}. If you still want to grant the access, please go to {hyperlink}',
      expired_tool_body: '{username} requested adding tool {toolName} to {groupName} group at {createdOn}. If you still want to grant the access, please go to {hyperlink}',
      expired_body_link_label: 'Admin Panel',
      success_header: 'Access Request {actionCompleted}',
      success_group_body: '{username} was {actionDescription} membership to {groupName} group.',
      success_tool_body: '{groupName} group was {actionDescription} access to {toolName} tool.',
      loading_header: 'Processing Access Request',
      loading_body: 'Access Request is being procesed',
      granted: 'granted',
      denied: 'denied'
    },
    notification_preview: {
      invalid_notification: 'Could not load notification',
      invalid_notification_details: 'Could not load notification. Please verify that the link is correct.',
      loading_notification: 'Loading notification...'
    },
    user_request: {
      header: 'Submit a Request',
      category_label: 'Select Request Category',
      category_placeholder: 'Select request category...',
      category_required: 'Category is required',
      remaining_length: 'remaining characters',
      select_value: 'Select a value...',
      type_text: 'Input text...',
      files_label: 'Attachments',
      max_size: 'You can upload at most {size} in total',
      could_not_fetch_data: 'Could not fetch required data',
      could_not_fetch_data_details: 'Could not required data. Please try again later.',
      request_submitted: 'User Request submitted',
      request_submitted_details: 'User Request successfully submited.',
      could_not_submit_request: 'Could not submit the Request',
      could_not_submit_request_details: 'Could not submit the Request. Please try again later.',
      requested_by: 'Requested by',
      requested_by_placeholder: 'Requested by...',
      user_required: 'User is required'
    }
  },
  api: apiI18n,
  ams: amsI18n
}
