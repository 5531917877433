<template>
  <div
    class="notification"
    :class="notificationClasses"
  >
    <div>
      <span class="notification-title">{{ notification.title }}</span>
    </div>
    <div class="notification-details">
      <div class="notification-description">
        {{ description }}
      </div>
      <div class="notification-created">
        {{ createdOn }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import INotification from '@/interfaces/notification'
import moment from 'moment'
import trimValue from '@/utils/trim'
import adjustDateToUserTz from '@/utils/adjustDateToUserTz'
import useMarkdown from '@/composables/useMarkdown'

export default defineComponent({
  name: 'HeaderNotification',
  props: {
    notification: {
      type: Object as PropType<INotification>,
      required: true
    }
  },
  setup (props) {
    const { generateHtml } = useMarkdown()

    const notificationClasses = computed((): Record<string, boolean> => {
      const classes: Record<string, boolean> = {
        'notification-inactive': !props.notification.active
      }

      classes[`notification-type-${props.notification.type}`] = true

      return classes
    })

    const title = computed((): string => {
      return trimValue(props.notification.title, 40)
    })

    const description = computed((): string => {
      const value = generateHtml(props.notification.content)
      const div = document.createElement('div')
      div.innerHTML = value
      return trimValue(div.innerText, 40)
    })

    const createdOn = computed((): string => moment(adjustDateToUserTz(props.notification.createdOn)).calendar())

    return {
      notificationClasses,
      title,
      description,
      createdOn
    }
  }
})
</script>

<style scoped lang="scss">
.notification {
  padding: 15px;
  border-bottom: 1px solid gainsboro;
  cursor: pointer;
  transition: background-color linear 0.3s;
  &:hover {
    background-color: var(--rich200);
    cursor: pointer;
  }
}
.notification.row {
  margin-left: 0;
  margin-right: 0;
}
.notification-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.notification-details {
  font-size: 12px;
}
.notification-type-0 {
  border-left: 5px solid var(--success);
}
.notification-type-1 {
  border-left: 5px solid #0072b8;
}
.notification-type-2 {
  border-left: 5px solid var(--warning);
}
.notification-type-3 {
  border-left: 5px solid var(--danger);
}
</style>
