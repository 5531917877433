import axios, { AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

export default function setupCaseParserInterceptor (): void {
  // Axios middleware to convert all api responses to camelCase
  axios.interceptors.response.use((response: AxiosResponse) => {
    if (response.config.snakeCaseParser) {
      if (response.data && response.headers['content-type'].indexOf('application/json') !== -1) {
        response.data = camelizeKeys(response.data)
      }
    }

    return response
  })

  // Axios middleware to convert all api requests to snake_case
  axios.interceptors.request.use((config: any) => {
    const newConfig = { ...config }

    if (newConfig.headers && newConfig.headers['Content-Type'] === 'multipart/form-data') {
      const metadata = JSON.parse(newConfig.data.get('data'))
      newConfig.data.set('data', JSON.stringify(decamelizeKeys(metadata)))
      return newConfig
    }

    if (config.snakeCaseParser) {
      if (config.params) {
        newConfig.params = decamelizeKeys(config.params)
      }

      if (config.data) {
        newConfig.data = decamelizeKeys(config.data)
      }
    }

    return newConfig
  })
}
