import { useI18n } from 'vue-i18n'
import store from '@/store'
import { AssignmentsActions } from '@/store/assignmentsStore'
import { IApiResponse } from '@/interfaces/api'
import IAssignmentsTaskCount from '@/interfaces/assignmentsTaskCount'
import assignmentsService from '@/services/assignmentsService'
import moment from 'moment'
import useToasts from '../useToasts'

export default function useAssignmentsCount (): {
    updateGlobalAssignmentsCount(): void
    } {
  const toast = useToasts()
  const { t } = useI18n()

  const updateGlobalAssignmentsCount = (): void => {
    assignmentsService.getAssignmentsCount({
      startDate: moment().startOf('isoWeek').format('YYYYMMDD'),
      currentWeekEndDate: moment().endOf('isoWeek').format('YYYYMMDD')
    })
      .then((result: IApiResponse<IAssignmentsTaskCount>) => {
        store.dispatch(AssignmentsActions.SetAvailableAssignments, result.data)
      })
      .catch(error => {
        toast.showError(t('layout.header_assignments.fetching_error').toString(), error.message)
      })
  }

  return {
    updateGlobalAssignmentsCount
  }
}
