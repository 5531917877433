import { IApiResponse } from '@/interfaces/api'
import Service from './service'
import AppSettings from '@/interfaces/appSettings'

export interface IAppSettingsResponse {
  appSettings: AppSettings
}

class AppService extends Service {
  async fetchAppSettings (): Promise<IApiResponse<IAppSettingsResponse>> {
    return this.get('/app-config')
  }
}

export default new AppService()
