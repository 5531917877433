import AssignmentsTaskCount from '@/interfaces/assignmentsTaskCount'
import moment from 'moment'
import Service, { IApiResponse, IParams } from './service'
import { IUser } from '@/interfaces/user';
import { IAssignment } from '@/interfaces/assignment';
import IGroup from '@/interfaces/group';

export interface IAssignmentsTaskCountRequest {
  startDate: string;
  currentWeekEndDate: string;
  offset?: number;
  overdueOffset?: number;
  groupsIds?: number[]
}

export interface IAvailableAssigneesResponse {
  top: IUser[],
  other: IUser[]
}

export interface IAssignmentAssignResponse {
  assigned: IAssignment[],
  skipped: IAssignment[]
}

export interface IAssignmentBasic {
  id: string,
  instanceId: number,
  dueDate: string
}

export interface IAssociatedAssignmentsResponse {
  taskCount: number;
}

export interface ICreateAdhocAssignmentRequest {
  name: string;
  description: string;
  groupId: number;
  userId: number;
  due: string;
  importance: number;
  sop: string[];
  tools: number[]
}

export interface ICreateAdhocAssignmentResponse {
  assignmentId: string;
}

class AssignmentsService extends Service {
  async fetchAvailableAssignees (templateId: number, groupId: number): Promise<IApiResponse<IAvailableAssigneesResponse>> {
    return this.get(`/assignments/${templateId}/${groupId}/get_users`)
  }

  async fetchUserGroups (): Promise<IApiResponse<IGroup[]>> {
    return this.get('/assignments/groups_filter')
  }

  async fetchUserAssignments (startDate: string, offset: number, overdueOffset = 0, groupsIds: number[] = []): Promise<IApiResponse<IAssignment[]>> {
    const params: IParams = {
      currentDate: moment().startOf('day').format('YYYYMMDD'),
      startDate: startDate,
      offset: offset,
      overdueOffset: overdueOffset
    }
    if (groupsIds.length > 0) {
      params.groups = groupsIds.join(', ')
    }

    return this.get('/assignments/user', params)
  }

  async fetchUnassignedAssignments (startDate: string, offset: number, groupsIds: number[] = []): Promise<IApiResponse<IAssignment[]>> {
    const params: IParams = {
      currentDate: moment().startOf('day').format('YYYYMMDD'),
      startDate: startDate,
      offset: offset
    }
    if (groupsIds.length > 0) {
      params.groups = groupsIds.join(', ')
    }

    return this.get('/assignments/unassigned', params)
  }

  async fetchMyGroupsAssignments (startDate: string, offset: number, groupsIds: number[] = []): Promise<IApiResponse<IAssignment[]>> {
    const params: IParams = {
      currentDate: moment().startOf('day').format('YYYYMMDD'),
      startDate: startDate,
      offset: offset
    }
    if (groupsIds.length > 0) {
      params.groups = groupsIds.join(', ')
    }

    return this.get('/assignments/group', params)
  }

  async getAssignmentsCount (data: IAssignmentsTaskCountRequest): Promise<IApiResponse<AssignmentsTaskCount>> {
    let url = `/assignments/user-task-counters?start_date=${data.startDate}&current_week_end_date=${data.currentWeekEndDate}`
    url += data.offset ? `&offset=${data.offset}` : ''
    url += data.overdueOffset ? `&overdue_offset=${data.overdueOffset}` : ''
    if (data.groupsIds && data.groupsIds.length > 0) {
      url += `&groups=${data.groupsIds.join(',')}`
    }
    return this.get(url)
  }

  async getAssignmentDetails (id: string): Promise<IApiResponse<IAssignment>> {
    return this.get(`/assignments/${id}`)
  }

  async addComment (assignmentId: string, comment: string): Promise<IApiResponse<IAssignment>> {
    return this.put(`/assignments/comment/${assignmentId}`, { comment: comment })
  }

  async setAssignmentStatusCompleted (assignmentId: string): Promise<IApiResponse<IAssignment>> {
    return this.put(`/assignments/complete/${assignmentId}`)
  }

  async setAssignmentStatusBlocked (assignmentId: string, comment: string): Promise<IApiResponse<IAssignment>> {
    return this.put(`/assignments/block/${assignmentId}`, { comment: comment })
  }

  async setAssignmentStatusUnblocked (assignmentId: string): Promise<IApiResponse<IAssignment>> {
    return this.put(`/assignments/unblock/${assignmentId}`)
  }

  async setAssignmentStatusCanceled (assignmentId: string): Promise<IApiResponse<IAssignment>> {
    return this.put(`/assignments/cancel/${assignmentId}`)
  }

  async setAssignmentStatusNew (assignmentId: string): Promise<IApiResponse<IAssignment>> {
    return this.put(`/assignments/revert/${assignmentId}`)
  }

  async createAndCancelAssignment (assignmentId: string): Promise<IApiResponse<IAssignment>> {
    return this.post(`/assignments/${assignmentId}/autocancel`)
  }

  async sendNotificationEmail (assignmentId: string): Promise<void> {
    return this.post(`/assignments/${assignmentId}/send_email`)
  }

  async assign (assignmentId: string, assigneeId: number, offset: number, sendEmail: boolean):
      Promise<IApiResponse<IAssignmentAssignResponse>> {
    return this.post('/assignments/assign', {
      assignment_id: assignmentId,
      assignee_id: assigneeId,
      offset: offset,
      send_email: sendEmail
    })
  }

  async reassign (assignmentId: string, assigneeId: number, offset: number, sendEmail: boolean): Promise<IApiResponse<IAssignmentAssignResponse>> {
    return this.put('/assignments/reassign', {
      assignment_id: assignmentId,
      assignee_id: assigneeId,
      offset: offset,
      send_email: sendEmail
    })
  }

  async bulkAssign (assignmentsIds: string[], assigneeId: number): Promise<IApiResponse<IAssignmentBasic[]>> {
    return this.post('/assignments/bulk_assign', {
      assignments: assignmentsIds,
      assignee_id: assigneeId
    })
  }

  async bulkReassign (assignmentsIds: string[], assigneeId: number): Promise<IApiResponse<IAssignmentBasic[]>> {
    return this.put('/assignments/bulk_reassign', {
      assignments: assignmentsIds,
      assignee_id: assigneeId
    })
  }

  async getAssociatedAssignments (groupId: number): Promise<IApiResponse<IAssociatedAssignmentsResponse>> {
    return this.get(`/assignments/count?groups_ids=${groupId}&statuses_ids=1,3`)
  }

  async getTemplateAssignments (templateId: number): Promise<IApiResponse<IAssociatedAssignmentsResponse>> {
    return this.get(`/assignments/count?templates_ids=${templateId}&statuses_ids=1,3`)
  }

  async createAdhocAssignment (request: ICreateAdhocAssignmentRequest): Promise<IApiResponse<ICreateAdhocAssignmentResponse>> {
    return this.post('/assignments/adhoc', request)
  }

  async editAdhocAssignment (assignmentId: string, request: ICreateAdhocAssignmentRequest): Promise<void> {
    return this.put(`/assignments/adhoc/${assignmentId}`, request)
  }

  async getAdhocEnabledGovernedGroups (): Promise<IApiResponse<IGroup[]>> {
    return this.get('/assignments/adhoc/groups')
  }
}

export default new AssignmentsService()
