import IAppSettings, { IEnvironmentSettings, IMixpanelSettings } from '@/interfaces/appSettings'
import { ActionContext } from 'vuex'
import { IRootState } from '@/store/index'
import { setupMixpanel } from '@/utils/mixpanel/setupMixpanel'
import { setSentryAppContext } from '@/utils/sentry/setupSentry'
import { Configuration } from '@azure/msal-browser/dist/config/Configuration'

enum AppMutations {
  SetAppSettings = 'SET_APP_SETTINGS_MUTATION',
  SetState = 'SET_STATE_MUTATION',
  ToggleFullScreen = 'TOGGLE_FULLSCREEN_MUTATION'
}

export enum AppActions {
  SetAppSettings = 'SET_APP_SETTINGS_ACTION',
  SetState = 'SET_STATE_ACTION',
  ToggleFullScreen = 'TOGGLE_FULLSCREEN_ACTION'
}
export type AppState = 'initializing' | 'ready' | 'maintenance' | 'error'

export interface IAppState {
  environment: IEnvironmentSettings|null,
  jiraBaseUrl: string;
  jiraIssuesLink: string;
  kbBaseUrl: string;
  mixpanel: IMixpanelSettings|null,
  msalConfig: Configuration|null,
  msalApiScopes: string[],
  state: AppState,
  fullScreen: boolean;
}

export default {
  state: (): IAppState => ({
    environment: null,
    jiraBaseUrl: '',
    jiraIssuesLink: '',
    kbBaseUrl: '',
    mixpanel: null,
    msalConfig: null,
    msalApiScopes: [],
    state: 'initializing',
    fullScreen: false
  }),
  mutations: {
    [AppMutations.SetAppSettings] (state: IAppState, payload: IAppSettings): void {
      state.environment = payload.environment
      state.jiraBaseUrl = payload.jiraBaseUrl
      state.jiraIssuesLink = payload.jiraIssuesLink
      state.kbBaseUrl = payload.kbBaseUrl
      state.mixpanel = payload.mixpanel
      state.msalConfig = payload.msalConfig
      state.msalApiScopes = payload.msalApiScopes
    },
    [AppMutations.SetState] (state: IAppState, payload: AppState): void {
      state.state = payload
    },
    [AppMutations.ToggleFullScreen] (state: IAppState, payload: boolean): void {
      state.fullScreen = payload
    }
  },
  actions: {
    [AppActions.SetAppSettings] (context: ActionContext<IAppState, IRootState>, appSettings: IAppSettings): void {
      context.commit(AppMutations.SetAppSettings, appSettings)

      if (context.state.mixpanel?.token) {
        setupMixpanel(context.state.mixpanel.token)
      }

      setSentryAppContext(context.state.environment?.localhost || false)
    },
    [AppActions.SetState] (context: ActionContext<IAppState, IRootState>, state: AppState): void {
      context.commit(AppMutations.SetState, state)
    },
    [AppActions.ToggleFullScreen] (context: ActionContext<IAppState, IRootState>, state: AppState): void {
      context.commit(AppMutations.ToggleFullScreen, state)
    }
  }
}
